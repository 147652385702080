<ng-container *ngrxLet="selectActiveLevel2$; let activeLevel2">
  <ng-container *ngrxLet="selectMenuItems$; let menuItems">
    <div *ngIf="menuItems; else emptyMenu" class="menu-wrapper p-r" [class.collapsed]="collapsed">
      <div>
        <div class="collapse-button"
             *ngIf="collapseEnabled"
             [class.collapsed]="collapsed"
             (click)="toggleMenu()"
        >
      <span
        class="ui-icon-expand-arrow"

      ></span>
        </div>

        <ng-container *ngIf="!collapsed">
          <div *ngFor="let item of menuItems; let i = index">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              class="menu-row"
              [class.disabled]="item.disabled"
              [routerLink]="validPoints[item.key] ? item.route : null"
              [class.active]="activeLevel2 === item.key"
            >
              <ng-container *ngIf="showValidPoints">
                <div *ngIf="!validPoints[item.key]"
                     fxLayout="row"
                     fxLayoutAlign="center center"
                     class="number"
                >{{ i + 1 }}
                </div>
                <img *ngIf="validPoints[item.key]" src="assets/icons/check_in_green.svg"/>
              </ng-container>
              <span *ngIf="item.iconClass" [ngClass]="item.iconClass" class="menu-icon"></span>
              <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="menu-icon"></mat-icon>
              <span class="title" [style.marginLeft]="showValidPoints ? '8px' : '0px'">{{ item.name }}</span>
            </div>
            <ng-container *ngrxLet="selectActiveLevel3$; let activeLevel3">
              <div
                *ngFor="let level3 of item.level3; let j = index"
                [class.disabled]="level3.disabled"
                [class.active]="activeLevel3 === level3.key"
                [routerLink]="level3.route"
                class="menu-row level3-point"
              >
                <span class="title">{{ i + 1 }}.{{ j + 1 }}. {{ level3.name }}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #emptyMenu> Menu is empty</ng-template>
