<ng-container *ngrxLet="selectCamerasLookup$;let selectCamerasLookup">
  <ng-container *ngIf="{
    isHovered:isOpened$|async,
    lefPosition:cameraSnapshotLeftPx$|async,
    cameraModalNoBottomSpace:cameraModalNoBottomSpace$|async
     }as conditions,
"
  >
    <div class="camera-wrapper flex-col gap-6" matTooltip="">
      @if (conditions.cameraModalNoBottomSpace) {
        <div class="snapshot-wrapper c-p"
             [class.hidden]="!conditions.isHovered"
             [style.left.px]="conditions.lefPosition"
        >
          <ui-camera-status-snapshot [cameraId]="cameraId"
                                     [edgeId]="selectCamerasLookup[cameraId]?.edgeId"
                                     [showStatus]="false"
                                     (click)="openVideo.emit(cameraId)"
          >

          </ui-camera-status-snapshot>
        </div>
      }
      <div class="flex-row">
        <ng-container *ngTemplateOutlet="nameTemplate;context: { $implicit: {
        hidden:true
        } }"
        >

        </ng-container>
      </div>
      @if (!conditions.cameraModalNoBottomSpace) {
        <div class="snapshot-wrapper c-p"
             [class.hidden]="!conditions.isHovered"
             [style.left.px]="conditions.lefPosition"
        >
          <ui-camera-status-snapshot [cameraId]="cameraId"
                                     [edgeId]="selectCamerasLookup[cameraId]?.edgeId"
                                     [showStatus]="false"
                                     (click)="openVideo.emit(cameraId)"
          >

          </ui-camera-status-snapshot>
        </div>
      }

      <div #nameBlock class="hidden-name-block">
        <ng-container *ngTemplateOutlet="nameTemplate; context: { $implicit: {
        hidden:false
        } }"
        >
        </ng-container>
      </div>


    </div>

    <ng-template #nameTemplate let-data>
      <div class="name-wrapper flex-row start-center gap-8 c-p"
      >
        <div class="flex-row" [class.draggable]="!conditions.isHovered && !viewMode">
          <span class="ui-icon-camera primary-color"></span>
        </div>
        <span class="primary-color c-p" [class.hidden]="!conditions.isHovered && data.hidden">
                      <ui-truncated-html style="max-width: 100px; line-height: 16px">
                              {{ selectCamerasLookup[cameraId]?.edgeOnly?.name }}
                      </ui-truncated-html>
          </span>
        @if (!viewMode) {
          <span class="v-d" [class.hidden]="!conditions.isHovered && data.hidden"></span>
          <span class="ui-icon-edit c-p"
                [class.hidden]="!conditions.isHovered && data.hidden"
                (click)="onEditShortcut.emit(cameraId)"
          ></span>
          <span class="ui-icon-delete c-p"
                [class.hidden]="!conditions.isHovered && data.hidden"
                (click)="onDeletedShortcut.emit(cameraId)"
          ></span>
        }
      </div>
    </ng-template>

  </ng-container>
</ng-container>
