import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AccessDoorsModel } from '@models/access-doors.model';
import { AccessDoorsActions } from '@states/access-doors/access-doors.action-types';
import { IntegrationsActions } from '@states/integrations/integrations.action-types';
import SourceType = AccessDoorsModel.SourceType;

export interface AccessDoorsState extends EntityState<AccessDoorsModel.DocumentMongo> {
  isInitialLoaded: boolean; // show loader until first loading happening
  isCreating: boolean;
  deletingAccessDoorsIds: string[];
  notEmpty: boolean; //show no Data if result empty
}

export const adapter: EntityAdapter<AccessDoorsModel.DocumentMongo> = createEntityAdapter<AccessDoorsModel.DocumentMongo>({
  selectId: (document: AccessDoorsModel.DocumentMongo) => document._id,
});

const { selectAll } = adapter.getSelectors();


const initialState: AccessDoorsState = adapter.getInitialState({
  isInitialLoaded: false,
  isCreating: false,
  deletingAccessDoorsIds: [],
  notEmpty: null,
});


export const accessDoorsStateReducer = createReducer(
  initialState,
  on(AccessDoorsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(AccessDoorsActions.getOrganizationDoorsSuccess, (state, { documents }) => {
    return adapter.setAll(documents, {
      ...state,
      isInitialLoaded: true,
      notEmpty: !state.isInitialLoaded ? !!documents.length : state.notEmpty,
    });
  }),
  on(AccessDoorsActions.getOrganizationDoorsFail, (state) => {
    return {
      ...state,
      isInitialLoaded: true,
    };
  }),
  on(AccessDoorsActions.setIsCreatingLoader, (state, { isCreating }) => {
    return {
      ...state,
      isCreating,
    };
  }),
  on(AccessDoorsActions.startDeleteDoor, (state, { id }) => {
    return {
      ...state,
      deletingAccessDoorsIds: [...state.deletingAccessDoorsIds, id],
    };
  }),
  on(AccessDoorsActions.sendDeleteDoorRequestSuccess, (state, { id }) => {
    const deletingIndex = state.deletingAccessDoorsIds.findIndex(_id => _id === id);
    let newDeletingArray = [...state.deletingAccessDoorsIds];
    newDeletingArray.splice(deletingIndex, 1);
    return adapter.removeOne(id, {
      ...state,
      deletingAccessDoorsIds: newDeletingArray,
      notEmpty: selectAll(state).length > 1,
    });
  }),
  on(AccessDoorsActions.sendDeleteDoorRequestFail, (state, { id }) => {
    const deletingIndex = state.deletingAccessDoorsIds.findIndex(_id => _id === id);
    let newDeletingArray = [...state.deletingAccessDoorsIds];
    newDeletingArray.splice(deletingIndex, 1);
    return {
      ...state,
      deletingAccessDoorsIds: newDeletingArray,
    };
  }),
  on(AccessDoorsActions.sendAddDoorRequestSuccess, (state, { door }) => {
    return adapter.setAll([door, ...selectAll(state)], { ...state, notEmpty: true });
  }),
  on(AccessDoorsActions.sendUpdateDoorRequestSuccess, (state, { door }) => {
    return adapter.updateOne({
      id: door._id,
      changes: {
        ...door,
      },
    }, { ...state });
  }),
  on(IntegrationsActions.uninstallKisiSuccess, (state) => {
    return adapter.removeMany(selectAll(state)
      .filter(door => door.sourceType === SourceType.Kisi)
      .map(door => door._id), {
      ...state,
      notEmpty: false,
    });
  }),
  on(IntegrationsActions.uninstallGeneaSuccess, (state) => {
    return adapter.removeMany(selectAll(state)
      .filter(door => door.sourceType === SourceType.Genea)
      .map(door => door._id), {
      ...state,
      notEmpty: false,
    });
  }),
);
