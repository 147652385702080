import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { PipesModule } from '../../../pipes/pipes.module';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { LazyLoadImageModule, StateChange } from 'ng-lazyload-image';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';
import { UiKitModule } from '../ui-kit.module';
import { PreloaderColor } from '@enums/shared.enum';
import { UiCameraStatusComponent } from '../ui-camera-status/ui-camera-status.component';
import { FlexModule } from '@angular/flex-layout';
import { PushPipe } from '@ngrx/component';
import { CamerasService } from '../../../cameras/cameras.service';

@Component({
  selector: 'ui-camera-snapshot',
  templateUrl: './ui-camera-snapshot.component.html',
  styleUrls: ['./ui-camera-snapshot.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PipesModule,
    AsyncPipe,
    LazyLoadImageModule,
    NgIf,
    MatTooltip,
    UiKitModule,
    UiCameraStatusComponent,
    FlexModule,
    PushPipe,
    NgTemplateOutlet,
  ],
})
export class UiCameraSnapshotComponent {
  @Input() cameraId: string;
  @Input() edgeId: string;

  public isError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public preloaderColor = PreloaderColor;

  constructor(private camerasService: CamerasService) {
  }

  public onImageLoad(ev: any) {
    this.loader$.next(false);
    this.isError$.next(false);
  }

  public onError() {
    this.loader$.next(false);
    this.isError$.next(true);
  }

  cameraSnapshot(): Observable<any> {
    return this.camerasService.getCameraSnapshot(this.cameraId);
  }
}
