import { Dictionary } from '@ngrx/entity/src/models';

export namespace CameraSettingsModel {

  export interface Shortcut {
    x: string,
    y: string,
    cameraId: string,
    edgeId: string,
    locationId: string
  }

  export interface CameraSettingsBase {
    _id: string;
    cameraId: string;
    edgeId: string;
    locationId: string;
    orgId: string;
  }

  export interface CameraSettingMongoDocument extends CameraSettingsBase {
    shortcuts: Shortcut[];
  }


  export interface CameraSettingUI extends CameraSettingsBase {
    shortcuts: Dictionary<Shortcut>;
  }

  export const convertCameraSettingMongoDocumentToCameraSettingDocument = (input: CameraSettingMongoDocument): CameraSettingUI => {
    const shortcutMap: Dictionary<Shortcut> = {};
    input.shortcuts.forEach(shortcut => {
      shortcutMap[shortcut.cameraId] = shortcut;
    });
    return {
      ...input,
      shortcuts: shortcutMap,
    };
  };
}

