import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';
import { CameraSettingsModel } from '@models/camera-settings.model';

@Injectable()
export class CamerasSettingsService {

  constructor(private http: HttpClient) {
  }

  public getCamerasSettings(): Observable<CameraSettingsModel.CameraSettingMongoDocument[]> {
    return this.http.get<CameraSettingsModel.CameraSettingMongoDocument[]>(api.location.getCameraSettings);
  }
}
