import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import _ from 'lodash';
import { UiBreadCrumbItem } from '@models/route.models';

@Injectable()
export class BreadcrumbsService {

  public buildBreadcrumbs(route: ActivatedRouteSnapshot, url: string = '', breadcrumbs: UiBreadCrumbItem[] = []): UiBreadCrumbItem[] {
    const breadcrumbsCopy = _.cloneDeep(breadcrumbs);
    if (route) {
      const routeUrl = route.url.map(segment => segment.path)
        .join('/');
      let nextUrl = routeUrl ? `${url}/${routeUrl}` : url;

      const routeDataBreadcrumb = route.data['breadcrumb'];
      const additionalBreadcrumbs = route.data['breadcrumbs'] || []; // Add dynamic breadcrumbs

      // Add the static breadcrumb for this route
      if (routeDataBreadcrumb) {
        if (route.params['id']) {
          nextUrl = nextUrl.replace(route.params['id'], '');
        }
        breadcrumbsCopy.push({ name: routeDataBreadcrumb, route: nextUrl });
      }

      // Add additional dynamic breadcrumbs if they exist
      for(const dynamicBreadcrumb of additionalBreadcrumbs) {
        breadcrumbsCopy.push(dynamicBreadcrumb);
      }

      // Check for child routes
      if (route.firstChild) {
        return this.buildBreadcrumbs(route.firstChild, nextUrl, breadcrumbsCopy);
      }
    }

    // Remove the URL from the last breadcrumb (if necessary)
    if (breadcrumbsCopy.length > 0) {
      if (breadcrumbsCopy[breadcrumbsCopy.length - 1].route) {
        breadcrumbsCopy[breadcrumbsCopy.length - 1].route = undefined;
      }
    }

    return breadcrumbsCopy;
  }

}
