import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HomeState } from '@states/home/home.reducer';
import { HomeModel } from '@models/home.model';

const selectHomeState = createFeatureSelector<HomeState>('homeState');

export const selectInitialLoaded = createSelector(selectHomeState, ({ initialLoaded }: HomeState) => initialLoaded);
export const selectNotEmpty = createSelector(selectHomeState, ({ notEmpty }: HomeState) => notEmpty);
export const selectIsSaving = createSelector(selectHomeState, ({ isSaving }: HomeState) => isSaving);
export const selectLocations = createSelector(selectHomeState, ({ entities }: HomeState) => {
  return Object.values(entities ?? {})
    .filter(location => !location?.parent);
});

export const selectAllSubLocations = createSelector(selectHomeState, ({ entities }: HomeState) => {
  return Object.values(entities ?? {})
    .filter(location => location?.parent);
});

export const hasSublocations = (locationId: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  return Object.values(entities ?? {})
    .some(location => location?.parent === locationId);
});

export const selectLocationsWithQuery = createSelector(selectHomeState, ({ entities, query, selectedLocationId }: HomeState) => {
  const normalizedQuery = query?.trim()
    .toLowerCase();

  let locations = Object.values(entities ?? {});

  if (!!selectedLocationId) {
    locations = locations.filter(location => location._id === selectedLocationId);
  }

  const res = !!query ? locations
    .filter(location => {
      return location.name.toLowerCase()
        .includes(normalizedQuery) || location.cameras?.some(camera => camera.name.toLowerCase()
        .includes(normalizedQuery));
    }) : !!selectedLocationId ? locations : locations
    .filter(location => !location?.parent);

  return res.filter(location => location?.cameras?.length || locations.some(l => l?.parent === location._id));
});

export const selectFilteredLocations = createSelector(selectHomeState, ({ filteredLocations }: HomeState) => filteredLocations);


export const selectAllLocations = createSelector(selectHomeState, ({ entities }: HomeState) => {
  return Object.values(entities ?? {});
});

export const selectLocationById = (locationId: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  return entities[locationId];
});

export const selectLocationsList = createSelector(selectHomeState, ({ entities }: HomeState) => {
  const locations = Object.values(entities ?? {});

  return locations
    .filter(location => !location?.parent && (location?.cameras?.length > 0 || locations.some(l => l?.parent === location._id)))
    .map(location => {
      return <HomeModel.LocationListItem>{
        _id: location._id,
        name: location.name,
        subLocations: location?.subLocations,
      };
    });
});

export const selectLocationCamerasById = (locationId) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  return Object.values(entities ?? {}) ? entities[locationId]?.cameras : [];
});

export const selectSubLocationsList = (locationId: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  return Object.values(entities ?? {})
    .filter(location => location?.parent === locationId)
    .map(location => {
      return <HomeModel.LocationListItem>{
        _id: location._id,
        name: location.name,
        subLocations: location?.subLocations,
      };
    });
});

export const selectFilteredSubLocations = (locationId: string) => createSelector(selectHomeState, ({ filteredLocations }: HomeState) => {
  return filteredLocations
    .filter(location => location?.parent === locationId);
});

export const selectSubLocations = (locationId: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  return Object.values(entities ?? {})
    .filter(location => location?.parent === locationId);
});

export const selectToRemoveList = createSelector(selectHomeState, ({ toRemove }: HomeState) => toRemove);

export const isStarred = (locationId: string) => createSelector(selectHomeState, ({ favorites }: HomeState) => {
  return favorites.includes(locationId);
});

export const starredLocations = createSelector(selectHomeState, ({ entities, favorites }: HomeState) => {
  return Object.values(entities ?? {})
    .filter(location => favorites?.includes(location?._id)) ?? [];
});

export const selectSubLocationCameras = createSelector(selectHomeState, ({ subLocationCameras }: HomeState) => subLocationCameras);

export const selectNameById = (subLocationId: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  return entities[subLocationId]?.name;
});

export const hasCameras = (locationId: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  if (entities[locationId]?.cameras?.length > 0) {
    return true;
  }
  const subLocations = Object.values(entities ?? {})
    .filter(location => location?.parent === locationId);
  return subLocations.some(location => location?.cameras?.length > 0 || hasCameras(location._id));


});

export const selectFullNamesById = (subLocationId: string) =>
  createSelector(selectHomeState, ({ entities }: HomeState): string[] => {
    // loop over parent locations and build the full name
    let location = entities[subLocationId];
    const names: string[] = [];

    if (!location?.parent) {
      return [location?.name];
    }

    while (location) {
      if (location?.parent) {
        names.push(location.name);
        location = entities[location.parent];
      } else {
        location = null;
      }
    }

    return names.reverse();
  });

export const selectFullNamesByIdWithBase = (subLocationId: string) =>
  createSelector(selectHomeState, ({ entities }: HomeState): string[] => {
    // loop over parent locations and build the full name
    let location = entities[subLocationId];
    const names: string[] = [];

    if (!location?.parent) {
      return [location?.name];
    }

    while (location) {
      if (location?.parent) {
        names.push(location.name);
        location = entities[location.parent];
      } else {
        names.push(location.name);
        location = null;
      }
    }

    return names.reverse();
  });

export const filterCamerasByQuery = (query: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  return Object.values(entities ?? {})
    .map(location => location.cameras)
    .flat()
    .filter(camera => camera?.name.toLowerCase()
      .includes(query.toLowerCase()));
});

export const selectSelectedLocationId = createSelector(selectHomeState, ({ selectedLocationId }: HomeState) => selectedLocationId);

export const selectSubLocationTreeForCamera = (cameraId: string) => createSelector(selectHomeState, ({ entities }): string[] => {
  const location = Object.values(entities ?? {})
    .find(
      (location) => location.cameras?.some((camera) => camera.cameraId === cameraId),
    );

  if (!location) {
    return [];
  }

  // CALL the second selector with `homeState` to get the actual string[].
  return selectFullNamesById(location._id)({ homeState: { entities } });
});

export const getBaseParentLocationId = (locationId: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  let location = entities[locationId];
  while (location?.parent) {
    location = entities[location.parent];
  }
  return location?._id;
});

export const getChildrenNames = (locationId: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  return Object.values(entities ?? {})
    .filter(location => location.parent === locationId)
    .map(location => location.name);
});

export const getAllDescendantNames = (locationId: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  const names: string[] = [];
  const children = Object.values(entities ?? {})
    .filter(location => location.parent === locationId);
  for(const child of children) {
    names.push(child.name);
    names.push(...getAllDescendantNames(child._id)({ homeState: { entities } }));
  }
  return names;
});

export const getAllDescendantCameraNames = (locationId: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  const names: string[] = [];
  const children = Object.values(entities ?? {})
    .filter(location => location.parent === locationId);
  for(const child of children) {
    names.push(...child.cameras?.map(camera => camera.name));
    names.push(...getAllDescendantCameraNames(child._id)({ homeState: { entities } }));
  }
  return names;
});

export const getAllDescendantCameraIds = (locationId: string) => createSelector(selectHomeState, ({ entities }: HomeState) => {
  const ids: string[] = [];
  const children = Object.values(entities ?? {})
    .filter(location => location.parent === locationId);
  for(const child of children) {
    ids.push(...child.cameras?.map(camera => camera.cameraId));
    ids.push(...getAllDescendantCameraIds(child._id)({ homeState: { entities } }));
  }
  return ids;
});

export const isCameraSelected = (cameraId: string) => createSelector(selectHomeState, ({ selectedCameras }: HomeState) => {
  return selectedCameras.map(camera => camera?.cameraId)
    .includes(cameraId);
});

export const selectedCamerasNum = createSelector(selectHomeState, ({ selectedCameras }: HomeState) => selectedCameras?.length);

export const selectLocationCameraMoveMode = createSelector(selectHomeState, ({ locationCameraSelectionMode }: HomeState) => {
  return locationCameraSelectionMode;
});

export const isLocationCameraMoveMode = (locationId: string) => createSelector(selectHomeState, ({ locationCameraSelectionMode }: HomeState) => {
  return locationCameraSelectionMode === locationId;
});

export const isLocationSelectModeEnabled = createSelector(selectHomeState, ({ locationCameraSelectionMode }: HomeState) => {
  return !!locationCameraSelectionMode;
});

export const getAllDescendantsList = (locationId?: string) => createSelector(selectHomeState, ({ entities, locationCameraSelectionMode }: HomeState) => {
  const sublocations: { name: string, _id: string } [] = [];
  const _entities = Object.values(entities ?? {});
  const children = _entities
    .filter(location => !!location.parent && location.parent === (locationId ?? locationCameraSelectionMode));

  for(const child of children) {
    sublocations.push({ name: child.name, _id: child._id });
    sublocations.push(...getAllDescendantsList(child._id)({ homeState: { entities, locationCameraSelectionMode } }));
  }

  return sublocations;
});

export const selectGotoCameraId = createSelector(selectHomeState, ({ gotoCameraId }: HomeState) => gotoCameraId);
