import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AlertEventsActions from '@states/alert-events/alert-events.actions';
import { catchError, debounceTime, exhaustMap, of, share, switchMap } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import * as SharedActions from '@states/shared/shared.actions';
import { AlertEventSendModel } from '@models/alert-events.model';
import { AlertEventsService } from '../../development/alert-events.service';
import { AlertType, AnalyticClasses, ConfigurationFilterType, DetectionType } from '@enums/alert-events.enum';
import { AlertEventsState } from '@states/alert-events/alert-events.reducer';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { MenuKey } from '@enums/menu.enum';
import { LocationSelectors } from '@states/location/location.selector-types';
import { SessionDataAction } from '@enums/session-data.enum';
import { MenuActions } from '@states/menu/menu.action-types';
import { CameraActions } from '@states/camera/camera.action-types';
import { AlertV2Document } from '@models/alerts-v2.model';

@Injectable()
export class AlertEventsEffect {
  public pressContinue$ = createEffect(() => this.actions$.pipe(ofType(AlertEventsActions.continueAlertEvents), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public pressBack$ = createEffect(() => this.actions$.pipe(ofType(AlertEventsActions.backAlertEvents), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public detailsValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.validateDetails),
      withLatestFrom(this.store$.pipe(select(state => state.alertEventsState))),
      switchMap(([, { alertName, selectedAlertType, selectedCamera }]) => {
        /**
         * if selectedAlertType not equal 0 -> hide the camera selection.
         */
        if (alertName && selectedAlertType !== null && (selectedCamera || selectedAlertType)) {
          return of(AlertEventsActions.addValidMenuPoint({ point: MenuKey.details }));
        } else {
          return [SharedActions.showMessage({ warning: 'All fields are required' })];
        }
      }),
      share(),
    ),
  );

  public zonesValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.validateZones),
      withLatestFrom(this.store$.pipe(select(state => state.alertEventsState))),
      switchMap(([, { zones, definedZones }]) => {
        if (!definedZones || (definedZones && Object.keys(zones).length)) {
          return of(AlertEventsActions.addValidMenuPoint({ point: MenuKey.zones }));
        }
        return [
          SharedActions.showMessage({
            warning: 'Select entire canvas or add at least one zone',
          }),
        ];
      }),
      share(),
    ),
  );

  public lineCrossingValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.validateLineCrossing),
      withLatestFrom(this.store$.pipe(select(state => state.alertEventsState))),
      switchMap(([, { lineCrossing }]) => {
        if (!!lineCrossing.p1?.x && !!lineCrossing.p2?.x) {
          return of(AlertEventsActions.addValidMenuPoint({ point: MenuKey.zones }));
        }
        return [SharedActions.showMessage({ warning: 'Mark line crossing' })];
      }),
      share(),
    ),
  );

  public trafficControlValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.validateTrafficControl),
      withLatestFrom(this.store$.pipe(select(state => state.alertEventsState))),
      switchMap(([, { trafficControl }]) => {
        if (trafficControl?.lines?.length >= 2) {
          return of(AlertEventsActions.addValidMenuPoint({ point: MenuKey.zones }));
        }
        return [SharedActions.showMessage({ warning: 'Mark at least two lines - an entry and an exit' })];
      }),
      share(),
    ),
  );

  public saveAlertEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.saveAlert),
      exhaustMap(() => [SharedActions.setIsSaving({ isSaving: true }), AlertEventsActions.sendAlert()]),
    ),
  );

  public sendAlertEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.sendAlert),
      withLatestFrom(this.store$.pipe(select(state => state.alertEventsState)), this.store$.select(LocationSelectors.selectLocationLookup)),
      mergeMap(([, alertEventState, locationLookup]) => {
        const alertEvent: AlertEventSendModel = {
          name: alertEventState.alertName,
          alertType: alertEventState.selectedAlertType,
          configuration: {
            object: alertEventState.trackedObject,
            offender: alertEventState.offenderObject,
            objects: alertEventState.trackedObject === AnalyticClasses.multiple ? alertEventState.multipleObjects : undefined,
            detection: alertEventState.detectionType,
            detectionAdditionalAttributes: alertEventState.detectionAdditionalAttributes,
            filters: loadConfigurationFilters(alertEventState),
            tresholdTime: alertEventState.tresholdTime,
          },
          settings: alertEventState.settings,
          notifications: alertEventState.notifications,
          timezone: alertEventState.timezone,
          actions: alertEventState.actions,
        };
        if (alertEventState.detectionType === DetectionType.LineCrossing || alertEventState.detectionType === DetectionType.Tailgating) {
          alertEvent.lineCrossing = alertEventState.lineCrossing;
        } else if (alertEventState.detectionType === DetectionType.TrafficControl) {
          alertEvent.trafficControl = alertEventState.trafficControl;
        } else {
          alertEvent.zones = alertEventState.zones;
          alertEvent.definedZones = alertEventState.definedZones;
          alertEvent.markedIdx = alertEventState.markedIdx;
          alertEvent.measureCrossZones = alertEventState.measureCrossZones;
        }

        if (alertEvent.alertType === AlertType.analytic) {
          alertEvent.selectedCamera = {
            locationId: alertEventState.selectedCamera.locationId,
            cameraId: alertEventState.selectedCamera.edgeOnly.cameraId,
            edgeId: alertEventState.selectedCamera.edgeId,
            timezone: locationLookup[alertEventState.selectedCamera.locationId]?.timezone,
          };
        }
        if (alertEvent.alertType !== AlertType.analytic) {
          alertEvent.multiCameras = alertEventState?.multiCameras;
        }
        if (alertEventState.selectedAlertEvent?._id) {
          return this.alertEventsService.updateAlertEvents(alertEventState.selectedAlertEvent._id, alertEvent)
            .pipe(
              switchMap(res => {
                const actions: Action[] = [
                  SharedActions.showMessage({
                    success: 'Alert has been updated',
                  }),
                  AlertEventsActions.saveAlertSuccess(),
                  SharedActions.setIsSaving({ isSaving: false }),
                ];
                if (res) {
                  actions.push(
                    SharedActions.subscribeToSessionStatus({
                      token: res.token.session,
                      sessionDataAction: SessionDataAction.sendAlert,
                      params: {
                        msTimeout: 40000,
                      },
                    }),
                  );
                }
                return actions;
              }),
              catchError(response => {
                return [
                  SharedActions.setIsSaving({ isSaving: false }), //loader off
                  this.catchError(response),
                ];
              }),
              share(),
            );
        } else {
          return this.alertEventsService.createAlertEvents(alertEvent)
            .pipe(
              mergeMap(res => {
                return [
                  SharedActions.showMessage({
                    success: 'Alert has been created',
                  }),
                  SharedActions.setIsSaving({ isSaving: false }),
                  AlertEventsActions.saveAlertSuccess(),
                  res.token.session ? SharedActions.subscribeToSessionStatus({
                    token: res.token.session,
                    sessionDataAction: SessionDataAction.sendAlert,
                    params: {
                      msTimeout: 40000,
                    },
                  }) : SharedActions.doNothing(),
                ];
              }),
              catchError(response => {
                return [
                  SharedActions.setIsSaving({ isSaving: false }), //loader off
                  this.catchError(response),
                ];
              }),
              share(),
            );
        }
      }),
      share(),
    ),
  );

  public getAlertEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.getAlertEvents),
      withLatestFrom(this.store$.pipe(select(state => state.alertEventsState))),
      mergeMap(([, { page, perPage, orderBy, orderDirection, query, flowTypes, cameras, isLastPage }]) => {
        if (!isLastPage) {
          return this.alertEventsService.getAlertEvents(page, perPage, orderBy, orderDirection, query, cameras, flowTypes)
            .pipe(
              mergeMap(result => {
                return [
                  AlertEventsActions.setAlertEvents({
                    alertEvents: result.items,
                  }),
                  AlertEventsActions.setTotalItemsCount({
                    totalItemsCount: result.totalItemsCount,
                  }),
                ];
              }),
            );
        } else {
          return [SharedActions.doNothing()];
        }

      }),
      share(),
    ),
  );

  public getSelectedAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.getSelectedAlert),
      withLatestFrom(this.store$.pipe(select(CameraSelectors.selectCameraState))),
      switchMap(([{ id }, allCameras]) => {
        return this.alertEventsService.getSelectedEvent(id)
          .pipe(
            switchMap(res => {
              const actions: Action[] = [
                AlertEventsActions.setSelectedAlert({
                  selectedAlertEvent: {
                    ...res,
                    selectedCamera: allCameras.entities[res.selectedCamera.cameraId],
                  },
                }),
              ];
              actions.push(AlertEventsActions.checkDisabledMenu());
              return actions;
            }),
          );
      }),
      share(),
    ),
  );

  public removeAlertEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.removeEvent),
      mergeMap(({ id }) => {
        return this.alertEventsService
          .remove(id)
          .pipe(
            mergeMap(res => {
              const actions = [];
              for(let result of res) {
                actions.push(SharedActions.subscribeToSessionStatus({
                  token: result?.token?.session,
                  sessionDataAction: SessionDataAction.removeEvent,
                  params: {
                    msTimeout: 40000,
                  },
                }));
              }
              return [
                ...actions,
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public enableAlertEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.enableEvent),
      mergeMap(({ alert, enabled }) => {
        const operation = enabled ? this.alertEventsService.enable(alert._id) : this.alertEventsService.disable(alert._id);
        return operation
          .pipe(
            mergeMap(res => {
              return [
                AlertEventsActions.enableEventSuccess({ alert, enabled }),
                SharedActions.showMessage({
                  success: `Alert has been ${enabled ? 'enabled' : 'disabled'}`,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public syncAlertEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.syncEvent),
      mergeMap(({ alert, synced }) => {
        return this.alertEventsService.sync(alert._id, alert)
          .pipe(
            mergeMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res?.length ? res[0]?.token?.session : res?.token?.session,
                  sessionDataAction: SessionDataAction.sync,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public migrateEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.migrateEvent),
      switchMap(({ alert, synced }) => {
        return this.alertEventsService.migrate(alert._id, alert)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.subscribeToSessionStatus({
                  token: res.token.session,
                  sessionDataAction: SessionDataAction.migrate,
                }),
              ];
            }),
            catchError(err => {
              return [
                SharedActions.showMessage({
                  error: `Event migration failed ${err.message}`,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public setSearchQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AlertEventsActions.setQuery,
        AlertEventsActions.setCameras,
        AlertEventsActions.setFlowTypes,
        AlertEventsActions.removeEventSuccess),
      debounceTime(400),
      switchMap(() => [AlertEventsActions.resetAlertEventsState(), AlertEventsActions.getAlertEvents()]),
    ),
  );

  public setAlertType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertEventsActions.setAlertType, AlertEventsActions.checkDisabledMenu),
      withLatestFrom(this.store$.pipe(select(state => state.alertEventsState))),
      switchMap(([, { selectedAlertType }]) => {
        switch (selectedAlertType) {
          case AlertType.edgeStatus:
          case AlertType.analyticStatus:
          case AlertType.cameraStatus:
            return [
              MenuActions.renameLevel2Point({
                key: MenuKey.cameras,
                name: selectedAlertType === AlertType.edgeStatus ? 'Cores' : 'Cameras',
              }),
              MenuActions.renameLevel3Point({
                level2Key: MenuKey.data,
                key: MenuKey.cameras,
                name: selectedAlertType === AlertType.edgeStatus ? 'Cores' : 'Cameras',
              }),
              MenuActions.disableLevel2Point({ key: MenuKey.zones }),
              MenuActions.disableLevel3Point({ level2Key: MenuKey.data, key: MenuKey.zones }),
              MenuActions.enableLevel2Point({ key: MenuKey.cameras }),
              MenuActions.enableLevel3Point({ level2Key: MenuKey.data, key: MenuKey.cameras }),
            ];
          case AlertType.analytic:
            return [
              MenuActions.enableLevel2Point({ key: MenuKey.zones }),
              MenuActions.enableLevel3Point({ level2Key: MenuKey.data, key: MenuKey.zones }),
              MenuActions.disableLevel2Point({ key: MenuKey.cameras }),
              MenuActions.disableLevel3Point({ level2Key: MenuKey.data, key: MenuKey.cameras }),
            ];
          default:
            return [
              MenuActions.enableLevel2Point({ key: MenuKey.cameras }),
              MenuActions.enableLevel3Point({ level2Key: MenuKey.data, key: MenuKey.cameras }),
              MenuActions.enableLevel2Point({ key: MenuKey.zones }),
              MenuActions.enableLevel3Point({ level2Key: MenuKey.data, key: MenuKey.zones }),
            ];
        }
      }),
    ),
  );


  public getAlertEventsLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.getCameraEventsLookup),
      switchMap(() => {
        return this.alertEventsService.getAllNoPaging()
          .pipe(
            switchMap(res => {
              const lookup: {
                [key: string]: {
                  [alertId: string]: AlertV2Document
                }
              } = {};
              res.forEach(item => {
                if (item?.selectedFlow?.formValue?.camera?.length) {
                  for(let camera of item.selectedFlow.formValue.camera) {
                    const key = camera?.cameraId;
                    if (key) {
                      if (lookup[key]) {
                        lookup[key] = {
                          ...lookup[key],
                          [item._id]: item,
                        };
                      } else {
                        lookup[key] = {
                          [item._id]: item,
                        };
                      }
                    }
                  }
                }
              });
              return [
                CameraActions.getCameraEventsLookupSuccess({
                  cameraEvents: lookup,
                }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

  constructor(private actions$: Actions, private store$: Store<AppState>, private alertEventsService: AlertEventsService) {
  }
}

const loadConfigurationFilters = (state: AlertEventsState): any => {
  if (state.configurationFilterType === ConfigurationFilterType.noFilters) {
    return {};
  }
  switch (state.trackedObject) {
    case AnalyticClasses.person:
      return {
        ageType: state.ageType,
        carryingType: state.carryingType,
        lowerbodyType: state.lowerBodyType,
        upperbodyType: state.upperBodyType,
        accessoryType: state.accessoryType,
        footwearType: state.footWearType,
        hairType: state.hairType,
        genderType: state.genderType,
        upperbodyColor: state.upperBodyColor,
        lowerbodyColor: state.lowerBodyColor,
        hairColor: state.hairColor,
        footwearColor: state.footWearColor,
      };
    case AnalyticClasses.vehicle:
      if (state.configurationFilterType === ConfigurationFilterType.specificAttributes) {
        return {
          make: state.carMake,
          model: state.carModel,
          type: state.carType,
          colors: state.carColor,
        };
      } else if (state.configurationFilterType === ConfigurationFilterType.licencePlate) {
        return {
          greenList: state.greenList,
          redList: state.redList,
          unrecognized: state.unrecognized,
        };
      }
      return {};
  }
};
