import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { api } from '@consts/url.const';
import { AccessDoorsModel } from '@models/access-doors.model';

@Injectable()
export class AccessDoorsService {

  constructor(private http: HttpClient) {
  }

  public getOrganizationAccessDoors(sourceType?: AccessDoorsModel.SourceType): Observable<AccessDoorsModel.DocumentMongo[]> {
    let url = api.accessDoors.crud;
    if (sourceType) {
      url += `?sourceType=${sourceType}`;
    }
    return this.http.get<AccessDoorsModel.DocumentMongo[]>(url);
  }

  public create(data: AccessDoorsModel.CreateDocumentDto, sourceType?: AccessDoorsModel.SourceType): Observable<AccessDoorsModel.DocumentMongo> {
    let url = api.accessDoors.crud;
    if (sourceType) {
      url += `?sourceType=${sourceType}`;
    }
    return this.http.post<AccessDoorsModel.DocumentMongo>(url, data);
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(api.accessDoors.geOne(id));
  }

  public getAlerts(id?: string, sourceType?: AccessDoorsModel.SourceType): Observable<AccessDoorsModel.DoorEventListItem> {
    let url = api.accessDoors.doorAlerts(id);
    if (sourceType) {
      url += `?sourceType=${sourceType}`;
    }
    return this.http.get<AccessDoorsModel.DoorEventListItem>(url);
  }

  public update(data: AccessDoorsModel.DocumentMongo): Observable<AccessDoorsModel.DocumentMongo> {
    return this.http.put<AccessDoorsModel.DocumentMongo>(api.accessDoors.geOne(data._id), data);
  }

  public getDoorLocations(sourceType?: AccessDoorsModel.SourceType): Observable<any> {
    let url = api.accessDoors.locations;
    if (sourceType) {
      url += `?sourceType=${sourceType}`;
    }
    return this.http.get<any>(url);
  }

  public getLocationDoors(locationId: string, sourceType?: AccessDoorsModel.SourceType): Observable<any> {
    let url = api.accessDoors.doors(locationId);
    if (sourceType) {
      url += `?sourceType=${sourceType}`;
    }
    return this.http.get<any>(url);
  }

  public openDoor(doorId: string, sourceType?: AccessDoorsModel.SourceType): Observable<any> {
    let url = api.accessDoors.openDoor(doorId);
    if (sourceType) {
      url += `?sourceType=${sourceType}`;
    }
    return this.http.get<any>(url);
  }

  public closeDoor(doorId: string, sourceType?: AccessDoorsModel.SourceType): Observable<any> {
    let url = api.accessDoors.closeDoor(doorId);
    if (sourceType) {
      url += `?sourceType=${sourceType}`;
    }
    return this.http.get<any>(url);
  }

  public getDoorState(doorId: string, sourceType?: AccessDoorsModel.SourceType): Observable<{ isLocked: boolean }> {
    let url = api.accessDoors.getDoorState(doorId);
    if (sourceType) {
      url += `?sourceType=${sourceType}`;
    }
    return this.http.get<{ isLocked: boolean }>(url);
  }
}
