import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { StorageSelectors } from '@states/storage/storage.selector-types';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { catchError, defaultIfEmpty, EmptyError, filter, lastValueFrom, Observable, of, take, timeout } from 'rxjs';
import * as _ from 'lodash';
import { StorageModel } from '@models/storage.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MultiPlaybackMove } from '@models/multi-playback.model';
import { MultiPlaybackSelectors } from '@states/multi-playback/multi-playback.selector-types';

@UntilDestroy()
@Component({
  selector: 'app-storage-histogram',
  templateUrl: './storage-histogram.component.html',
  styleUrls: ['./storage-histogram.component.scss'],
})
export class StorageHistogramComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  ctx: CanvasRenderingContext2D;

  @Input() edgeId: string;
  @Input() cameraId: string;
  @Input() start: number;
  @Input() end: number;
  @Input() parent: HTMLDivElement;
  @Input() isCameraView = false;
  @Input() streamLiveView = false;

  baseStart: number;
  baseEnd: number;

  public selectMove$: Observable<MultiPlaybackMove> = this.store$.select(MultiPlaybackSelectors.selectMove)
    .pipe(untilDestroyed(this));


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.render();
  }

  constructor(private store$: Store, private el: ElementRef, private cameraThumbnailsService: CamerasThumbnailsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['start'] || changes['end']) {
      this.render();
    }

    if (changes['parent']) {
      this.canvas.nativeElement.width = this.parent.clientWidth;
      this.canvas.nativeElement.height = this.parent.clientHeight;
    }
  }

  ngOnInit(): void {
  }


  ngAfterViewInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    const height = 38; // You can adjust the height as needed

    this.ctx.canvas.width = this.parent.clientWidth;
    this.ctx.canvas.height = this.parent.clientHeight;
    const baseStart = this.getStartOfDay(this.start);
    this.store$.select(
        StorageSelectors.selectStorageEventsByEdgeIdCameraIdAndBase({ edgeId: this.edgeId, cameraId: this.cameraId, base: baseStart }),
      )
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.render();
      });
    this.render();

    this.selectMove$.pipe(untilDestroyed(this))
      .pipe(filter(move => !!move.timestamp && !!move.percentage))
      .subscribe((move) => {
        if (this.isCameraView && this.streamLiveView) {
          const delta = this.end - this.start;
          this.start = move.timestamp - delta / 2;
          this.end = move.timestamp + delta / 2;
          this.render();
        }
      });

  }

  render(resize = false) {
    if (resize) {
      this.canvas.nativeElement.width = this.parent.clientWidth;
      this.canvas.nativeElement.height = this.parent.clientHeight;
    }
    const baseStart = this.getStartOfDay(this.start);
    const baseEnd = this.getStartOfDay(this.end);
    this.baseStart = baseStart;
    this.baseEnd = baseEnd;

    if (baseStart === baseEnd) {
      this.handleSingleDay(this.ctx, baseStart);
    } else {
      this.handleSpannedDays(this.ctx, baseStart, baseEnd);
    }
  }

  getStartOfDay(timestamp: number): number {
    return timestamp - (timestamp % (24 * 60 * 60 * 1000));
  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date);
  }

  handleSingleDay(ctx: CanvasRenderingContext2D, base: number) {
    const edgeId = this.edgeId;
    const cameraId = this.cameraId;
    const now = Date.now();
    const todayBase = this.getStartOfDay(now);
    this.store$.select(
        StorageSelectors.selectStorageEventsByEdgeIdCameraIdAndBase({ edgeId, cameraId, base }),
      )
      .pipe(take(1))
      .subscribe((data) => {
        if (data) {
          this.drawData(data.noStorage, data.smartStorage);
        } else {
          if (todayBase !== base) {
            this.drawData([[0, now - this.baseStart]], []);
          }
          this._drawFuture(now - this.baseStart);
        }
      });
  }

  public getStorageStats(edgeId: string, cameraId: string, base: number) {
    return this.store$.select(
        StorageSelectors.selectStorageEventsByEdgeIdCameraIdAndBase({ edgeId, cameraId, base }),
      )
      .pipe(filter(data => !!data), take(1), timeout(2000)
        , catchError((err) => {
          if (err.message === 'Timeout has occurred') {
            // It's a timeout error. Handle it without logging.
            const storageStats: StorageModel.StorageStatsOfDay = {
              edgeId,
              cameraId,
              base,
              cacheId: `${edgeId}:${cameraId}:${base}`,
              // noStorage: [[0, 86400000]],
              noStorage: [],
              smartStorage: [],
            };
            return of(storageStats); // or however you want to handle it
          }
          if (err instanceof EmptyError) {
            console.warn('No elements in sequence');
            return of([]);
          }
          // Handle other errors
          console.error(err);
          return of([]);
        }),
        defaultIfEmpty([]),
      );
  }

  async handleSpannedDays(ctx: CanvasRenderingContext2D, startBase: number, endBase: number) {
    const edgeId = this.edgeId;
    const cameraId = this.cameraId;

    const startData: StorageModel.StorageStatsOfDay = await lastValueFrom(this.getStorageStats(edgeId, cameraId, startBase)) as StorageModel.StorageStatsOfDay;
    const endData: StorageModel.StorageStatsOfDay = await lastValueFrom(this.getStorageStats(edgeId, cameraId, endBase)) as StorageModel.StorageStatsOfDay;

    const endStats = _.cloneDeep(endData?.noStorage ?? []);
    const endSmartStorage = _.cloneDeep(endData?.smartStorage ?? []);

    for(let stat of endStats) {
      stat[0] += 86400000;
      stat[1] += 86400000;
    }
    for(let stat of endSmartStorage) {
      stat[0] += 86400000;
      stat[1] += 86400000;
    }
    this.drawData((!!startData?.noStorage ? startData.noStorage : []).concat(endStats), (!!startData?.smartStorage ? startData?.smartStorage : []).concat(endSmartStorage));
  }

  // drawData(ctx: CanvasRenderingContext2D, data: number[][], start: number, end: number) {
  //   ctx.fillStyle = 'black';
  //   ctx.fillRect(0, 0, this.parentWidth, 5);
  //
  //   for (let period of data) {
  //     if (period[1] < start || period[0] > end) continue;
  //     const delta = this.end - this.start;
  //     const startX = Math.max(period[0] - this.start, 0);
  //     const endX = Math.min(period[1] - this.start, this.end - this.start);
  //
  //     ctx.fillStyle = 'blue';
  //     ctx.fillRect(startX, 0, endX - startX, 5);
  //   }
  // }

  _drawFuture(startOffset: number) {
    if (!this.ctx) {
      return;
    }
    const relativeStart = this.baseStart + startOffset - this.start;
    const width = this.parent.clientWidth;
    const duration = this.end - this.start;
    const ratio = width / duration;
    this.ctx.fillStyle = '#fafafb';
    // calculate the remaining width from the right and fill it
    this.ctx.fillRect(
      Math.max(relativeStart * ratio, 0),
      0,
      width - Math.max(relativeStart * ratio, 0),
      100,
    );
  }

  _draw(stats: number[][], color: string, duration: number, ratio: number) {
    const relativeNow = this.baseStart + Date.now() - this.start;
    for(let i = 0; i < stats?.length; i++) {
      const [startOffset, endOffset] = stats[i];
      const relativeStart = this.baseStart + startOffset - this.start;
      const relativeEnd = this.baseStart + endOffset - this.start;
      if (relativeNow < relativeStart || relativeNow < relativeEnd) {
        continue;
      }
      if (relativeEnd > 0 && relativeStart < duration) {
        this.ctx.fillStyle = color;
        this.ctx.fillRect(
          Math.max(relativeStart * ratio, 0),
          0,
          (Math.min(relativeEnd, duration) - Math.max(relativeStart, 0)) * ratio,
          18,
        );
      }
    }
  }

  _drawLiveView() {
    const width = this.parent.clientWidth;
    const duration = this.end - this.start;
    this.ctx.fillStyle = 'rgba(16, 24, 40, 0.02)';
    // calculate the remaining width from the right and fill it
    this.ctx.fillRect(
      width / 2,
      0,
      width,
      100,
    );
  }

  drawData(noStorage: number[][], smartStorage: number[][]) {
    if (!this.ctx) {
      return;
    }

    const width = this.parent.clientWidth;
    const duration = this.end - this.start;
    const ratio = width / duration;

    this.ctx.setTransform(1, 0, 0, -1, 0, this.canvas.nativeElement.height);
    this.ctx.clearRect(0, 0, width, 100);

    const todayBase = this.getStartOfDay(Date.now());
    const now = Date.now();

    if (todayBase <= this.baseEnd && noStorage?.length) {
      noStorage = _.cloneDeep(noStorage);
      // noStorage.splice(noStorage.length - 1, 1);
      noStorage = noStorage.filter(storage => storage[1] < 86399999);

      const ts = this.start + (this.end - this.start) / 2;
      if (now - ts < 2000) {
        this._drawLiveView();
      } else {
        this._drawFuture(now - this.baseStart);
      }
    }

    this._draw(noStorage, '#101828', duration, ratio);
    this._draw(smartStorage, '#0EA5E9', duration, ratio);

    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
  }
}
