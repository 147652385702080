import { MenuItem } from '@models/menu.model';
import { MenuKey } from '@enums/menu.enum';
import { routerSegments } from '@consts/routes';

export const alertEventsMenuLevel2: MenuItem[] = [
  {
    name: 'Details',
    route: routerSegments.details,
    key: MenuKey.details,
  },
  {
    name: 'Configuration',
    route: routerSegments.configuration,
    key: MenuKey.configuration,
  },
  {
    name: 'Edges',
    route: routerSegments.cameras,
    key: MenuKey.cameras,
  },
  {
    name: 'Zones',
    route: routerSegments.zones,
    key: MenuKey.zones,
  },
  {
    name: 'Settings',
    route: routerSegments.settings,
    key: MenuKey.settings,
  },
  {
    name: 'Notifications',
    route: routerSegments.notifications,
    key: MenuKey.notifications,
  },
  {
    name: 'Actions',
    route: routerSegments.actions,
    key: MenuKey.actions,
  },
];

export const cameraEditUserMenuLevel2: MenuItem[] = [
  {
    name: 'Details',
    route: routerSegments.details,
    key: MenuKey.details,
    iconClass: 'ui-icon-billing',
  },
  {
    name: 'Streams',
    route: routerSegments.network,
    key: MenuKey.network,
    iconClass: 'ui-icon-stream',
  },
  {
    name: 'Analytics',
    route: routerSegments.searchZones,
    key: MenuKey.searchZones,
    iconClass: 'ui-icon-object-analytic',
  },
  // {
  //   name: 'Privacy',
  //   route: routerSegments.privacy,
  //   key: MenuKey.privacy,
  // },
  {
    name: 'Location',
    route: routerSegments.cameraLocation,
    key: MenuKey.location,
    iconClass: 'ui-icon-location',
  },
  {
    name: 'Storage',
    route: routerSegments.storageSettings,
    key: MenuKey.storageSettings,
    iconClass: 'ui-icon-archive',
  },
  {
    name: 'PTZ',
    route: routerSegments.ptz,
    key: MenuKey.ptz,
    iconClass: 'ui-icon-ptz',
  },
  {
    name: 'Monitoring',
    route: routerSegments.monitoring,
    key: MenuKey.monitoring,
    iconClass: 'ui-icon-live',
  },

  {
    name: 'Shortcuts',
    route: routerSegments.shortcut,
    key: MenuKey.shortcut,
    iconClass: 'ui-icon-live',
  },

];

export const cameraEditDeveloperMenuLevel2: MenuItem[] = [
  {
    name: 'Details',
    route: routerSegments.details,
    key: MenuKey.details,
    iconClass: null,
    svgIcon: 'billing',
  },
  {
    name: 'Location',
    route: routerSegments.cameraLocation,
    key: MenuKey.location,
    iconClass: 'ui-icon-location',
  },
  {
    name: 'Streams',
    route: routerSegments.network,
    key: MenuKey.network,
    iconClass: 'ui-icon-stream',
  },
  {
    name: 'Video & audio',
    route: routerSegments.videoAudio,
    key: MenuKey.videoAudio,
    iconClass: 'ui-icon-history',
  },
  {
    name: 'HLS Management',
    route: routerSegments.hlsManagement,
    key: MenuKey.hlsManagement,
    iconClass: 'ui-icon-history',
  },
  {
    name: 'PTZ',
    route: routerSegments.ptz,
    key: MenuKey.ptz,
    iconClass: 'ui-icon-ptz',
  },
  {
    name: 'Analytics',
    route: routerSegments.searchZones,
    key: MenuKey.searchZones,
    iconClass: 'ui-icon-object-analytic',
  },
  // {
  //   name: 'Privacy',
  //   route: routerSegments.privacy,
  //   key: MenuKey.privacy,
  // },
  {
    name: 'Storage',
    route: routerSegments.storageSettings,
    key: MenuKey.storageSettings,
    iconClass: 'ui-icon-archive',
  },
  {
    name: 'Assets',
    route: routerSegments.assets,
    key: MenuKey.assets,
    iconClass: 'ui-icon-assets',
  },
  {
    name: 'Search settings',
    route: routerSegments.searchSettings,
    key: MenuKey.searchSettings,
    iconClass: 'ui-icon-settings',
  },
  {
    name: 'Error report',
    route: routerSegments.errorReport,
    key: MenuKey.errorReport,
    iconClass: 'ui-icon-warning',
  },
  {
    name: 'Monitoring',
    route: routerSegments.monitoring,
    key: MenuKey.monitoring,
    iconClass: 'ui-icon-live',
  },
  {
    name: 'Shortcuts',
    route: routerSegments.shortcut,
    key: MenuKey.shortcut,
    iconClass: 'ui-icon-camera-shortcut',
  },
  // {
  //   name: 'Muxer',
  //   route: routerSegments.muxer,
  //   key: MenuKey.muxer,
  // },
];

export const edgeEditDevloperMenuLevel2: MenuItem[] = [
  {
    name: 'Details',
    route: routerSegments.details,
    key: MenuKey.details,
  },
  {
    name: 'Network',
    route: routerSegments.network,
    key: MenuKey.network,
  },
  {
    name: 'NTP',
    route: routerSegments.ntp,
    key: MenuKey.ntp,
  },
  {
    name: 'Configuration',
    route: routerSegments.configuration,
    key: MenuKey.configuration,
  },
  {
    name: 'SW Update',
    route: routerSegments.swUpdate,
    key: MenuKey.swUpdate,
  },
  {
    name: 'Upload Configuration',
    route: routerSegments.uploadConfiguration,
    key: MenuKey.uploadConfiguration,
  },
  {
    name: 'Core Document',
    route: routerSegments.document,
    key: MenuKey.document,
  },
  {
    name: 'Core Info',
    route: routerSegments.info,
    key: MenuKey.info,
  },
  {
    name: 'Core Management',
    route: routerSegments.management,
    key: MenuKey.management,
  },
  {
    name: 'Core Logs',
    route: routerSegments.logs,
    key: MenuKey.logs,
  },
  {
    name: 'Core Pulsation Singlestore',
    route: routerSegments.pulsationState,
    key: MenuKey.pulsationState,
  },
  {
    name: 'Core Thumbnails Bitmap',
    route: routerSegments.thumbnailsBitmap,
    key: MenuKey.thumbnailsBitmap,
  },
  {
    name: 'Video Upload Storage',
    route: routerSegments.uploadVideoStorage,
    key: MenuKey.uploadVideoStorage,
  },
  {
    name: 'Stats',
    route: routerSegments.uptime,
    key: MenuKey.uptime,
  },
  {
    name: 'Analytic Configuration',
    route: routerSegments.analyticConfiguration,
    key: MenuKey.analyticConfiguration,
  },
  {
    name: 'Test camera',
    route: routerSegments.testCamera,
    key: MenuKey.testCamera,
  },
  {
    name: 'Core hash info',
    route: routerSegments.coreInfo,
    key: MenuKey.coreInfo,
  },
  {
    name: 'Core recovery',
    route: routerSegments.recovery,
    key: MenuKey.recovery,
  },
  {
    name: 'Core component status',
    route: routerSegments.heartBeatStatus,
    key: MenuKey.heartBeatStatus,
  },
  {
    name: 'Monitoring',
    route: routerSegments.monitoring,
    key: MenuKey.monitoring,
  },
  {
    name: 'Certifications',
    route: routerSegments.certifications,
    key: MenuKey.certifications,
  },
  {
    name: 'DHCP Server',
    route: routerSegments.dhcp,
    key: MenuKey.dhcp,
  },
];

export const edgeEditOrganizationAdminMenuLevel2: MenuItem[] = [
  {
    name: 'Details',
    route: routerSegments.details,
    key: MenuKey.details,
  },
  {
    name: 'Network',
    route: routerSegments.network,
    key: MenuKey.network,
  },
  {
    name: 'NTP',
    route: routerSegments.ntp,
    key: MenuKey.ntp,
  },
  {
    name: 'Stats',
    route: routerSegments.uptime,
    key: MenuKey.uptime,
  },
  {
    name: 'DHCP Server',
    route: routerSegments.dhcp,
    key: MenuKey.dhcp,
  },
];

export const edgeEditOrganizationUserMenuLevel2: MenuItem[] = [
  {
    name: 'Details',
    route: routerSegments.details,
    key: MenuKey.details,
  },
  {
    name: 'Stats',
    route: routerSegments.uptime,
    key: MenuKey.uptime,
  },
];

export const variableMenuLevel2: MenuItem[] = [
  {
    name: 'Details',
    route: routerSegments.details,
    key: MenuKey.details,
  },
  {
    name: 'Define Data',
    route: routerSegments.data,
    key: MenuKey.data,
  },
  {
    name: 'Settings',
    route: routerSegments.settings,
    key: MenuKey.settings,
  },
];

export const variableMenuLevel3AlertCreate = [
  {
    name: 'Alert Details',
    route: routerSegments.alertDetails,
    key: MenuKey.alertDetails,
  },
  {
    name: 'Configuration',
    route: routerSegments.configuration,
    key: MenuKey.configuration,
  },
  {
    name: 'Edges',
    route: routerSegments.cameras,
    key: MenuKey.cameras,
  },
  {
    name: 'Zones',
    route: routerSegments.zones,
    key: MenuKey.zones,
  },
];

export const variableMenuLevel3SearchCreate = [
  {
    name: 'Cameras',
    route: routerSegments.variableCameras,
    key: MenuKey.variableCameras,
  },
  {
    name: 'Objects',
    route: routerSegments.objects,
    key: MenuKey.objects,
  },
];

export const variableMenuLevel2Validation = {
  [MenuKey.details]: false,
  [MenuKey.data]: false,
  [MenuKey.settings]: false,
};

export const variableEditMenuLevel2Validation = {
  [MenuKey.details]: true,
  [MenuKey.data]: true,
  [MenuKey.settings]: true,
};

export const camerasMenuLevel2: MenuItem[] = [
  {
    name: 'Configuration',
    route: routerSegments.configuration,
    key: MenuKey.configuration,
  },
  {
    name: 'Details',
    route: routerSegments.configuration,
    key: MenuKey.details,
  },
];

export const camerasMenuLevel2Validation = {
  [MenuKey.configuration]: false,
  [MenuKey.details]: false,
};

export const camerasFileMenuLevel2: MenuItem[] = [
  {
    name: 'Upload File',
    route: routerSegments.uploadFile,
    key: MenuKey.uploadFile,
  },
  {
    name: 'Preview Data',
    route: routerSegments.previewData,
    key: MenuKey.previewData,
  },
  {
    name: 'Import Cameras',
    route: routerSegments.import,
    key: MenuKey.import,
  },
];

export const camerasFileMenuLevel2Validation = {
  [MenuKey.uploadFile]: false,
  [MenuKey.previewData]: false,
  [MenuKey.import]: false,
};

export const camerasMenuLevel3Onvif = [
  {
    name: 'Select camera',
    route: routerSegments.camera,
    key: MenuKey.cameras,
  },
  {
    name: 'Config',
    route: routerSegments.configure,
    key: MenuKey.configure,
  },
  {
    name: 'Information',
    route: routerSegments.info,
    key: MenuKey.info,
  },
];

export const alertEventsMenuLevel2Validation = {
  [MenuKey.details]: false,
  [MenuKey.configuration]: false,
  [MenuKey.zones]: false,
  [MenuKey.settings]: false,
  [MenuKey.notifications]: false,
  [MenuKey.actions]: false,
};

export const cameraEditMenuLevel2Validation = {
  [MenuKey.cameraEdit]: true,
  [MenuKey.location]: true,
  [MenuKey.assets]: true,
  [MenuKey.network]: true,
  [MenuKey.details]: true,
  [MenuKey.videoAudio]: true,
  [MenuKey.hlsManagement]: true,
  [MenuKey.ptz]: true,
  [MenuKey.muxer]: true,
  [MenuKey.searchZones]: true,
  [MenuKey.privacy]: true,
  [MenuKey.searchSettings]: true,
  [MenuKey.storageSettings]: true,
  [MenuKey.errorReport]: true,
  [MenuKey.monitoring]: true,
  [MenuKey.shortcut]: true,
};

export const edgeEditMenuLevel2Validation = {
  [MenuKey.details]: true,
  [MenuKey.network]: true,
  [MenuKey.configuration]: true,
  [MenuKey.swUpdate]: true,
  [MenuKey.uploadConfiguration]: true,
  [MenuKey.document]: true,
  [MenuKey.info]: true,
  [MenuKey.management]: true,
  [MenuKey.pulsation]: true,
  [MenuKey.thumbnailsBitmap]: true,
  [MenuKey.logs]: true,
  [MenuKey.uploadVideoStorage]: true,
  [MenuKey.uptime]: true,
  [MenuKey.analyticConfiguration]: true,
  [MenuKey.ntp]: true,
  [MenuKey.coreInfo]: true,
  [MenuKey.testCamera]: true,
  [MenuKey.pulsationState]: true,
  [MenuKey.recovery]: true,
  [MenuKey.heartBeatStatus]: true,
  [MenuKey.monitoring]: true,
  [MenuKey.certifications]: true,
  [MenuKey.dhcp]: true,
};

export const menuKeyStringBreadcrumb: {
  [key: number]: string
} = {
  [MenuKey.details]: 'Details',
  [MenuKey.configuration]: 'Configuration',
  [MenuKey.uploadConfiguration]: 'Upload Configuration',
  [MenuKey.document]: 'Document',
  [MenuKey.info]: 'Info',
  [MenuKey.management]: 'Management',
  [MenuKey.pulsation]: 'Core Pulsation',
  [MenuKey.thumbnailsBitmap]: 'Thumbnails Beatmap',
  [MenuKey.logs]: 'Logs',
};

export const locationEditMenuLevel2: MenuItem[] = [
  {
    name: 'Details',
    route: routerSegments.details,
    key: MenuKey.details,
    iconClass: 'ui-icon-list-view-2',
  },
  {
    name: 'Configuration',
    route: routerSegments.configuration,
    key: MenuKey.configuration,
    iconClass: 'ui-icon-globe',
  },
  {
    name: 'Speakers',
    route: routerSegments.speakers,
    key: MenuKey.speakers,
    iconClass: 'ui-icon-speaker',
  },
  {
    name: 'Apple TV',
    route: routerSegments.appleTV,
    key: MenuKey.appleTVList,
    isDeveloper: true,
    iconClass: 'ui-icon-stream',
  },
  {
    name: 'External Storage',
    route: routerSegments.externalStorage,
    key: MenuKey.externalStorage,
    isDeveloper: true,
    iconClass: 'ui-icon-storage',
  },
];

export const locationEditMenuLevel2Validation = {
  [MenuKey.details]: true,
  [MenuKey.configuration]: true,
  [MenuKey.speakers]: true,
  [MenuKey.appleTVList]: true,
  [MenuKey.externalStorage]: true,
};

export const cameraEditVPNMenuLevel2: MenuItem[] = [
  {
    name: 'Details',
    route: routerSegments.details,
    key: MenuKey.details,
    iconClass: 'ui-icon-billing',
  },
  {
    name: 'Network',
    route: routerSegments.network,
    key: MenuKey.network,
    iconClass: 'ui-icon-globe',
  },
  {
    name: 'Video & audio',
    route: routerSegments.videoAudio,
    key: MenuKey.videoAudio,
    iconClass: 'ui-icon-history',
  },
  {
    name: 'PTZ',
    route: routerSegments.ptz,
    key: MenuKey.ptz,
    iconClass: 'ui-icon-ptz',
  },
  {
    name: 'Analytics',
    route: routerSegments.searchZones,
    key: MenuKey.searchZones,
    iconClass: 'ui-icon-object-analytic',
  },
  // {
  //   name: 'Privacy',
  //   route: routerSegments.privacy,
  //   key: MenuKey.privacy,
  // },
  {
    name: 'Storage',
    route: routerSegments.storageSettings,
    key: MenuKey.storageSettings,
    iconClass: 'ui-icon-archive',
  },
  {
    name: 'Assets',
    route: routerSegments.assets,
    key: MenuKey.assets,
    iconClass: 'ui-icon-assets',
  },
  {
    name: 'Search settings',
    route: routerSegments.searchSettings,
    key: MenuKey.searchSettings,
    iconClass: 'ui-icon-settings',
  },
  {
    name: 'Error report',
    route: routerSegments.errorReport,
    key: MenuKey.errorReport,
    iconClass: 'ui-icon-warning',
  },
  {
    name: 'Monitoring',
    route: routerSegments.monitoring,
    key: MenuKey.monitoring,
    iconClass: 'ui-icon-live',
  },

  // {
  //   name: 'Muxer',
  //   route: routerSegments.muxer,
  //   key: MenuKey.muxer,
  // },
];
