import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { HomeActions } from '@states/home/home.action-types';
import { Dictionary } from '@ngrx/entity/src/models';
import { sortArrByField } from '../../../helpers/common.helpers';
import { HomeModel } from '@models/home.model';


export interface HomeState extends EntityState<HomeModel.Location> {
  initialLoaded: boolean;// show loader until first loading happening
  notEmpty: boolean; //show no Data if result empty
  listLoading: boolean; //show loaded each time when query params changed
  isSaving: boolean;
  toRemove: Dictionary<boolean>;
  favorites: string[];
  subLocationCameras: Dictionary<boolean>;
  filteredLocations: HomeModel.Location[];
  query?: string;
  selectedLocationId?: string;
  selectedCameras?: HomeModel.Camera[];
  locationCameraSelectionMode?: string;
  gotoCameraId?: string;
}

export const homeAdapter: EntityAdapter<HomeModel.Location> = createEntityAdapter<HomeModel.Location>({
  selectId: (location: HomeModel.Location) => location._id,
});

const initialState: HomeState = homeAdapter.getInitialState({
  initialLoaded: false,
  notEmpty: false,
  listLoading: false,
  isSaving: false,
  toRemove: {},
  favorites: [],
  subLocationCameras: {},
  filteredLocations: [],
  query: null,
  // ...homeTestEntities, // [TODO] remove when front end is connected to backend
  selectedCameras: [],
  locationCameraSelectionMode: null,
});


export const homeStateReducer = createReducer(
  initialState,
  on(HomeActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(HomeActions.setIsSaving, (state, { isSaving }) => {
    return {
      ...state,
      isSaving,
    };
  }),
  on(HomeActions.getLocationsSuccess, (state, { locations }) => {
    const subLocationCameras = {};
    for(let location of locations) {
      location.cameras.forEach(camera => {
        subLocationCameras[camera?.cameraId] = true;
      });
    }
    return homeAdapter.setAll(locations, {
      ...state,
      initialLoaded: true,
      notEmpty: !!locations.length,
      subLocationCameras,
    });
  }),
  on(HomeActions.deleteLocationById, (state, { id }) => {
    return {
      ...state,
      toRemove: {
        ...state.toRemove,
        [id]: true,
      },
    };
  }),
  on(HomeActions.deleteLocationByIdSuccess, (state, { id }) => {
    const toRemove = { ...state.toRemove };
    delete toRemove[id];
    return homeAdapter.removeOne(id, {
      ...state,
      toRemove,
    });
  }),
  on(HomeActions.deleteLocationByIdFail, (state, { id }) => {
    const toRemove = { ...state.toRemove };
    delete toRemove[id];
    return {
      ...state,
      toRemove,
    };
  }),
  on(HomeActions.serverRequestCreateSubLocationSuccess, (state, { document }) => {
    const documents = {
      ...state.entities,
      [document._id]: document,
    };
    const documentArray = Object.values(documents ?? {});
    const sortedAlerts = sortArrByField<HomeModel.Location>(documentArray, 'createdAt', 'desc');
    return homeAdapter.setAll(sortedAlerts, {
      ...state,
    });
  }),
  on(HomeActions.starLocation, (state, { id }) => {
    return {
      ...state,
      favorites: [...state.favorites, id],
    };
  }),
  on(HomeActions.unstarLocation, (state, { id }) => {
    return {
      ...state,
      favorites: state.favorites.filter(item => item !== id),
    };
  }),
  on(HomeActions.getFavoriteLocationsSuccess, (state, { favorites }) => {
    return {
      ...state,
      favorites,
    };
  }),
  on(HomeActions.setFilteredLocations, (state, { locations }) => {
    return {
      ...state,
      filteredLocations: locations,
    };
  }),
  on(HomeActions.setQuery, (state, { query }) => {
    return {
      ...state,
      query,
    };
  }),
  on(HomeActions.setSelectedLocationId, (state, { selectedLocationId }) => {
    return {
      ...state,
      selectedLocationId,
    };
  }),
  on(HomeActions.unsetSelectedLocationId, (state, {}) => {
    return {
      ...state,
      selectedLocationId: null,
    };
  }),
  on(HomeActions.clearSelectedCameras, (state, {}) => {
    return {
      ...state,
      selectedCameras: [],
    };
  }),
  on(HomeActions.addSelectedCamera, (state, { camera }) => {
    return {
      ...state,
      selectedCameras: [...state.selectedCameras, camera],
    };
  }),
  on(HomeActions.removeSelectedCamera, (state, { cameraId }) => {
    return {
      ...state,
      selectedCameras: state.selectedCameras.filter(camera => camera.cameraId !== cameraId),
    };
  }),
  on(HomeActions.setCameraSelectionModeForLocation, (state, { locationId }) => {
    return {
      ...state,
      locationCameraSelectionMode: locationId,
    };
  }),
  on(HomeActions.clearCameraSelectionMode, (state, {}) => {
    return {
      ...state,
      locationCameraSelectionMode: null,
      selectedCameras: [],
    };
  }),
  on(HomeActions.setGotoCameraId, (state, { cameraId }) => {
    return {
      ...state,
      gotoCameraId: cameraId,
    };
  }),
  on(HomeActions.clearGotoCameraId, (state, {}) => {
    return {
      ...state,
      gotoCameraId: null,
    };
  }),
);

