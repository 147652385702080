import { environment } from '../../environments/environment';
import { EdgeDatabaseManagerMsgType, EdgeManagerMsgType } from '../locations/edge-management.service';
import { SelectedCamera } from '@models/alert-events.model';
import { LiveKitModels } from '@models/livekit.model';

const securityGroupURL = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3009' : environment.apiUrl;
const dataAnalyticsUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3015' : environment.apiUrl;
const unusualAlertsUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3018' : environment.apiUrl;
const accessDoorsUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3025' : environment.apiUrl;
const apiKeysUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3002' : environment.apiUrl;
const oktaApiUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3005' : environment.apiUrl;
const liveViewUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3023' : environment.apiUrl;
const supportApiUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3031' : environment.apiUrl;
const getUserDevicesUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3003' : environment.apiUrl;
const proxyApiUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3020' : environment.apiUrl;
const floorsApiUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3022' : environment.apiLumana;
const edgeManagement = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3007' : environment.apiLumana;
const locationsUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3024' : environment.apiLumana;
const favoritesUrl = environment.env === 'localNoKube' || environment.env === 'localNoKubeStaging' || environment.env === 'localNoKubeProd' ? 'http://127.0.0.1:3026' : environment.apiLumana;

export const organizationLogo = (organizationId: string) => `https://d1wltabqw740v1.cloudfront.net/${organizationId}/logo.jpeg`;

export const api = {
  general: {
    getConfig: `${environment.apiUrl}/general`,
  },
  catalog: {
    download: `${environment.apiUrl}/catalog/download`,
  },
  organization: {
    crud: `${environment.apiUrl}/organizations`,
    /**
     * @deprecated
     *
     */
    getOrganizationUsers: `${environment.apiUrl}/organizations/usersById`,
    getActiveOrganizationUsers: `${environment.apiUrl}/organizations/users`,
    invite: `${environment.apiUrl}/organizations/invite`,
    getUserOrganizations: (userId: string) => {
      return `${environment.apiUrl}/organizations/users/${userId}`;
    },
    blockUser: (userId: string) => {
      return `${environment.apiUrl}/organizations/users/block/${userId}`;
    },
    removeUser: (userId: string) => {
      return `${environment.apiUrl}/organizations/users/remove/${userId}`;
    },
    updateUserRole: (userId: string) => {
      return `${environment.apiUrl}/organizations/users/updateRole/${userId}`;
    },
    updateLogo: `${environment.apiUrl}/organizations/updateLogo`,
    checkIfOrgNameExist: `${environment.apiUrl}/organizations/checkIfOrgNameExist`,
    checkIfOrgEmailExist: `${environment.apiUrl}/organizations/checkIfOrgEmailExist`,
    leave: (userId: string) => {
      return `${environment.apiUrl}/organizations/users/leave/${userId}`;
    },
    all: `${environment.apiUrl}/organizations/all`,
    orgUsers: `${environment.apiUrl}/organizations/users/byOrgIds`,
    apiKeys: `${environment.apiUrl}/organizations/api-key-list/byFilter`,
    getMaxRetentionDays: `${environment.apiUrl}/organizations/get-max-retentions-days`,
    generateApi: `${apiKeysUrl}/security/api-keys`,
    revokeApiKey: `${apiKeysUrl}/security/api-keys/revoke`,
    extendApiKey: `${environment.apiUrl}/organizations/api-key/extend`,
    checkIfUserExists: (email: string) => `${environment.apiUrl}/organizations/check-if-user-exists?email=${encodeURIComponent(email)}`,
  },
  invites: {
    get: `${environment.apiUrl}/invites`,
    one: (id: string) => `${environment.apiUrl}/invites/${id}`,
    resend: `${environment.apiUrl}/invites/resend`,
    cancel: `${environment.apiUrl}/invites/cancel`,
    getActive: `${environment.apiUrl}/invites/activeInvites`,
    accept: `${environment.apiUrl}/invites/accept`,
    checkIfUserSigned: `${environment.apiUrl}/invites/checkUserExist`,
    revokeAll: `${environment.apiUrl}/invites/revokeAll`,
    resendAll: `${environment.apiUrl}/invites/resendAll`,
    acceptNoAuth: `${environment.apiUrl}/invites/public/accept`,
  },
  location: {
    updateEdgeConfig: `${environment.apiUrl}/locations/update-edge-config`,
    updateEdgeConfigJson: `${environment.apiUrl}/locations/update-edge-config-json`,
    uploadCameraAsset: `${environment.apiUrl}/locations/upload-camera-asset`,
    getCameraAssetPreSignedUrl: `${environment.apiUrl}/locations/upload-camera-asset-presigned-url`,
    notifyCameraAssetUploaded: `${environment.apiUrl}/locations/notify-camera-asset-uploaded`,
    getEdgeConfig: `${environment.apiUrl}/locations/get-edge-config`,
    probFile: `${environment.apiUrl}/locations/prob-file`,
    approveEdgeConfig: `${environment.apiUrl}/locations/approve-edge-config`,
    getEdgeDocument: `${environment.apiUrl}/locations/get-edge-document`,
    getEdgeInfo: `${environment.apiUrl}/locations/get-edge-info`,
    getEdgeLogs: `${environment.apiUrl}/locations/get-edge-logs`,
    getAwsEdgeLogs: `${environment.apiUrl}/locations/get-aws-edge-logs`,
    uploadVideoStorage: `${environment.apiUrl}/locations/upload-video-storage`,
    rmLog: `${environment.apiUrl}/locations/remove-aws-edge-log`,
    rmCamera: (locationId: string, edgeId: string, cameraId: string) => `${environment.apiUrl}/locations/camera/${locationId}/${edgeId}/${cameraId}`,
    updateAnalyticConfigJson: `${environment.apiUrl}/locations/update-analytic-config-json`,
    getAnalyticConfigJson: `${environment.apiUrl}/locations/get-analytic-config`,
    getByEdge: (id: string) => `${environment.apiUrl}/locations/getByEdge/${id}`,
    deviceConnectivity: `${environment.apiUrl}/locations/device-connectivity`,
    device: `${environment.apiUrl}/locations/device`,
    ntp: `${environment.apiUrl}/locations/ntp`,
    getEdgeTimezone: (edgeId: string) => `${environment.apiUrl}/locations/get-edge-timezone/${edgeId}`,
    getCameraAspectRatio: (camera: SelectedCamera) => `${environment.apiUrl}/locations/get-camera-aspect-ratio/${camera.locationId}/${camera.edgeId}/${camera.cameraId}`,
    getCameraRetentionDays: (camera: SelectedCamera) => `${environment.apiUrl}/locations/get-camera-retention-days/${camera.edgeId}/${camera.cameraId}`,
    getEdgeLookup: (locationId: string, edgeId: string) => `${environment.apiUrl}/locations/get-edge-lookup/${locationId}/${edgeId}`,
    updateEdgeLookup: (locationId: string, edgeId: string) => `${environment.apiUrl}/locations/update-edge-lookup/${locationId}/${edgeId}`,
    getCameraById: (locationId: string, edgeId: string, cameraId: string) => `${environment.apiUrl}/locations/get-camera-by-id/${locationId}/${edgeId}/${cameraId}`,
    updateCameraCoords: (locationId: string, edgeId: string, cameraId: string) => `${environment.apiUrl}/locations/update-camera/coords/${locationId}/${edgeId}/${cameraId}`,
    updateCameraShortcuts: (locationId: string, edgeId: string, cameraId: string) => `${environment.apiUrl}/locations/update-camera/shortcuts/${locationId}/${edgeId}/${cameraId}`,
    getCameraSettings: `${environment.apiUrl}/locations/cameras/get-cameras-settings`,
  },
  edge: {
    uploadVideoStorage: `${environment.apiUrl}/edgesF/upload-video-storage`,
    listVideoStorage: `${environment.apiUrl}/edges/list-video-storage`,
    sendHeartbeat: `${environment.apiUrl}/edges/send-heartbeat`,
    action: (msgType: number) => `${environment.apiUrl}/edges/action/${msgType}`,
    restore: (locationId: string, edgeId: string) => `${environment.apiUrl}/edges/restore-edge?locationId=${locationId}&edgeId=${edgeId}`,
    certifications: (edgeId: string) => `${environment.apiUrl}/edge-config/edge-certs-manage/${edgeId}`,
    updateEdgeWithExistsCert: `${environment.apiUrl}/edge-config/edge-certs-manage/update-edge-with-exist-certificate`,
    validateExistsCert: `${environment.apiUrl}/edge-config/edge-certs-manage/validate-exist-certificate-at-edge`,
    getEdgeLocalAddresses: `${environment.apiUrl}/edges/local-network-configuration`,
  },
  edgeManagement: {
    manageEdge: (action: EdgeManagerMsgType) => `${environment.apiUrl}/edge-management/${EdgeManagerMsgType[action]}`,
    manageEdgeDatabase: (action: EdgeDatabaseManagerMsgType) => `${environment.apiUrl}/edge-management/database/${EdgeDatabaseManagerMsgType[action]}`,
    manageEdgeByActionId: (msgType: number) => `${environment.apiUrl}/edge-management/action/${msgType}`,
    getEdgeSwUpdate: `${environment.apiUrl}/edge-management/trace/get-edge-sw-update`,
    recovery: (id: string) => `${environment.apiUrl}/edge-management/recovery/${id}`,
    toggleDhcp: (edgeId: string) => `${edgeManagement}/edge-manager/locations-management/dhcp/${edgeId}`,
    dhcpGetConfig: (edgeId: string) => `${edgeManagement}/edge-manager/locations-management/dhcp/config/${edgeId}`,
    addNewReservedAddress: (edgeId: string) => `${edgeManagement}/edge-manager/locations-management/dhcp/add-address-reservations/${edgeId}`,
  },
  alertEvents: {
    crud: `${environment.apiUrl}/events`,
    list: `${environment.apiUrl}/events/list`,
    v2: `${environment.apiUrl}/events/v2`,
    promptConfig: `${environment.apiUrl}/events/v2/prompt-config`,
    getSqs: (id: string) => {
      return `${environment.apiUrl}/events/get-sqs/${id}`;
    },
    one: (id: string) => {
      return `${environment.apiUrl}/events/${id}`;
    },
    enable: (id: string) => {
      return `${environment.apiUrl}/events/enable/${id}`;
    },
    disable: (id: string) => {
      return `${environment.apiUrl}/events/disable/${id}`;
    },
    delete: (id: string) => {
      return `${environment.apiUrl}/events/delete/${id}`;
    },
    sync: (id: string) => `${environment.apiUrl}/events/sync/${id}`,
    migrate: (id: string) => `${environment.apiUrl}/events/migrate/${id}`,
    allNoPaging: `${environment.apiUrl}/events/get-all/no-pagination`,
    testHttp: `${environment.apiUrl}/events/test-http`,
    missingObject: `${environment.apiUrl}/events/missing-object`,
  },
  variables: {
    crud: `${environment.apiUrl}/variables-config`,
    autocomplete: `${environment.apiUrl}/variables-config/get-autocomplete`,
    one: (id: string) => {
      return `${environment.apiUrl}/variables-config/${id}`;
    },
    enable: (id: string) => {
      return `${environment.apiUrl}/variables-config/enable/${id}`;
    },
    delete: (id: string) => {
      return `${environment.apiUrl}/variables-config/delete/${id}`;
    },
    sync: (id: string) => `${environment.apiUrl}/variables-config/sync/${id}`,
  },
  users: {
    onSwUpdate: `${environment.apiUrl}/users/sw-update`,
    all: `${environment.apiUrl}/users/all`,
    mfaStatus: `${environment.apiUrl}/users/mfa/status`,
    passwordChange: `${oktaApiUrl}/okta/api/reset-user-password/:token`,
    passwordReset: `${environment.apiUrl}/users/password/reset`,
    passwordTokenCheck: `${environment.apiUrl}/users/password/token/check`,
    passwordResetSecured: `${environment.apiUrl}/users/password/reset/secured`,
    signUp: `${oktaApiUrl}/okta/api/sign-up`,
    mfaSubmitAndLogin: `${oktaApiUrl}/okta/api/mfa/verify-otp-login`,
    login: `${oktaApiUrl}/okta/api/login`,
    mfaList: `${oktaApiUrl}/okta/api/mfa/list`,
    mfaDelete: (mfaId: string) => `${oktaApiUrl}/okta/api/mfa/remove/${encodeURIComponent(mfaId)}`,
    mfaCreate: `${oktaApiUrl}/okta/api/mfa/create`,
    mfaVerifyOtp: `${oktaApiUrl}/okta/api/mfa/verify-otp`,
  },
  searchConfiguration: {
    crud: `${environment.apiUrl}/search-configuration`,
    hasProtectiveGear: `${environment.apiUrl}/search-configuration/has-protective-gear`,
    hasForklift: `${environment.apiUrl}/search-configuration/has-forklift`,
    hasContainer: `${environment.apiUrl}/search-configuration/has-container`,
    hasShoppingCart: `${environment.apiUrl}/search-configuration/has-shopping-cart`,
    one: (locationId: string, edgeId: string, cameraId: string) => {
      return `${environment.apiUrl}/search-configuration/${locationId}/${edgeId}/${cameraId}`;
    },
    vpn: (locationId: string, edgeId: string, cameraId: string) => {
      return `${environment.apiUrl}/search-configuration/vpn/${locationId}/${edgeId}/${cameraId}`;
    },
    vpnReboot: (locationId: string, edgeId: string, cameraId: string) => {
      return `${environment.apiUrl}/search-configuration/vpn/${locationId}/${edgeId}/${cameraId}/reboot`;
    },
    vpnTest: (locationId: string, edgeId: string, cameraId: string, width: number, height: number) => {
      return `${environment.apiUrl}/search-configuration/vpn/${locationId}/${edgeId}/${cameraId}/test/${width}/${height}`;
    },
    cameraTest: (locationId: string, edgeId: string, width: number, height: number) => {
      return `${environment.apiUrl}/search-configuration/camera-test/${locationId}/${edgeId}/${width}/${height}`;
    },
    delete: (id: string) => {
      return `${environment.apiUrl}/search-configuration/delete/${id}`;
    },
  },
  savedSearch: {
    crud: `${environment.apiUrl}/saved-search`,
    one: (id: string) => {
      return `${environment.apiUrl}/saved-search/${id}`;
    },
    delete: (id: string) => {
      return `${environment.apiUrl}/saved-search/delete/${id}`;
    },
  },
  alertMonitoring: {
    crud: `${environment.apiUrl}/alert-monitoring-view`,
    getViews: `${environment.apiUrl}/alert-monitoring-view/get-views`,
    getView: (id: string) => `${environment.apiUrl}/alert-monitoring-view/${id}`,
    one: (id: string) => `${environment.apiUrl}/alert-monitoring-view/${id}`,
  },
  alerts: {
    getByFilter: `${environment.apiUrl}/alerts/getAlertsByFilter`,
    countByFilter: `${environment.apiUrl}/alerts/countAlertsByFilter`,
    autocomplete: `${environment.apiUrl}/alerts/getAlertsAutocomplete`,
    archive: (id: string) => `${environment.apiUrl}/alerts/ack/${id}`,
    one: (id: string) => `${environment.apiUrl}/alerts/${id}`,
    activity: (id: string) => `${environment.apiUrl}/alerts/activity/${id}`,
    getAlertsMonitoring: `${environment.apiUrl}/alerts/get-alerts-monitoring`,
    getAlertNotificationLogs: `${environment.apiUrl}/alerts/get-alert-notification-logs`,
  },
  editCamera: {
    one: (id: string) => `${environment.apiUrl}/camera-assets/${id}`,
    get: (edgeId: string, cameraId: string) => `${environment.apiUrl}/camera-assets/${edgeId}/${cameraId}`,
  },
  walls: {
    crud: `${environment.apiUrl}/walls`,
    getByFilters: `${environment.apiUrl}/walls/get-by-filters`,
    one: (id: string) => `${environment.apiUrl}/walls/${id}`,
  },
  cameraSearchSettings: {
    crud: `${environment.apiUrl}/camera-search-settings`,
    one: (id: string) => `${environment.apiUrl}/camera-search-settings/${id}`,
    byIds: `${environment.apiUrl}/camera-search-settings/byIds`,
  },
  grantedAccess: {
    crud: `${environment.apiUrl}/granted-access`,
    all: `${environment.apiUrl}/granted-access/all`,
    one: (id: string) => `${environment.apiUrl}/granted-access/${id}`,
    extend: (id: string) => `${environment.apiUrl}/granted-access/${id}/extend`,
    notify: (id: string) => `${environment.apiUrl}/granted-access/${id}/notify`,
    shareWith: (id: string) => `${environment.apiUrl}/granted-access/${id}/share-with`,
    stats: (id: string) => `${environment.apiUrl}/granted-access/${id}/stats`,
    viewAuth: (id: string) => `${environment.apiUrl}/granted-access/${id}/view`,
    viewNoAuth: (id: string) => `${environment.apiUrl}/granted-access/${id}/view-no-auth`,
    getByEntityId: (entityId: string) => `${environment.apiUrl}/granted-access/getEntity/${entityId}`,
    getPreviewUrl: (entityId: string) => `${environment.apiUrl}/granted-access/${entityId}/preview`,
    getSharedArchive: (id: string) => `${environment.apiUrl}/granted-access/get-shared-archive/${id}`,
    getByFilter: `${environment.apiUrl}/granted-access/byFilter/all`,
    revoke: (id: string) => `${environment.apiUrl}/granted-access/revoke/${id}`,
    revokeAll: `${environment.apiUrl}/granted-access/revoke/All`,
  },
  stats: {
    camera: `${environment.apiUrl}/stats/camera`,
    edge: `${environment.apiUrl}/stats/edge`,
    edgeAll: `${environment.apiUrl}/stats/edgeAll`,
    analytics: `${environment.apiUrl}/stats/analytics`,
    storage: `${environment.apiUrl}/stats/storage`,
    properFitting: `${environment.apiUrl}/stats/proper-fitting`,
    grafana: `${environment.apiUrl}/stats/grafana`,
    edgesHealth: `${environment.apiUrl}/stats/grafana/edges/health`,
    edgesSwVersion: `${environment.apiUrl}/stats/grafana/edges/sw-version`,
  },
  tags: {
    crud: `${environment.apiUrl}/tags`,
  },
  userSettings: {
    crud: `${environment.apiUrl}/user-settings`,
    me: `${environment.apiUrl}/user-settings/me`,
  },
  activityLog: {
    findByFilter: `${environment.apiUrl}/activity-log/findByFilter`,
    exportCsv: `${environment.apiUrl}/activity-log/export-csv`,
    findAndGroupByDailyEvents: `${environment.apiUrl}/activity-log/findAndGroupByDailyEvents`,
    crud: `${environment.apiUrl}/activity-log`,
    auditReportPdf: `${environment.apiUrl}/activity-log/pdf/audit-report`,
    auditLogPdf: `${environment.apiUrl}/activity-log/pdf/audit-log`,
  },
  workspace: {
    one: (id: string) => `${environment.apiUrl}/workspace/${id}`,
    addFavorite: (id: string) => `${environment.apiUrl}/workspace/add-favorite/${id}`,
    removeFavorite: (id: string) => `${environment.apiUrl}/workspace/remove-favorite/${id}`,
    crud: `${environment.apiUrl}/workspace`,
    getWidgetData: `${environment.apiUrl}/workspace/get-widget-data`,
    favorites: `${environment.apiUrl}/workspace/favorites`,
  },
  dashboard: {
    one: (id: number) => `${dataAnalyticsUrl}/data-analytics/dashboard/${id}`,
    crud: `${dataAnalyticsUrl}/data-analytics/dashboard`,
    updateFilters: (id: number) => `${dataAnalyticsUrl}/data-analytics/dashboard/filters/${id}`,
  },
  widget: {
    one: (id: number) => `${dataAnalyticsUrl}/data-analytics/widget/?id=${id}`,
    crud: `${dataAnalyticsUrl}/data-analytics/widget`,
    visualization: (id: number) => `${dataAnalyticsUrl}/data-analytics/widget/visualization?id=${id}`,
    upload: `${dataAnalyticsUrl}/data-analytics/widget/image/upload`,
    preview: `${dataAnalyticsUrl}/data-analytics/widget/preview`,
    redirectRouter: `${dataAnalyticsUrl}/data-analytics/redirect-router`,
  },
  verification: {
    crud: `${environment.apiUrl}/verification`,
    one: (id: string) => `${environment.apiUrl}/verification/${id}`,
    email: `${environment.apiUrl}/verification/email`,
  },
  webRtc: {
    peer: `${environment.apiUrl}/cameras/camera/live-view/peer-webrtc`,
    credentials: `${environment.apiUrl}/cameras/camera/live-view/webrtc-credentials`,
    delete: (id: string) => {
      return `${environment.apiUrl}/variables-config/delete/${id}`;
    },
  },
  notification: {
    seen: (id: string) => `${environment.apiUrl}/notifications/seen/${id}`,
    delete: (id: string) => `${environment.apiUrl}/notifications/${id}`,
    deleteAll: `${environment.apiUrl}/notifications`,
    schedule: `${environment.apiUrl}/notifications/schedule`,
  },
  comment: {
    byEntity: (id: string) => `${environment.apiUrl}/comments/byEntity/${id}`,
    crud: `${environment.apiUrl}/comments`,
  },
  playback: {
    codec: () => `${environment.apiUrl}/cameras/camera/playback/codec`,
    storage: `${environment.apiUrl}/cameras/camera/playback/storage`,
  },
  people: {
    crud: `${environment.groupsUrl}/groups`,
    count: `${environment.groupsUrl}/groups/count`,
    add: (id: string) => `${environment.groupsUrl}/groups/${id}`,
    remove: (id: string) => `${environment.groupsUrl}/groups/${id}`,
    edit: (id: number) => `${environment.groupsUrl}/groups/${id}`,
    list: (ids: string[]) => `${environment.groupsUrl}/groups?ids=${ids.join(',')}`,
    deleteFromTracker: (id: string, idBase: number, idIndex: number) => `${environment.groupsUrl}/groups/${id}?idBase=${idBase}&idIndex=${idIndex}`,
    merge: `${environment.groupsUrl}/groups/merge`,
    learn: `${environment.groupsUrl}/groups/image/evaluate`,
    search: `${environment.groupsUrl}/groups/image/search`,
    attach: `${environment.groupsUrl}/groups/image/attach`,
    create: `${environment.groupsUrl}/groups/image/create`,
    getFacePresignedUrl: `${environment.groupsUrl}/groups/image/upload`,
  },
  persons: {
    getUnsaved: `${environment.groupsUrl}/persons/buckets`,
    crud: `${environment.groupsUrl}/persons`,
    count: `${environment.groupsUrl}/persons/count`,
    delete: (id: number) => `${environment.groupsUrl}/persons/${id}`,
    deleteTrackerFromPerson: (trackerId: number) => `${environment.groupsUrl}/persons/delete-tracker-from-person/${trackerId}`,
    getFacePresignedUrl: `${environment.groupsUrl}/persons/image/upload`,
    learn: `${environment.groupsUrl}/persons/image/evaluate`,
    attach: `${environment.groupsUrl}/persons/image/manual-update`,
    create: `${environment.groupsUrl}/persons/image/manual-create`,
    search: `${environment.groupsUrl}/persons/image/search`,
    addToExist: (token: string) => `${environment.groupsUrl}/persons/add-to-exist/${token}`,
    bucketsForTracker: (trackerId: number) => `${environment.groupsUrl}/persons/buckets-for-tracker/?trackerId=${trackerId}`,
    createNew: `${environment.groupsUrl}/persons/manual-update/exist-tracker`,
  },
  doors: {
    crud: `${environment.doorsUrl}/doors`,
    count: `${environment.doorsUrl}/doors/count`,
    add: (id: string) => `${environment.doorsUrl}/doors/${id}`,
    remove: (id: string) => `${environment.doorsUrl}/doors/${id}`,
    edit: (id: string) => `${environment.doorsUrl}/doors/${id}`,
    list: (ids: string[]) => `${environment.doorsUrl}/doors?ids=${ids.join(',')}`,
  },
  vehicles: {
    crud: `${environment.vehiclesUrl}/vehicle-manager/vehicles`,
    unsaved: `${environment.vehiclesUrl}/vehicle-manager/vehicles/unsaved`,
    saved: `${environment.vehiclesUrl}/vehicle-manager/vehicles/saved`,
    count: `${environment.vehiclesUrl}/vehicle-manager/count`,
    add: (id: string) => `${environment.vehiclesUrl}/vehicle-manager/vehicles/${id}`,
    remove: (id: string) => `${environment.vehiclesUrl}/vehicle-manager/vehicles/${id}`,
    edit: (id: string) => `${environment.vehiclesUrl}/vehicle-manager/${id}`,
    list: (ids: string[]) => `${environment.vehiclesUrl}/vehicle-manager?ids=${ids.join(',')}`,
  },
  customEvents: {
    crud: `${environment.customEventsUrl}/custom-events`,
    count: `${environment.customEventsUrl}/custom-events/count`,
    add: `${environment.customEventsUrl}/custom-events`,
    remove: (id: string) => `${environment.customEventsUrl}/custom-events/${id}`,
    edit: `${environment.customEventsUrl}/custom-events`,
    list: (ids: string[]) => `${environment.customEventsUrl}/custom-events?ids=${ids.join(',')}`,
  },
  device: {
    registerWeb: `${environment.apiUrl}/device/register-web-sub`,
  },
  mediasoup: {
    start: `${environment.mediasoupApiUrl}/mediasoup/start`,
  },
  operator: {
    whitelist: `${environment.apiUrl}/usersWhitelist/generate`,
  },
  cameras: {
    getErrorReport: (id: string) => `${environment.apiUrl}/cameras/error-report?cameraId=${id}`,
    liveStream: `${environment.apiUrl}/cameras/camera/live-stream`,
    localHls: `${environment.apiUrl}/cameras/camera/live-stream/local-hls/start`,
  },
  orgDevices: {
    monitor: `${environment.apiUrl}/org-devices/monitor`,
  },
  userGroups: {
    crud: `${environment.apiUrl}/user-groups`,
  },
  permissionGroups: {
    crud: `${securityGroupURL}/permissions/security-groups`,
    getOrganizationPermissionGroups: `${securityGroupURL}/permissions/security-groups/groups/no-pagination`,
    getSystemPermissionGroups: `${securityGroupURL}/permissions/security-groups/system-groups/no-pagination`,
    getByFilters: `${securityGroupURL}/permissions/security-groups/groups/by-filters`,
    updateUsers: (id: string) => `${securityGroupURL}/permissions/security-groups/${id}/updateUsers`,
    getUserPermissions: `${securityGroupURL}/permissions/security-groups/user/permissions-per-entities`,
    updateUserGroups: (id: string) => `${securityGroupURL}/permissions/security-groups/user/${id}/update-groups`,
    getPermissionByUserId: (userId: string) => `${securityGroupURL}/permissions/security-groups/user/permissions/${userId}`,
  },
  orgGroups: {
    crud: `${environment.orgGroupsUrl}/org-groups`,
    byType: (type: string) => `${environment.orgGroupsUrl}/org-groups/byType?type=${type}`,
    add: `${environment.orgGroupsUrl}/org-groups`,
    edit: `${environment.orgGroupsUrl}/org-groups`,
    remove: (id: string) => `${environment.orgGroupsUrl}/org-groups/${id}`,
  },
  pulsation: {
    heartbeatStatus: (edgeId: string) => `${environment.apiUrl}/pulsation-api/heartbeat-status/?edgeId=${edgeId}`,
    heartbeatStatusBatch: (edgeIds: string[]) => `${environment.apiUrl}/pulsation-api/heartbeat-status-batch/?ids=${edgeIds}`,
  },
  videoStorage: {
    crud: `${environment.thumbnailsV2}/thumbnails/video-storage`,
    last: (edgeId: string) => `${environment.thumbnailsV2}/thumbnails/video-storage/last/${edgeId}`,
  },
  unusualAlerts: {
    search: `${unusualAlertsUrl}/unusual-alerts/search`,
  },
  edgePlayback: {
    test: `${environment.apiUrl}/edge-playback`,
  },
  identity: {
    identityByEmail: (email: string) => `${oktaApiUrl}/okta/api/users-by-email/${email}`,
    changePassword: `${oktaApiUrl}/okta/api/change-password`,
  },
  help: {
    crud: `${environment.apiUrl}/v1/articles`,
    article: `${environment.apiUrl}/v1/articles/article`,
    section: `${environment.apiUrl}/v1/articles/sections`,
    sectionsWithArticles: `${environment.apiUrl}/v1/articles/sections/sections-with-articles`,
    publish: (id: number) => `${environment.apiUrl}/v1/articles/article/${id}/published`,
    draft: (id: number) => `${environment.apiUrl}/v1/articles/article/${id}/draft`,
  },
  appleTv: {
    crud: `${environment.apiUrl}/apple-tvs`,
    create: `${environment.apiUrl}/apple-tvs/create-apple-tv`,
    delete: (locationId: string, edgeId: string) => `${environment.apiUrl}/apple-tvs/${locationId}/${edgeId}`,
    one: (edgeId: string) => `${environment.apiUrl}/apple-tvs/${edgeId}`,
    configure: `${environment.apiUrl}/apple-tvs/configure-apple-tv-wall`,
    getWall: (edgeId: string) => `${environment.apiUrl}/apple-tvs/get-apple-tv-wall/${edgeId}`,
    getWallDropdown: `${environment.apiUrl}/walls-v2/apple-tv/all`,
  },
  externalStorage: {
    crud: `${environment.apiUrl}/external-storage`,
    create: `${environment.apiUrl}/external-storage`,
    test: `${environment.apiUrl}/external-storage/check-connection`,
    delete: (locationId: string, edgeId: string) => `${environment.apiUrl}/external-storage/${locationId}/${edgeId}`,
    one: (storageId: string) => `${environment.apiUrl}/external-storage/${storageId}`,
    configure: `${environment.apiUrl}/apple-tvs/configure-apple-tv-wall`,
  },
  livekit: {
    url: `https://stream.lumana.ai`,
    urlIsrael: `https://stream-il.lumana.ai`,
    getToken: `${liveViewUrl}/v1/live-view/lwebrtc/create-token`,
    getParticipants: (req: LiveKitModels.GetLiveKitParticipantsRequest) => `${liveViewUrl}/v1/live-view/lwebrtc/get-participants/${req.edgeId}/${req.cameraId}/${req.resolution}`,
    stop: `${liveViewUrl}/v1/live-view/lwebrtc/stop-livekit`,
  },
  liveView: {
    localStart: `${liveViewUrl}/v1/live-view/local/start-hls`,
    playbackStart: `${liveViewUrl}/v1/live-view/playback/start`,
    playbackStop: `${liveViewUrl}/v1/live-view/playback/stop`,
    playbackVideoTimeline: `${liveViewUrl}/v1/live-view/playback/video-timeline`,
  },
  support: {
    login: `${supportApiUrl}/support/zendesk/login`,
  },
  integrations: {
    get: (orgId: string) => `${environment.apiUrl}/integrations/${orgId}`,
    getDetails: (orgId: string) => `${environment.apiUrl}/integrations/details/${orgId}`,
    slack: `${environment.apiUrl}/integrations/slack/callback`,
    listSlackChannels: (orgId: string) => `${environment.apiUrl}/integrations/slack/listChannels/${orgId}`,
    deactivateSlack: (orgId: string) => `${environment.apiUrl}/integrations/slack/deactivate/${orgId}`,
    installTeam3: `${environment.apiUrl}/integrations/install/provider/team3`,
    uninstallTeam3: `${environment.apiUrl}/integrations/uninstall/provider/team3`,
    installNoonLight: `${environment.apiUrl}/integrations/install/provider/noon-light`,
    uninstallNoonLight: `${environment.apiUrl}/integrations/uninstall/provider/noon-light`,
    installLumana: `${environment.apiUrl}/integrations/install/provider/lumana`,
    uninstallLumana: `${environment.apiUrl}/integrations/uninstall/provider/lumana`,
    updateQuotas: `${environment.apiUrl}/integrations/update-quotas`,
    installGenea: `${environment.apiUrl}/integrations/install/provider/genea`,
    uninstallGenea: `${environment.apiUrl}/integrations/uninstall/provider/genea`,
    installKisi: `${environment.apiUrl}/integrations/install/provider/kisi`,
    uninstallKisi: `${environment.apiUrl}/integrations/uninstall/provider/kisi`,
    slackRedirectUrl: () => {
      switch (environment.env) {
        case 'production':
          return 'https://app.lumana.ai/settings/integrations';
        case 'staging-hosted':
          return 'https://staging.lumana.ai/settings/integrations';
        default:
          return 'https://dev.lumana.ai/settings/integrations';
      }
    },
    installWebhook: `${environment.apiUrl}/integrations/install/provider/webhook`,
    getWebhooks: `${environment.apiUrl}/integrations/provider/webhook`,
    getOneWebhook: (webhookId: string) => `${environment.apiUrl}/integrations/provider/webhook/${webhookId}`,
  },
  accessDoors: {
    crud: `${environment.apiUrl}/v1/access-doors`,
    geOne: (id: string) => `${environment.apiUrl}/v1/access-doors/${id}`,
    delete: (id: string) => `${environment.apiUrl}/v1/access-doors/${id}`,
    locations: `${environment.apiUrl}/v1/access-doors/locations`,
    doors: (locationUUID: string) => `${environment.apiUrl}/v1/access-doors/doors/${locationUUID}`,
    openDoor: (doorId: string) => `${environment.apiUrl}/v1/access-doors/door/open/${doorId}`,
    closeDoor: (doorId: string) => `${environment.apiUrl}/v1/access-doors/door/close/${doorId}`,
    getDoorState: (doorId: string) => `${environment.apiUrl}/v1/access-doors/door/state/${doorId}`,
    doorAlerts: (id: string) => !!id ? `${environment.apiUrl}/v1/access-doors/alerts/${id}` : `${environment.apiUrl}/v1/access-doors/alerts`,
    search: `${accessDoorsUrl}/v2/access-doors/search`,
  },

  proxy: {
    vpn: (locationId: string, edgeId: string, cameraId: string, force?: boolean) => `${proxyApiUrl}/edge-proxy/vpn/location/${locationId}/edge/${edgeId}/camera/${cameraId}${force ? '?force=true' : ''}`,
  },
  userDevices: {
    one: `${getUserDevicesUrl}/user-devices/device/get-user-devices`,
    update: (deviceId: string) => `${getUserDevicesUrl}/user-devices/device/update-device/${deviceId}`,
  },
  floors: {
    crud: `${floorsApiUrl}/v1/floors/`,
    deleteBuilding: (buildingId: string) => `${floorsApiUrl}/v1/floors/${buildingId}`,
    deleteFloor: (buildingId: string, floorId: string) => `${floorsApiUrl}/v1/floors/${buildingId}/${floorId}`,
    editFloor: (buildingId: string, floorId: string) => `${floorsApiUrl}/v1/floors/${buildingId}/${floorId}`,
    createFloor: (buildingId: string) => `${floorsApiUrl}/v1/floors/${buildingId}`,
    upload: `${floorsApiUrl}/v1/floors/image/upload`,
  },
  archive: {
    download: (sessionId: string) => `${edgeManagement}/edge-manager/archive/download/${sessionId}`,
  },
  wallV2: {
    crud: `${environment.apiUrl}/walls-v2`,
  },
  youtube: {
    crud: `${environment.apiUrl}/youtube`,
  },
  ptt: {
    crud: `${environment.apiUrl}/ptt`,
    authToken: `${environment.apiUrl}/ptt/auth-token`,
    listCredentialList: `${environment.apiUrl}/ptt/list-credential-lists`,
    register: `${environment.apiUrl}/ptt/register`,
  },
  locations: {
    home: `${locationsUrl}/v2/locations/home`,
    sublocation: `${locationsUrl}/v2/locations/sublocation`,
    sublocationCamera: `${locationsUrl}/v2/locations/sublocation/camera`,
    sublocationCameras: `${locationsUrl}/v2/locations/sublocation/cameras`,
  },
  favorites: {
    crud: `${favoritesUrl}/v1/favorites`,
    location: `${favoritesUrl}/v1/favorites/location`,
    wall: `${favoritesUrl}/v1/favorites/wall`,
  },
};
