import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PermissionsState } from '@states/permissions/permissions.reducer';
import { PermissionModel } from '@models/permission.model';
import { checkPermission } from '../../../helpers/common.helpers';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';

export const selectPermissionsState = createFeatureSelector<PermissionsState>('permissionsState');
export const selectPermissions = createSelector(selectPermissionsState, ({
                                                                           permissions, entityIds,
                                                                         }: PermissionsState) => {
  return {
    permissions,
    entityIds,
  };
});

export const checkAccessPermissions = (
  selectedPermissions: PermissionModel.Permissions[], ids: string[],
) =>
  createSelector(selectPermissions, OrganizationSelectors.isDeveloper, (permissions, isDeveloper) => {
    if (isDeveloper) {
      return true;
    }
    return selectedPermissions.some(perm => {
      return checkPermission(perm, permissions, ids);
    });
  });

