<ng-container *ngIf="{
loading:loader$|async,
error:isError$|async} as conditions"
>

  <div class="h-100">
    <ng-container *ngTemplateOutlet="uiCameraSnapshot"></ng-container>
  </div>

  <ng-template #uiCameraSnapshot>
    <div class="snapshot-wrapper h-100">
      <ui-preloader style="position: absolute"
                    [diameter]="20"
                    *ngIf="conditions.loading"
                    [color]="preloaderColor.blue"
                    matTooltip="Loading"
      ></ui-preloader>

      <ng-container *ngTemplateOutlet="img">

      </ng-container>
      @if (conditions.error && !conditions.loading) {
        <div class="flex-col center-center h-100 w-100"
        >
        </div>
      }
    </div>

  </ng-template>
  <ng-template #img>
    <img
      matTooltipClass="sidebar-tooltip oldest-video"
      class="snapshot"
      [class.hidden]="conditions.loading || conditions.error"
      [lazyLoad]="{edgeId:edgeId, cameraId: cameraId } | cameraBestImagePipe | async"
      [defaultImage]="cameraSnapshot()|async"
      (load)="onImageLoad($event)"
      (error)="onError()"
    />
  </ng-template>

</ng-container>

