import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { catchError, defaultIfEmpty, EmptyError, filter, lastValueFrom, Observable, of, take, timeout } from 'rxjs';
import * as _ from 'lodash';
import { ThumbnailsSelectors } from '@states/thumbnails/thumbnails.selector-types';
import { ThumbnailModel } from '@models/thumbnail.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MultiPlaybackMove } from '@models/multi-playback.model';
import { MultiPlaybackSelectors } from '@states/multi-playback/multi-playback.selector-types';

const ALERT_WINDOW = 3000;
const FIVE_MINUTES = 5 * 60 * 1000;
const ONE_HOUR = 60 * 60 * 1000;

@UntilDestroy()
@Component({
  selector: 'app-thumbnail-histogram',
  templateUrl: './thumbnail-histogram.component.html',
  styleUrls: ['./thumbnail-histogram.component.scss'],
})
export class ThumbnailHistogramComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  ctx: CanvasRenderingContext2D;

  @Input() edgeId: string;
  @Input() cameraId: string;
  @Input() start: number;
  @Input() end: number;
  @Input() parent: HTMLDivElement;
  parentWidth: number;
  parentHeight: number;
  @Input() preview = false;

  @Input() isAlertNow = false;
  @Input() isCameraView = false;
  @Input() streamLiveView = false;

  public selectMove$: Observable<MultiPlaybackMove> = this.store$.select(MultiPlaybackSelectors.selectMove)
    .pipe(untilDestroyed(this));

  baseStart: number;
  baseEnd: number;

  delta: number;

  alertInit = false;

  public eventsCount = 0;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.render();
  }

  constructor(
    private store$: Store,
    private el: ElementRef,
    private cameraThumbnailsService: CamerasThumbnailsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['start'] || changes['end']) {
      this.render();
      this.delta = this.end - this.start;
    }

    if (changes['parent']) {
      this.canvas.nativeElement.width = this.parent.clientWidth;
      this.canvas.nativeElement.height = this.parent.clientHeight;
      this.parentWidth = this.parent.clientWidth;
      this.parentHeight = this.parent.clientHeight;
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');

    this.ctx.canvas.width = this.parent.clientWidth;
    this.ctx.canvas.height = this.parent.clientHeight;
    const baseStart = this.getStartOfDay(this.start);
    this.store$.select(
        ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({ edgeId: this.edgeId, cameraId: this.cameraId, base: baseStart }),
      )
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.render();
      });
    this.render();

    this.selectMove$.pipe(untilDestroyed(this))
      .pipe(filter(move => !!move.timestamp && !!move.percentage))
      .subscribe((move) => {
        if (this.isCameraView && this.streamLiveView) {
          this.delta = this.end - this.start;
          this.start = move.timestamp - this.delta / 2;
          this.end = move.timestamp + this.delta / 2;
          this.render();
        }
      });

  }

  render(resize = false) {
    if (resize) {
      this.canvas.nativeElement.width = this.parent.clientWidth;
      this.canvas.nativeElement.height = this.parent.clientHeight;
    }
    const baseStart = this.getStartOfDay(this.start);
    const baseEnd = this.getStartOfDay(this.end);
    this.baseStart = baseStart;
    this.baseEnd = baseEnd;

    if (this.isAlertNow) {
      const delta = (this.start + this.end) / 2 - baseStart;
      this.drawData([], [[delta - 2000, delta + 2000]]);
      this.alertInit = true;
      return;
    }

    if (baseStart === baseEnd) {
      this.handleSingleDay(this.ctx, baseStart);
    } else {
      this.handleSpannedDays(this.ctx, baseStart, baseEnd);
    }
  }

  getStartOfDay(timestamp: number): number {
    return timestamp - (timestamp % (24 * 60 * 60 * 1000));
  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date);
  }

  public normalizeTimestamp(timestamp: number, thumbnailsDuration = 2000): number {
    return Math.round(timestamp / thumbnailsDuration) * thumbnailsDuration;
  }

  async handleSingleDay(ctx: CanvasRenderingContext2D, base: number) {
    const edgeId = this.edgeId;
    const cameraId = this.cameraId;
    const data: ThumbnailModel.ThumbnailDocument = await lastValueFrom(this.getStorageStats(edgeId, cameraId, base)) as ThumbnailModel.ThumbnailDocument;
    this.eventsCount = data?.alerts?.filter((alert) => alert.timestamp + base > this.start && alert.timestamp + base < this.end).length ?? 0;
    if (data) {
      const alerts = this.convertAlerts(data.alerts);
      this.drawData(data.offlineThumbnails, alerts);
    }

  }

  public convertAlerts(alerts: ThumbnailModel.AlertsDataResponse[]): number[][] {
    return alerts?.map((alert) => {
      const ts = this.normalizeTimestamp(alert.timestamp);
      return [ts - ALERT_WINDOW, ts + ALERT_WINDOW];
    });
  }

  public getStorageStats(edgeId: string, cameraId: string, base: number) {
    return this.store$.select(
        ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({ edgeId, cameraId, base }),
      )
      .pipe(filter(data => !!data), take(1), timeout(2000)
        , catchError((err) => {
          if (err.message === 'Timeout has occurred') {
            // It's a timeout error. Handle it without logging.
            const thumbnailStats: ThumbnailModel.ThumbnailDocument = {
              edgeId,
              cameraId,
              base,
              cacheId: `${edgeId}:${cameraId}:${base}`,
              offlineThumbnails: [[0, 86400000]],
              alerts: [],
            };
            return of(thumbnailStats); // or however you want to handle it
          }
          if (err instanceof EmptyError) {
            console.warn('No elements in sequence');
            return of([]);
          }
          // Handle other errors
          console.error(err);
          return of([]);
        }),
        defaultIfEmpty([]),
      );
  }

  async handleSpannedDays(ctx: CanvasRenderingContext2D, startBase: number, endBase: number) {
    const edgeId = this.edgeId;
    const cameraId = this.cameraId;

    const startData = await lastValueFrom(this.getStorageStats(edgeId, cameraId, startBase)) as ThumbnailModel.ThumbnailDocument;
    const endData = await lastValueFrom(this.getStorageStats(edgeId, cameraId, endBase)) as ThumbnailModel.ThumbnailDocument;

    const endStats = _.cloneDeep(endData?.offlineThumbnails || []);
    const endAlerts = endData?.alerts ? _.cloneDeep(this.convertAlerts(endData.alerts)) : [];
    const startStats = _.cloneDeep(startData?.offlineThumbnails || []);
    const startAlerts = startData?.alerts ? _.cloneDeep(this.convertAlerts(startData.alerts)) : [];

    const dayInMs = 86400000;
    endStats.forEach(stat => {
      stat[0] += dayInMs;
      stat[1] += dayInMs;
    });

    endAlerts.forEach(alert => {
      alert[0] += dayInMs;
      alert[1] += dayInMs;
    });

    // Handle current day data specially
    const now = Date.now();
    const todayBase = this.getStartOfDay(now);

    if (endBase === todayBase) {
      endStats.forEach(stat => {
        if (stat[1] === dayInMs * 2) { // Check for end of day
          stat[1] = now - startBase; // Adjust to current time
        }
      });
    }

    const startEventsCount = startData?.alerts?.filter(
      alert => alert.timestamp + startBase > this.start &&
        alert.timestamp + startBase < this.end,
    ).length || 0;

    const endEventsCount = endData?.alerts?.filter(
      alert => (alert.timestamp + endBase) > this.start &&
        (alert.timestamp + endBase) < this.end,
    ).length || 0;

    this.eventsCount = startEventsCount + endEventsCount;

    this.drawData(startStats.concat(endStats), startAlerts.concat(endAlerts));
  }

  _draw(stats: number[][], color: string, duration: number, ratio: number, tall = false) {
    for(let i = 0; i < stats?.length; i++) {
      const [startOffset, endOffset] = stats[i];
      const relativeStart = this.baseStart + startOffset - this.start;
      const relativeEnd = this.baseStart + endOffset - this.start;

      // if (this.baseStart + endOffset >= Date.now()) {
      //   continue;
      // }


      let magnify = 0.002;
      if (this.delta > 60 * 60 * 1000) {
        magnify = 0.002;
      } else if (this.delta > 10 * 60 * 1000) {
        magnify = 0.001;
      } else {
        magnify = 0.0;
      }

      if (this.preview) {
        magnify = 0.004;
      }
      const half = this.parent.clientWidth * magnify;

      if (relativeEnd > 0 && relativeStart < duration) {
        this.ctx.fillStyle = color;
        this.ctx.fillRect(
          Math.max(relativeStart * ratio, 0) - half / 2,
          0,
          (Math.min(relativeEnd, duration) - Math.max(relativeStart, 0)) * ratio + half,
          this.preview ? 100 : tall ? 36 : 20,
        );
      }
    }
  }

  _drawFuture(startOffset: number) {
    const relativeStart = this.baseStart + startOffset - this.start;
    const width = this.parent.clientWidth;
    const duration = this.end - this.start;
    const ratio = width / duration;
    this.ctx.fillStyle = '#fafafb';
    // calculate the remaining width from the right and fill it
    this.ctx.fillRect(
      Math.max(relativeStart * ratio, 0),
      0,
      width - Math.max(relativeStart * ratio, 0),
      100,
    );
  }

  _drawLiveView() {
    const width = this.parent.clientWidth;
    const duration = this.end - this.start;
    this.ctx.fillStyle = 'rgba(16, 24, 40, 0.02)';
    // calculate the remaining width from the right and fill it
    this.ctx.fillRect(
      width / 2,
      0,
      width,
      100,
    );
  }

  drawData(offlineThumbnails: number[][], alerts: number[][]) {

    if (!this.ctx) {
      return;
    }
    const width = this.parent.clientWidth;
    const duration = this.end - this.start;
    const ratio = width / duration;

    this.ctx.setTransform(1, 0, 0, -1, 0, this.canvas.nativeElement.height);
    this.ctx.clearRect(0, 0, width, 100);

    const todayBase = this.getStartOfDay(Date.now());
    const now = Date.now();


    if (todayBase <= this.baseEnd && offlineThumbnails?.length) {
      offlineThumbnails = _.cloneDeep(offlineThumbnails);
      // if (offlineThumbnails[offlineThumbnails.length - 1][1] === 86400000) {
      //   const offlineLastStarTime = offlineThumbnails[offlineThumbnails.length - 1][0];
      //   const last10MinOffset = now - this.baseStart - 10 * 60 * 1000;
      //   if (offlineLastStarTime < last10MinOffset) {
      //     offlineThumbnails[offlineThumbnails.length - 1] = [offlineThumbnails[offlineThumbnails.length - 1][0], now - this.baseStart];
      //   } else {
      //     offlineThumbnails.splice(offlineThumbnails.length - 1, 1);
      //   }
      // }

      // offlineThumbnails = offlineThumbnails.filter(item => item[0] !== 86400000)
      //   .map(thumb => {
      //     if (thumb[1] !== 86400000) {
      //       return thumb;
      //     } else {
      //       return [thumb[0], now - this.baseStart];
      //     }
      //   });

      // When the last thumbnail entry for today is 1 hour long , we assume the camera is offline, otherwise - we assume it is online
      if ((offlineThumbnails.length > 0 && offlineThumbnails[offlineThumbnails.length - 1][1] - now - this.baseStart < FIVE_MINUTES &&
          (offlineThumbnails.length > 0 && offlineThumbnails[offlineThumbnails.length - 1][0] >= now - this.baseStart - ONE_HOUR))
        || (todayBase === this.baseStart && offlineThumbnails.length > 0 && offlineThumbnails[offlineThumbnails.length - 1][0] === 0 && offlineThumbnails[offlineThumbnails.length - 1][1] === 86400000)
      ) {
        offlineThumbnails.pop();
      } else {
        if (todayBase === this.baseStart) {
          offlineThumbnails[offlineThumbnails.length - 1][1] = now - this.baseStart;
        }
      }

      const ts = this.start + (this.end - this.start) / 2;
      if (now - ts < 2000) {
        this._drawLiveView();
      } else {
        this._drawFuture(now - this.baseStart);
      }
    } else {
      this._drawFuture(now - this.baseStart);
    }

    this._draw(offlineThumbnails, '#101828', duration, ratio);
    this._draw(alerts, '#9bacf2', duration, ratio, true);

    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
  }

  protected readonly event = event;
}
