import { EdgeCamera } from '../../../cameras/camera.model';
import { createReducer, on } from '@ngrx/store';
import { CameraSearchSettings } from '@models/camera-search.settings';
import { SearchSelection, SearchSelectionForm } from '@models/search.model';
import { SearchObjectTypes, SearchQueryOperator } from '@enums/search.enum';
import { Search, SearchType } from '../../../shared/search.model';
import { SortDirection } from '@angular/material/sort';
import { MultiSearchActions } from '@states/multi-search/multi-search.action-types';
import { OnRangeSelectedResult } from '../../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { UiCalendarPickerType } from '@enums/shared.enum';
import { UiDatetimeRangePickerModel } from '../../../shared/ui-kit/ui-datetime-range-picker/ui-datetime-range-picker.model';
import * as _ from 'lodash';
import { Period, PeriodUnit } from '../../../shared/ui-kit/ui-period/ui-period.component';
import { CustomEventModel } from '@models/custom-event.model';
import * as moment from 'moment-timezone';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;

const dateRangeInitial = {
  start: moment()
    .subtract(3, 'day')
    .toString(),
  end: moment()
    .toString(),
};

export declare interface MultiSearchState {
  selectedCameras: EdgeCamera.CameraItem[];
  searchSelections: SearchSelection[];
  highConfidence: boolean;
  precision: Search.Precision;
  searchV2: boolean;
  dwellRange: Search.SearchDwellRange,
  cameraSearchSettingsFilters: { [key: string]: boolean };
  objectSelections: SearchSelectionForm[];
  objectSelectionsFormatted: SearchSelectionForm[];
  outerOperator: SearchQueryOperator;
  dateRange?: Search.DateRange;
  dateTimeRange?: OnRangeSelectedResult;
  appearancePeriod?: Period;

  savedSearches: Search.SavedSearch[];
  totalItemsCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  orderDirection: SortDirection;
  query: string;

  selectedSavedSearch: Search.SavedSearch;
  useSingleStore: boolean;
  customEvent: CustomEventModel.CustomEvent;
  motion: Search.MotionSearchParams;
  unusualEvent?: Search.UnusualEventSearchParams;
  accessDoors?: Search.AccessControlSelectionChange;
  searchType: SearchType;
  faces: Search.Faces;
}

export const defaultDateRange = { absolute: dateRangeInitial, type: UiCalendarPickerType.RELATIVE, relative: { unit: CustomUnit.days, value: 3 } };

export const initialState: MultiSearchState = {
  selectedCameras: [],
  searchSelections: [],
  highConfidence: true,
  precision: Search.Precision.HIGH,
  searchV2: true,
  dwellRange: {
    period: {
      unit: PeriodUnit.minutes,
      start: 0,
      end: 61,
    },
    start: 0,
    end: null,
  },
  cameraSearchSettingsFilters: {},
  objectSelections: null,
  objectSelectionsFormatted: null,
  outerOperator: SearchQueryOperator.AND,
  dateRange: null,
  dateTimeRange: defaultDateRange,
  appearancePeriod: {
    unit: PeriodUnit.seconds,
    start: 0,
    end: 0,

  },
  savedSearches: [],
  selectedSavedSearch: null,
  useSingleStore: false,
  totalItemsCount: 0,
  page: 0,
  perPage: 10,
  orderBy: 'name',
  orderDirection: 'asc',
  query: null,
  customEvent: null,
  motion: {
    sensitivityEnabled: false,
    minMotion: 50,
  },
  unusualEvent: {
    sensitivity: 5,
  },
  accessDoors: {
    doors: [],
    personName: '',
    status: '',
  },

  searchType: SearchType.AI,
  faces: {
    operation: Search.FacesOpeartion.Separate,
    objects: [],
  },
};

export const multiSearchStateReducer = createReducer(
  initialState,
  on(MultiSearchActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(MultiSearchActions.setSelectedCameras, (state, { selectedCameras, noReset }) => {
    if (noReset) {
      return {
        ...state,
        selectedCameras,
      };
    }
    return {
      ...state,
      selectedCameras,
      objectSelections: initialState.objectSelections,
      objectSelectionsFormatted: initialState.objectSelectionsFormatted,
      outerOperator: initialState.outerOperator,
    };
  }),
  on(MultiSearchActions.setSearchSelections, (state, { searchSelections }) => {
    return {
      ...state,
      searchSelections,
    };
  }),
  on(MultiSearchActions.setAccessControlSearch, (state, { accessDoors }) => {
    return {
      ...state,
      accessDoors,
    };
  }),
  on(MultiSearchActions.setSavedSearches, (state, { savedSearches }) => {
    return {
      ...state,
      savedSearches,
    };
  }),
  on(MultiSearchActions.setSelectedSavedSearch, (state, { selectedSavedSearch }) => {
    return {
      ...state,
      selectedSavedSearch,
    };
  }),
  on(MultiSearchActions.setHighConfidence, (state, { highConfidence }) => {
    return {
      ...state,
      highConfidence,
    };
  }),
  on(MultiSearchActions.setPrecision, (state, { precision }) => {
    return {
      ...state,
      precision,
    };
  }),
  on(MultiSearchActions.setSearchV2, (state, { searchV2 }) => {
    return {
      ...state,
      searchV2,
    };
  }),
  on(MultiSearchActions.setDwellRange, (state, { dwellRange }) => {
    return {
      ...state,
      dwellRange,
    };
  }),
  on(MultiSearchActions.getCamerasSearchSettingsSuccess, (state, { cameraSearchSettings, camerasCountRequested }) => {
    return {
      ...state,
      cameraSearchSettingsFilters: cameraSearchSettingsFilters(cameraSearchSettings, camerasCountRequested),
    };
  }),
  on(MultiSearchActions.setObjectSelection, (state, { objectSelections }) => {
    return {
      ...state,
      objectSelections,
    };
  }),
  on(MultiSearchActions.deleteObjectFromSelection, (state, { index }) => {
    const objectSelections = [...state.objectSelections];
    objectSelections.splice(index, 1);
    return {
      ...state,
      objectSelections,
    };
  }),
  on(MultiSearchActions.deletePropertyFromSelection, (state, { index, property, value }) => {
    const objectSelections = [...state.objectSelections];
    const object = _.cloneDeep(objectSelections[index]);
    switch (object.type) {
      case SearchObjectTypes.PERSON:
        if (property.key === 'genderType' || property.key === 'ageType') {
          object.properties[property.key].props[value] = false;
        } else {
          if (property?.colors?.includes(value)) {
            const idx = property.colors.indexOf(value);
            (<string[]>object.properties[property.key].props['colors']).splice(idx, 1);
          }
          if (property?.types?.includes(value)) {
            const idx = property.types.indexOf(value);
            (<string[]>object.properties[property.key].props['type']).splice(idx, 1);
          }
        }
        break;
      case SearchObjectTypes.VEHICLE:
        const idx = property.value.value.indexOf(value);
        object.properties[property.key].value.splice(idx, 1);
        break;
    }
    objectSelections[index] = {
      ...object,
    };
    return {
      ...state,
      objectSelections,
    };
  }),
  on(MultiSearchActions.setObjectSelectionFormatted, (state, { objectSelectionsFormatted }) => {
    return {
      ...state,
      objectSelectionsFormatted,
    };
  }),
  on(MultiSearchActions.setOuterOperator, (state, { outerOperator }) => {
    return {
      ...state,
      outerOperator,
    };
  }),
  on(MultiSearchActions.setDateRange, (state, { dateRange }) => {
    return {
      ...state,
      dateRange,
    };
  }),
  on(MultiSearchActions.setDateTimeRange, (state, { dateTimeRange }) => {
    return {
      ...state,
      dateTimeRange,
    };
  }),
  on(MultiSearchActions.setQuery, (state, { query }) => {
    return {
      ...state,
      query,
    };
  }),
  on(MultiSearchActions.setPaginationParams, (state, { page, perPage }) => {
    return {
      ...state,
      page,
      perPage,
    };
  }),
  on(MultiSearchActions.setTotalItemsCount, (state, { totalItemsCount }) => {
    return {
      ...state,
      totalItemsCount,
    };
  }),
  on(MultiSearchActions.setUseSingleStore, (state, { useSingleStore }) => {
    return {
      ...state,
      useSingleStore,
    };
  }),
  on(MultiSearchActions.loadSavedSearch, state => {
    return {
      ...state,
      ...state.selectedSavedSearch,
    };
  }),
  on(MultiSearchActions.setRelativeTime, state => {
    if (state.dateTimeRange.type === UiCalendarPickerType.RELATIVE) {

    }
    return {
      ...state,
      ...state.selectedSavedSearch,
    };
  }),
  on(MultiSearchActions.removeSelectedCamera, (state, { cameraId }) => {
    const selectedCameras = [...state.selectedCameras].filter(camera => camera.edgeOnly.cameraId !== cameraId);
    return {
      ...state,
      selectedCameras,
    };
  }),
  on(MultiSearchActions.setCustomEvent, (state, { customEvent }) => {
    return {
      ...state,
      customEvent,
    };
  }),
  on(MultiSearchActions.setMotion, (state, { motion }) => {
    return {
      ...state,
      motion,
    };
  }),
  on(MultiSearchActions.setUnusualEvent, (state, { unusualEvent }) => {
    return {
      ...state,
      unusualEvent,
    };
  }),
  on(MultiSearchActions.setSearchType, (state, { searchType }) => {
    return {
      ...state,
      searchType,
    };
  }),
  on(MultiSearchActions.setFaces, (state, { faces }) => {
    return {
      ...state,
      faces,
    };
  }),
);

const cameraSearchSettingsFilters = (cameraSearchSettings: CameraSearchSettings[], camerasCountRequested: number) => {
  const settings = {};
  cameraSearchSettings.forEach(cameraFilter => {
    const filterKeys = Object.keys(cameraFilter.filters);
    filterKeys.forEach(key => {
      if (settings[key] === undefined) {
        settings[key] = true;
      }
      //Disabled take precedence
      settings[key] = settings[key] && cameraFilter.filters[key];
    });
  });

  return settings;
};
