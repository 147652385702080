import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CameraSettingsModel } from '@models/camera-settings.model';
import { CameraSettingsActions } from '@states/camera-settings/camera-settings.action-types';

export interface CameraSettingsState extends EntityState<CameraSettingsModel.CameraSettingUI> {

}

export const cameraSettingsAdapter: EntityAdapter<CameraSettingsModel.CameraSettingUI> = createEntityAdapter<CameraSettingsModel.CameraSettingUI>({
  selectId: (document: CameraSettingsModel.CameraSettingUI) => document.cameraId,
});


const initialState: CameraSettingsState = cameraSettingsAdapter.getInitialState({});


export const cameraSettingsStateReducer = createReducer(
  initialState,
  on(CameraSettingsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(CameraSettingsActions.getCameraSettingsSuccess, (state, { documents }) => {
    return cameraSettingsAdapter.setMany(documents, {
      ...state,
    });
  }),
  on(CameraSettingsActions.updateCameraSettings, (state, { document }) => {
    return cameraSettingsAdapter.setOne(document, {
      ...state,
    });
  }),
);

