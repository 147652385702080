import { AccessoryType, ActionType, AgeType, AlertSettingSendFrequency, AlertType, AnalyticClasses, CarryingType, CarType, DetectionType, FootwearType, GenderType, HairType, LowerBodyType, UpperBodyType } from '@enums/alert-events.enum';
import { OrganizationUser } from '@models/organization.model';
import { KeyValuePairs } from '../../core/interfaces';
import { Point } from './canvas-selector.model';
import { EdgeCamera } from '../../cameras/camera.model';
import { Zones } from '../../shared/ui-kit/ui-zone-selector/ui-zone-selector.model';
import { DayOfWeek } from '@enums/shared.enum';
import { AlertNotificationMethod } from '@models/alerts-v2.model';

export declare interface AlertEventsConfigurationFilter {
  //person
  ageType: AgeType[];
  carryingType: CarryingType[];
  lowerbodyType: LowerBodyType[];
  upperbodyType: UpperBodyType[];
  accessoryType: AccessoryType[];
  footwearType: FootwearType[];
  hairType: HairType[];
  genderType: GenderType[];
  upperbodyColor: string[];
  lowerbodyColor: string[];
  hairColor: string[];
  footwearColor: string[];
  //vehicle attr
  colors: string[];
  make: string[];
  model: string[];
  type: CarType[];
  //vehicle license
  greenList: string;
  redList: string;
  unrecognized: boolean;
}

export interface ObjectInterface {
  object: AnalyticClasses;
  filters?: AlertEventsConfigurationFilter;
}

export declare interface Configuration {
  object: AnalyticClasses;
  objects?: ObjectInterface[];
  offender?: AnalyticClasses;
  detection: DetectionType;
  detectionAdditionalAttributes: { direction?: string; count?: number; sensitivity?: number };
  filters: AlertEventsConfigurationFilter;
  tresholdTime: number;
}

/**
 * @deprecated
 * use EventV2Document instead
 */
export declare interface AlertEvent {
  _id?: string;
  name: string;
  enabled?: boolean;
  selectedCamera?: EdgeCamera.CameraItem;
  multiCameras?: EdgeCamera.CameraItem[];
  alertType: AlertType;
  configuration: Configuration;
  //zones
  zones?: Zones;
  selections?: boolean;
  markedIdx?: number[];
  definedZones?: boolean;
  measureCrossZones?: boolean;
  settings?: AlertSettings;
  notifications?: any;
  lineCrossing?: any;
  trafficControl?: any;
  timezone?: string;
  actions?: AlertActionNotifications;
}

export interface SelectedCamera {
  cameraId: string;
  locationId?: string;
  edgeId: string;
  timezone?: string;
}

export declare interface AlertEventSendModel {
  _id?: string;
  name: string;
  selectedCamera?: SelectedCamera;
  multiCameras?: EdgeCamera.CameraItem[];
  alertType: AlertType;
  configuration: Configuration;
  //zones
  zones?: Zones;
  definedZones?: boolean;
  measureCrossZones?: boolean;
  markedIdx?: number[];

  settings: AlertSettings;
  notifications: any;
  lineCrossing?: any;
  enabled?: boolean;
  synced?: boolean;
  trafficControl?: any;
  alertEventState?: string;
  timezone?: string;
  actions: AlertActionNotifications;
}

export interface AlertDirectAction {
  protocol: ActionProtocol;
  address: string;
  port: number;
  trafficController: boolean;
  msg?: string;
  body?: string;
}

export enum ActionProtocol {
  HTTP,
  UDP,
  TCP,
}

export enum ActionProtocolNoHttp {
  UDP = 1,
  TCP = 2,
}

export enum AlertActionGpioType {
  On,
  Off,
}

export interface AlertActionForm {
  type: ActionType;
  data: AlertGpioAction | AlertMsgAction | AlertDirectAction;
}

export interface AlertGpioAction {
  id: number;
  action: AlertActionGpioType;
}

export enum HttpMethods {
  GET,
  POST,
}

export interface AlertMsgAction {
  cloudHttp?: string;
  localHttp?: string;
  method: HttpMethods;
  body?: string;
}

export interface AlertActionNotifications {
  msgActions?: AlertMsgAction[];
  gpioActions?: AlertGpioAction[];
  directActions?: AlertDirectAction[];
}

export interface AlertEventNotification {
  orgUsers: OrganizationUser[];
  manualUsers: OrganizationUser[];
  notificationMethods: KeyValuePairs<AlertNotificationMethod[]>;
}

export enum AlertEventLineCrossingState {
  NO_SELECTION,
  P1_SELECTED,
  P2_SELECTED,
}

export enum AlertEventLineCrossingDirection {
  ANY,
  CW,
  CCW,
}

export interface AlertEventLineCrossing {
  p1?: Point;
  p2?: Point;
  d?: AlertEventLineCrossingDirection;
  state?: AlertEventLineCrossingState;
  name?: string;
}

export const AlertEventLineColors = ['blue', 'green', 'yellow', 'purple', 'cyan'];
export const AlertEventLineColorsHex: KeyValuePairs<string> = {
  blue: '#3B82F6',
  green: '#10B981',
  yellow: '#F59E0B',
  purple: '#9300cb',
  cyan: '#00c7bd',
};

export interface AlertEventTrafficControlLine {
  name?: string;
  color: string;
  p1: Point;
  p2: Point;
  d: AlertEventLineCrossingDirection;
}

export interface AlertEventTrafficControl {
  lines: AlertEventTrafficControlLine[];
  distance: number;
  distanceUnits: 'meter' | 'feet';
  state: AlertEventLineCrossingState;
}

export interface AlertEventSettingAutoArchive {
  enabled?: boolean;
  ttlSeconds: number;
}

export interface AlertSettings {
  schedule: AlertEventSettingSchedule;
  frequency: AlertSettingSendFrequency;
  pushAlert: boolean;
  additionalOptions: boolean;
  blockNotificationPeriod: number;
  enableNotificationSound: boolean;
  alertThumbnail: boolean;
  alertZoomThumbnail: boolean;
  autoArchive: AlertEventSettingAutoArchive;
}

export interface AlertSettingsSelector {
  schedule: {
    day: DayOfWeek;
    allDay: boolean;
    from: string;
    to: string;
  }[];
  frequency: AlertSettingSendFrequency;
  additionalOptions: boolean;
  blockNotificationPeriod: number;
}

export interface AlertEventSettingSchedule {
  monday: AlertSchedule;
  tuesday: AlertSchedule;
  wednesday: AlertSchedule;
  thursday: AlertSchedule;
  friday: AlertSchedule;
  saturday: AlertSchedule;
  sunday: AlertSchedule;
}

export interface AlertSchedule {
  from: Date;
  to: Date;
}
