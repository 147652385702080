// local-network.worker.service.ts
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class LocalNetworkWorkerService {
  private worker: Worker | undefined;

  constructor(private authenticationService: AuthenticationService) {
    if (typeof Worker !== 'undefined') {
      // IMPORTANT: Make sure the path here matches your actual worker file
      this.worker = new Worker(new URL('../../workers/local-network.worker', import.meta.url));
    }
  }

  // We'll replicate the existing fetch logic but add a unique requestId
  fetchLocalNetworkInWorker(edgeId: string, url: string): Observable<any> {
    if (!this.worker) {
      return new Observable((subscriber) => {
        subscriber.error('Web Workers not supported in this environment.');
      });
    }

    return new Observable((subscriber) => {
      // Generate a unique ID for this request
      // (For a real app, you might use a UUID library or a better unique generator)
      const requestId = uuid.v4();

      const handleMessage = (evt: MessageEvent) => {
        const msg = evt.data;

        // Make sure we only handle the response corresponding to *this* requestId
        if (
          msg?.type === 'GET_LOCAL_NETWORK_SUCCESS' &&
          msg.requestId === requestId
        ) {
          subscriber.next(msg.payload);
          // console.log('Worker Message Received:', msg);

          subscriber.complete();
        } else if (
          msg?.type === 'GET_LOCAL_NETWORK_FAIL' &&
          msg.requestId === requestId
        ) {
          // console.log('Worker Message Received:', msg);

          subscriber.error(msg.error);
        }
      };

      this.worker.addEventListener('message', handleMessage);

      const token = this.authenticationService.getIdTokenFromLocalStorage();

      // Send the message to the worker *with* the requestId
      this.worker.postMessage({
        type: 'GET_LOCAL_NETWORK',
        requestId,     // Include requestId in the outgoing message
        edgeId,
        url,
        token,
      });

      // Cleanup when unsubscribed
      return () => {
        this.worker?.removeEventListener('message', handleMessage);
      };
    });
  }
}
