import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UiCameraSnapshotComponent } from '../ui-camera-snapshot/ui-camera-snapshot.component';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { DeviceStatusSelectors } from '@states/device-status/device-status.selector-types';
import { PulsationModels } from '@models/pulsation.model';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;
import { Observable } from 'rxjs';
import { LetDirective, PushPipe } from '@ngrx/component';
import { AsyncPipe, NgIf } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { PipesModule } from '../../../pipes/pipes.module';
import { UiCameraStatusComponent } from '../ui-camera-status/ui-camera-status.component';

@Component({
  selector: 'ui-camera-status-snapshot',
  standalone: true,
  imports: [
    UiCameraSnapshotComponent,
    LetDirective,
    AsyncPipe,
    FlexModule,
    NgIf,
    PipesModule,
    PushPipe,
    UiCameraStatusComponent,
  ],
  templateUrl: './ui-camera-status-snapshot.component.html',
  styleUrl: './ui-camera-status-snapshot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiCameraStatusSnapshotComponent implements OnChanges {
  @Input() cameraId: string;
  @Input() edgeId: string;
  @Input() cameraName: string;
  @Input() showStatus: boolean = true;
  @Input() showName: boolean = true;
  @Input() iconSizePx = 40;

  public cameraStatusDisplay = ComponentStatusDisplay;
  public selectCameraStatus$: Observable<{
    edgeId: string,
    cameraId: string,
    status: ComponentStatusDisplay
  }>;


  constructor(private store$: Store<AppState>) {
  }

  public ngOnChanges() {
    this.selectCameraStatus$ = this.store$.pipe(select(DeviceStatusSelectors.selectCamerasStatusByCameraId(this.cameraId)));
  }
}
