import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, share, switchMap, withLatestFrom } from 'rxjs/operators';
import { CameraSettingsActions } from '@states/camera-settings/camera-settings.action-types';
import { CamerasSettingsService } from '../../services/cameras-settings.service';
import { CameraSettingsModel } from '@models/camera-settings.model';

@Injectable()
export class CameraSettingsEffect {

  public getCameraSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraSettingsActions.getCameraSettings),
      switchMap(() => {
        return this.camerasSettingsService.getCamerasSettings()
          .pipe(
            switchMap(res => {
              const uiCompatibleResponse: CameraSettingsModel.CameraSettingUI[] = res.map(item => {
                return CameraSettingsModel.convertCameraSettingMongoDocumentToCameraSettingDocument(item);
              });
              return [
                CameraSettingsActions.getCameraSettingsSuccess({ documents: uiCompatibleResponse }),
              ];
            }),
            catchError(err => {
              return [
                CameraSettingsActions.getCameraSettingsFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private camerasSettingsService: CamerasSettingsService,
  ) {
  }
}
