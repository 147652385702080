import { createReducer, on } from '@ngrx/store';
import { RouteActions } from '@states/route/route.action-types';

import { UiBreadCrumbItem } from '@models/route.models';

export declare interface RouteState {
  breadcrumbs: UiBreadCrumbItem[];
}

export const initialState: RouteState = {
  breadcrumbs: [],
};

export const routeStateReducer = createReducer(
  initialState,
  on(RouteActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(RouteActions.setBreadcrumbs, (state, { breadcrumbs }) => {
    return {
      ...state,
      breadcrumbs,
    };
  }),
);
