import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-copy-clipboard',
  templateUrl: './ui-copy-clipboard.component.html',
  styleUrls: ['./ui-copy-clipboard.component.scss'],
})
export class UiCopyClipboardComponent implements OnInit {
  @Input() copyContent: string;
  @Input() fontSize: number = 16;
  @Input() toolTip: string = 'Copy link';
  @Input() padding: number = 0;
  public copiedIndex = -1;

  constructor() {
  }

  ngOnInit(): void {
  }

  public copyIndex(index: number) {
    this.copiedIndex = index;
    setTimeout(() => {
      this.copiedIndex = -1;
    }, 2000);
  }
}
