import { createReducer, on } from '@ngrx/store';
import * as IntegrationsActions from '@states/integrations/integrations.actions';
import { IntegrationsModel } from '@models/integrations.model';

export declare interface IntegrationsState {
  integrations: IntegrationsModel.Providers[];
  details: IntegrationsModel.IntegrationsBaseDocument;
}


export const initialState: IntegrationsState = {
  integrations: null,
  details: null,
};

export const integrationsStateReducer = createReducer(
  initialState,
  on(IntegrationsActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(IntegrationsActions.getIntegrationsSuccess, (state, { integrations }) => {
    return {
      ...state,
      integrations,
    };
  }),
  on(IntegrationsActions.getIntegrationDetailsSuccess, (state, { details }) => {
    return {
      ...state,
      details,
    };
  }),
  on(IntegrationsActions.updateIntegrationsQuotaSuccess, (state, { details }) => {
    return {
      ...state,
      details,
    };
  }),
  on(IntegrationsActions.uninstallGenea, (state) => {
    const integrations = state.integrations.filter(integration => integration !== IntegrationsModel.Providers.Genea);
    return {
      ...state,
      integrations,
    };
  }),
  on(IntegrationsActions.uninstallKisi, (state) => {
    const integrations = state.integrations.filter(integration => integration !== IntegrationsModel.Providers.Kisi);
    return {
      ...state,
      integrations,
    };
  }),
);
