import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccessDoorsState } from '@states/access-doors/access-doors.reducer';
import { AccessDoorsModel } from '@models/access-doors.model';

export const selectAccessDoorsState = createFeatureSelector<AccessDoorsState>('accessDoorsState');

export const selectAllDoors = createSelector(selectAccessDoorsState, ({ entities }: AccessDoorsState) => Object.values(entities ?? {}));
export const selectHasAccessDoors = createSelector(selectAccessDoorsState, ({ entities }: AccessDoorsState) => !!Object.values(entities ?? {})?.length);
export const selectDoorsBySourceType = (sourceType: AccessDoorsModel.SourceType) =>
  createSelector(selectAccessDoorsState, ({ entities }: AccessDoorsState) => Object.values(entities ?? {})
    .filter((door) => door?.sourceType === sourceType));
export const selectIsInitialLoaded = createSelector(selectAccessDoorsState, ({ isInitialLoaded }: AccessDoorsState) => isInitialLoaded);
export const selectNotEmpty = createSelector(selectAccessDoorsState, ({ notEmpty }: AccessDoorsState) => notEmpty);

export const selectDoorsByCameraId = (cameraId: string) =>
  createSelector(selectAccessDoorsState, ({ entities }: AccessDoorsState) => Object.values(entities ?? {})
    .filter((door) => door?.cameras?.map(camera => camera?.cameraId)
      ?.includes(cameraId)));
