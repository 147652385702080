import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UiKitModule } from '../ui-kit.module';
import { BehaviorSubject, Observable } from 'rxjs';
import { Dictionary } from '@ngrx/entity/src/models';
import { EdgeCamera } from '../../../cameras/camera.model';
import { select, Store } from '@ngrx/store';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { AppState } from '../../../store/app.state';
import { LetDirective } from '@ngrx/component';
import { UiCameraSnapshotComponent } from '../ui-camera-snapshot/ui-camera-snapshot.component';
import { UiCameraStatusSnapshotComponent } from '../ui-camera-status-snapshot/ui-camera-status-snapshot.component';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-ui-camera-shortcut',
  standalone: true,
  imports: [
    UiKitModule,
    LetDirective,
    UiCameraSnapshotComponent,
    UiCameraStatusSnapshotComponent,
    AsyncPipe,
    NgIf,
    NgTemplateOutlet,
    MatTooltip,
  ],
  templateUrl: './ui-camera-shortcut.component.html',
  styleUrl: './ui-camera-shortcut.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiCameraShortcutComponent implements OnDestroy {
  @Input() coords: { x: string, y: string };
  @Input() cameraId: string;
  @Input() parentContainer: HTMLElement;
  @Input() viewMode: boolean = false;
  @Input() draggingState: { cameraId: string, state: boolean } = null;

  @Output() onDeletedShortcut: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEditShortcut: EventEmitter<string> = new EventEmitter<string>();
  @Output() openVideo: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('nameBlock') nameBlock: ElementRef;

  public readonly MODAL_WIDTH_PX = 204;
  public readonly MODAL_HEIGHT_PX = 159;
  public readonly CAMERA_NAME_WIDTH_PX = 175;
  public selectCamerasLookup$: Observable<Dictionary<EdgeCamera.CameraItem>> = this.store$.pipe(select(CameraSelectors.selectCamerasLookup));
  public isOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public cameraSnapshotLeftPx$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public cameraModalNoBottomSpace$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private store$: Store<AppState>,
              private el: ElementRef) {
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.viewMode) {
      this.onHover();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.viewMode) {
      this.destroy();
    }
  }

  @HostListener('mouseup')
  onClick(): void {
    if (!this.viewMode) {
      this.onHover();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.el.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.destroy();
    }
  }

  private onHover() {
    if (this.draggingState?.cameraId === this.cameraId && this.draggingState?.state) {
      return;
    }
    this.open();
  }

  public open() {
    const currentStatus = this.isOpened$.getValue();
    this.isOpened$.next(!currentStatus);
    if (!currentStatus) {
      this.calculate();
    } else {
      this.destroy();
    }
  }

  destroy() {
    this.isOpened$.next(false);
    this.cameraSnapshotLeftPx$.next(0);
    this.el.nativeElement.style.top = `${this.coords.y}%`;
    this.el.nativeElement.style.left = `${this.coords.x}%`;
    this.cameraModalNoBottomSpace$.next(false);
  }

  public ngOnDestroy() {
    this.destroy();
  }

  private calculate() {
    const xCoordPx = (this.parentContainer.clientWidth * +this.coords.x) / 100;
    const yCoordPx = (this.parentContainer.clientHeight * +this.coords.y) / 100;

    let nameWidth = this.CAMERA_NAME_WIDTH_PX;
    if (this.nameBlock) {
      nameWidth = this.nameBlock.nativeElement.offsetWidth;
    }
    const rightDistancePx = this.parentContainer.clientWidth - xCoordPx;
    const ifRightSpaceForNameIsNotOk = rightDistancePx < nameWidth / 2;
    const ifLeftSpaceOk = xCoordPx > nameWidth / 2;
    const ifRightSpaceForVideoIsNotOk = rightDistancePx < this.MODAL_WIDTH_PX / 2;
    const ifLeftSpaceForVideoOk = xCoordPx > this.MODAL_WIDTH_PX / 2;

    if (ifRightSpaceForNameIsNotOk) {
      const left = xCoordPx - (nameWidth - rightDistancePx);
      this.el.nativeElement.style.left = `${left}px`;

    } else if (ifLeftSpaceOk) {
      this.el.nativeElement.style.left = `calc( ${this.coords.x}% - ${nameWidth / 2}px`;
    }

    if (ifRightSpaceForVideoIsNotOk) {
      const left = xCoordPx - (nameWidth - rightDistancePx);
      const needsSpacePx = this.parentContainer.clientWidth - left;
      this.cameraSnapshotLeftPx$.next(-1 * (this.MODAL_WIDTH_PX - needsSpacePx));
    } else if (ifLeftSpaceForVideoOk) {
      this.cameraSnapshotLeftPx$.next(-1 * (this.MODAL_WIDTH_PX / 2 - nameWidth / 2));
    }

    const bottomDistancePx = this.parentContainer.clientHeight - yCoordPx;
    if (bottomDistancePx < this.MODAL_HEIGHT_PX) {
      this.cameraModalNoBottomSpace$.next(true);
      this.el.nativeElement.style.top = `calc(${this.coords.y}% - 121px)`;
    }
  }
}
