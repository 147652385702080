import { createAction, props } from '@ngrx/store';
import { AccessDoorsModel } from '@models/access-doors.model';

export const resetToInitialState = createAction('[ACCESS DOORS] Reset to Initial state');

export const getOrganizationAccessDoors = createAction('[ACCESS DOORS] Get Organization Access Doors', props<{ sourceType?: AccessDoorsModel.SourceType }>());
export const getOrganizationDoorsSuccess = createAction('[ACCESS DOORS] Get Organization Doors Success', props<{ documents: AccessDoorsModel.DocumentMongo[] }>());
export const getOrganizationDoorsFail = createAction('[ACCESS DOORS] Get Organization Doors Fail');

export const startAddDoor = createAction('[ACCESS DOORS] Start add access door', props<{ door: AccessDoorsModel.CreateDocumentDto, sourceType?: AccessDoorsModel.SourceType }>());
export const sendAddDoorRequest = createAction('[ACCESS DOORS] Send add access door request', props<{ door: AccessDoorsModel.CreateDocumentDto, sourceType?: AccessDoorsModel.SourceType }>());
export const sendAddDoorRequestSuccess = createAction('[ACCESS DOORS] Send add access door request Success', props<{ door: AccessDoorsModel.DocumentMongo }>());
export const sendAddDoorRequestFail = createAction('[ACCESS DOORS] Send add access door request Fail');

export const setIsCreatingLoader = createAction('[ACCESS DOORS] Set is creating Loader', props<{ isCreating: boolean }>());

export const startDeleteDoor = createAction('[ACCESS DOORS] Start delete access door', props<{ id: string }>());
export const sendDeleteDoorRequest = createAction('[ACCESS DOORS] Send delete access door request', props<{ id: string }>());
export const sendDeleteDoorRequestSuccess = createAction('[ACCESS DOORS] Send delete access door request Success', props<{ id: string }>());
export const sendDeleteDoorRequestFail = createAction('[ACCESS DOORS] Send delete access door request Fail', props<{ id: string }>());

export const startUpdateDoor = createAction('[ACCESS DOORS] Start Update access door', props<{ door: AccessDoorsModel.DocumentMongo }>());
export const sendUpdateDoorRequest = createAction('[ACCESS DOORS] Send Update access door request', props<{ door: AccessDoorsModel.DocumentMongo }>());
export const sendUpdateDoorRequestSuccess = createAction('[ACCESS DOORS] Send Update access door request Success', props<{ door: AccessDoorsModel.DocumentMongo }>());
export const sendUpdateDoorRequestFail = createAction('[ACCESS DOORS] Send Update access door request Fail');
