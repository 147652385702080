import { Injectable, OnInit } from '@angular/core';
import { HttpService } from '../core/http.service';
import { Search } from './search.model';
import { environment } from '../../environments/environment';
import { BehaviorSubject, catchError, concatMap, Observable, Subject, take, takeUntil, tap, throwError, timeout } from 'rxjs';
import { Sort } from './shared.model';
import { SearchSelection } from './advanced-search/advanced-search.model';
import * as MultiSearchSelectors from '@states/multi-search/multi-search.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { CamerasThumbnailsService } from '../cameras/camera-thumbnails/camera-thumnails.service';
import { TrackObjectState } from '@states/track-object/track-object.reducer';
import * as TrackObjectSelectors from '@states/track-object/track-object.selectors';
import { withLatestFrom } from 'rxjs/operators';
import { PeriodUnit } from './ui-kit/ui-period/ui-period.component';
import { UtilsV2Service } from '../services/utils-v2.service';
import { api } from '@consts/url.const';
import { DashboardModel } from '@models/dashboard.model';

export enum SearchType {
  MOTION_VECTORS = 'motion-vectors',
  ANALYTICS = 'analytics',
  MULTI_ANALYTICS = 'multi-analytics',
  MULTI_TRACKER = 'multi-tracker',
  CUSTOM_EVENTS = 'custom-events',
  MOTION_VECTOR = 'motion-vector/search',
  COUNT = 'count',
  UNUSUAL_EVENTS = 'unusual-events',
  ACCESS_DOORS = 'access-doors',
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SearchService implements OnInit {
  private searchSubject: Subject<unknown> = new Subject<unknown>();
  public search$: Observable<unknown> = this.searchSubject.asObservable();

  private resetSubject: Subject<unknown> = new Subject<unknown>();
  public reset$: Observable<unknown> = this.resetSubject.asObservable();

  searchSelectionsSubject = new BehaviorSubject<SearchSelection[]>([]);

  public searchSelections$: Observable<SearchSelection[]> = this.searchSelectionsSubject.asObservable();
  trackObject$: Observable<TrackObjectState> = this.store.pipe(untilDestroyed(this), select(TrackObjectSelectors.selectTrackObjectState));
  public selectDwellRange$: Observable<Search.SearchDwellRange> = this.store.pipe(select(MultiSearchSelectors.selectDwellRange));
  public selectSearchV2$: Observable<boolean> = this.store.pipe(select(MultiSearchSelectors.selectSearchV2));
  public selectFaces$: Observable<Search.Faces> = this.store.pipe(select(MultiSearchSelectors.selectFaces));


  constructor(private httpService: HttpService, private store: Store, private cameraThumbnailsService: CamerasThumbnailsService,
              private utilsV2Service: UtilsV2Service) {
  }

  ngOnInit(): void {
  }

  setSearchSelections(selections: SearchSelection[]) {
    this.searchSelectionsSubject.next(selections);
  }

  triggerSearch() {
    this.searchSubject.next(undefined);
  }

  reset() {
    this.resetSubject.next(undefined);
  }

  searchMotionVectors(
    page: number,
    size = 500,
    sort: Sort,
    request: Search.MotionVectorSearchRequest,
    filter?: Search.SearchQuery,
  ): Observable<Search.MotionVectorSearchResponse> {
    return this.search(page, size, sort, SearchType.MOTION_VECTORS, request, false, filter);
  }

  multiSearchAnalytic(
    page: number,
    size = 500,
    sort: Sort,
    request: Search.MultiAnalyticSearchRequest,
  ): Observable<Search.MultiAnalyticResponse> {
    const baseUrl = !!environment.searchUrl ? environment.searchUrl : environment.apiUrl;
    let url = `${baseUrl}/search/multi?page=${page}&size=${size}&sort=${sort}`;
    return this.store.select(MultiSearchSelectors.selectSearchPrecision)
      .pipe(
        take(1),
        tap(precision => {
          request.precision = precision;
        }),
        concatMap(_ =>
          this.httpService.http.post<Search.MultiAnalyticResponse>(url, request)
            .pipe(takeUntil(this.httpService.onCancelPendingRequests())),
        ),
      );
  }

  searchMultiTracker(
    page: number,
    size = 500,
    sort: Sort,
    request: Search.MultiTrackerSearchRequest,
    filter?: Search.SearchQuery,
    multiTracker = false,
    multi = false,
  ): Observable<Search.AnalyticSearchResponse> {
    return this.store.select(MultiSearchSelectors.selectSearchPrecision)
      .pipe(
        take(1),
        withLatestFrom(this.trackObject$.pipe(take(1)), this.selectSearchV2$.pipe(take(1))),
        tap(([precision, trackObject, searchV2]) => {
          filter.cameraId = trackObject.cameraId;
          request.onlyFaceId = trackObject.onlyFaceId;
          request.precision = precision;
          request.searchSelections = undefined;
          request.searchV2 = searchV2 ?? false;
        }),
        concatMap(_ => this.search(page, size, sort, SearchType.MULTI_TRACKER, request, false, filter)),
      );
  }

  searchAnalytic(
    page: number,
    size = 500,
    sort: Sort,
    request: Search.AnalyticSearchRequest | Search.MultiTrackerSearchRequest | DashboardModel.SearchLinkObject,
    filter?: Search.SearchQuery,
    multiTracker = false,
    multi = false,
    dashboard = false,
  ) {
    if (dashboard) {
      request.timeRange = [{ start: +filter.start, end: +filter.end }];
      return this.search(page, size, sort, SearchType.ANALYTICS, request, false, filter);
    }
    return this.store.select(MultiSearchSelectors.selectSearchPrecision)
      .pipe(
        take(1),
        withLatestFrom(
          this.selectDwellRange$.pipe(take(1)),
          this.selectSearchV2$.pipe(take(1)),
          this.store.select(MultiSearchSelectors.selectDateTimeRange),
          this.selectFaces$.pipe(take(1)),
        ),
        tap(([precision, dwellRange, searchV2, dateRange, faces]) => {
          const timeRange = this.utilsV2Service.dateRangeWithAdvancedOptionsToServerRequestV2(dateRange);
          const period = dwellRange.period;
          const any = ((period.unit === PeriodUnit.hours && period.end > 24) || period.unit !== PeriodUnit.hours && period.end === 61);
          request.precision = precision;
          request.timeRange = timeRange;
          request.searchV2 = searchV2 ?? false;
          request.dwellRange = any ? {
            ...dwellRange,
            end: null,
          } : dwellRange;
          if (faces?.objects?.length) {
            request.faces = faces;
          }
        }),
        concatMap(_ => this.search(page, size, sort, multi ? SearchType.MULTI_ANALYTICS : SearchType.ANALYTICS, request, false, filter)),
        catchError(err => {
          return throwError(err);
        }),
      );
  }


  searchCustomEvents(
    page: number,
    size = 500,
    sort: Sort,
    request: Search.CustomEventSearchRequest,
    filter?: Search.SearchQuery,
  ) {
    return this.search(page, size, sort, SearchType.CUSTOM_EVENTS, request, false, filter);
  }

  searchMotionVector(
    page: number,
    size = 500,
    sort: Sort,
    request: Search.MotionSearchRequest,
    filter?: Search.SearchQuery,
  ) {
    return this.search(page, size, sort, SearchType.MOTION_VECTOR, request, false, filter);
  }

  searchUnusualEvents(
    page: number,
    size = 500,
    sort: Sort,
    request: Search.MotionSearchRequest,
    filter?: Search.SearchQuery,
  ) {
    return this.search(page, size, sort, SearchType.UNUSUAL_EVENTS, request, false, filter);
  }

  count(
    request: Search.AnalyticSearchRequest | Search.MultiTrackerSearchRequest | Search.MotionVectorSearchRequest | Search.CustomEventSearchRequest,
    type: SearchType,
    filter?: Search.SearchQuery,
    dashboard = false,
  ) {
    if (type === SearchType.ANALYTICS) {
      return this.store.select(MultiSearchSelectors.selectSearchPrecision)
        .pipe(
          take(1),
          withLatestFrom(
            this.selectDwellRange$.pipe(take(1)),
            this.selectSearchV2$.pipe(take(1)),
            this.store.select(MultiSearchSelectors.selectDateTimeRange),
            this.selectFaces$.pipe(take(1))),
          tap(([precision, dwellRange, searchV2, dateRange, faces]) => {
            const period = dwellRange.period;
            const timeRange = dashboard ? [{ start: +filter.start, end: +filter.end }] : this.utilsV2Service.dateRangeWithAdvancedOptionsToServerRequestV2(dateRange);

            const any = ((period.unit === PeriodUnit.hours && period.end > 24) || period.unit !== PeriodUnit.hours && period.end === 61);
            (request as Search.AnalyticSearchRequest).precision = precision;
            (request as Search.AnalyticSearchRequest).searchV2 = searchV2;
            (request as Search.AnalyticSearchRequest).timeRange = timeRange;
            (request as Search.AnalyticSearchRequest).dwellRange = any ? {
              ...dwellRange,
              end: null,
            } : dwellRange;
            if (faces?.objects?.length) {
              (request as Search.AnalyticSearchRequest).faces = faces;
            }
          }),
          concatMap(_ => this.search(0, 1, Sort.DESC, type, request, true, filter)),
          catchError(err => {
            return throwError(err);
          }),
        );
    }
    return this.search(0, 1, Sort.DESC, type, request, true, filter);
  }

  search(
    page: number,
    size = 500,
    sort: Sort = Sort.DESC,
    type: SearchType,
    request: Search.MotionVectorSearchRequest | Search.AnalyticSearchRequest | Search.MultiAnalyticResponse | Search.SearchBody | Search.MotionSearchRequest | Search.CustomEventSearchRequest,
    count = false,
    filter?: Search.SearchQuery,
  ) {
    const baseUrl = !!environment.searchUrl ? environment.searchUrl : environment.apiUrl;
    let url = `${baseUrl}/search/${type}?page=${page}&size=${size}&sort=${sort}`;

    // For local usage
    switch (type) {
      case SearchType.CUSTOM_EVENTS:
        url = `${environment.customEventsUrl}/custom-events/search${count ? '/count' : ''}`;
        request = {
          ...request,
          start: filter?.start,
          end: filter?.end,
          page,
          sort,
          size,
        };
        break;
      case SearchType.ANALYTICS:
        url = `${baseUrl}/search/analytics${count ? '/count' : ''}`;
        request = {
          ...request,
          start: filter?.start,
          end: filter?.end,
          page,
          sort,
          size,
        };
        if (filter?.start) {
          url = url.concat(`?start=${filter.start}`);
        }
        if (filter?.end) {
          url = url.concat(`&end=${filter.end}`);
        }
        url = url.concat(`&page=${page}&size=${size}&sort=${sort}`);
        break;
      case SearchType.MOTION_VECTOR:
        url = `${environment.motionSearchUrl}/motion-vector/search${count ? '/count' : ''}`;
        request = {
          ...request,
          start: filter?.start,
          end: filter?.end,
          page,
          sort,
          size,
        };
        break;
      case SearchType.ACCESS_DOORS:
        url = `${api.accessDoors.search}${count ? '/count' : ''}`;
        delete request['cameras']
        request = {
          ...request,
          start: filter?.start,
          end: filter?.end,
          page,
          sort,
          size,
        };
        break;


      case SearchType.UNUSUAL_EVENTS:
        url = `${api.unusualAlerts.search}${count ? '/count' : ''}`;
        request = {
          ...request,
          start: filter?.start,
          end: filter?.end,
          page,
          sort,
          size,
        };
        break;
      default:
        if (filter?.edgeId) {
          url = url.concat(`&edgeId=${filter.edgeId}`);
        }
        if (filter?.cameraId) {
          url = url.concat(`&cameraId=${filter.cameraId}`);
        }
        if (filter?.start) {
          url = url.concat(`&start=${filter.start}`);
        }
        if (filter?.end) {
          url = url.concat(`&end=${filter.end}`);
        }
        break;
    }

    return this.httpService.http
      .post<Search.SearchResponse>(url, request)
      .pipe(takeUntil(this.httpService.onCancelPendingRequests()), timeout(120000));
  }

  saveSearch(data: Search.SaveSearchRequest) {
    const url = `${environment.apiUrl}/saved-search`;
    return this.httpService.http.post(url, data);
  }
}
