import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, exhaustMap, of, share, switchMap, withLatestFrom } from 'rxjs';
import { SharedActions } from '@states/shared/shared.action-types';
import { UtilsService } from '../../edge/utils.service';
import { mergeMap } from 'rxjs/operators';
import { AccessDoorsActions } from '@states/access-doors/access-doors.action-types';
import { AccessDoorsService } from '../../services/access-doors.service';


@Injectable()
export class AccessDoorsEffects {

  public startAddGeneaDoor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessDoorsActions.startAddDoor),
      switchMap(({ door, sourceType }) => {
        return [
          AccessDoorsActions.setIsCreatingLoader({ isCreating: true }),
          AccessDoorsActions.sendAddDoorRequest({ door: door, sourceType })];
      }),
    ),
  );

  public sendAddDoorRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessDoorsActions.sendAddDoorRequest),
      switchMap(({ door, sourceType }) => {
        return this.accessDoorsService.create(door, sourceType)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Access door has been created' }),
                AccessDoorsActions.setIsCreatingLoader({ isCreating: false }),
                AccessDoorsActions.sendAddDoorRequestSuccess({ door: res }),
              ];
            }),
            catchError((error) => {
              const errorObj = JSON.parse(error?.error?.exception);
              console.log(errorObj);
              return [
                AccessDoorsActions.setIsCreatingLoader({ isCreating: false }),
                AccessDoorsActions.sendAddDoorRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public getOrganizationGeneaDoors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessDoorsActions.getOrganizationAccessDoors),
      switchMap(({ sourceType }) => {
        return this.accessDoorsService.getOrganizationAccessDoors(sourceType)
          .pipe(
            switchMap(res => {
              return [
                AccessDoorsActions.getOrganizationDoorsSuccess({ documents: res }),
              ];
            }),
            catchError(() => {
              return of(
                AccessDoorsActions.getOrganizationDoorsFail(),
              );
            }),
          );
      }),
    ),
  );

  public startDeleteGenea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessDoorsActions.startDeleteDoor),
      debounceTime(400),
      exhaustMap(({ id }) => [AccessDoorsActions.sendDeleteDoorRequest({ id })]),
    ),
  );

  public sendDeleteGeneaRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessDoorsActions.sendDeleteDoorRequest),
      mergeMap(({ id }) => {
        return this.accessDoorsService
          .delete(id)
          .pipe(
            mergeMap(res => {
              return [
                AccessDoorsActions.sendDeleteDoorRequestSuccess({ id }),
                SharedActions.showMessage({ success: 'Genea door has been deleted' }),
              ];
            }),
            catchError(response => {
              const err = this.utilsService.errMessage(response);
              return [
                AccessDoorsActions.sendDeleteDoorRequestFail({ id }),
                SharedActions.showMessage({ error: err }),
              ];
            }),
            share(),
          );
      }),
    ),
  );


  public startUpdateGenea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessDoorsActions.startUpdateDoor),
      switchMap(({ door }) => {
        return [
          AccessDoorsActions.setIsCreatingLoader({ isCreating: true }),
          AccessDoorsActions.sendUpdateDoorRequest({ door: door })];
      }),
    ),
  );

  public sendUpdateGeneaRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessDoorsActions.sendUpdateDoorRequest),
      switchMap(({ door }) => {
        return this.accessDoorsService.update(door)
          .pipe(
            switchMap(res => {
              return [
                SharedActions.showMessage({ success: 'Genea has been updated' }),
                AccessDoorsActions.setIsCreatingLoader({ isCreating: false }),
                AccessDoorsActions.sendUpdateDoorRequestSuccess({ door: res }),
              ];
            }),
            catchError((error) => {
              const errorObj = JSON.parse(error?.error?.exception);
              console.log(errorObj);
              return [
                AccessDoorsActions.setIsCreatingLoader({ isCreating: false }),
                AccessDoorsActions.sendUpdateDoorRequestFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  constructor(private accessDoorsService: AccessDoorsService,
              private actions$: Actions,
              private utilsService: UtilsService) {
  }

}
