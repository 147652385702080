import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { EdgeCamera } from '../cameras/camera.model';
import { Store } from '@ngrx/store';
import { CameraActions } from '@states/camera/camera.action-types';
import { CameraSelectors } from '@states/camera/camera.selector-types';

export interface ThumbnailsEntry {
  _id?: string;
  edgeId: string;
  cameraId: string;
  timestamp: number;
  normalizedTimestamp: number;
  data: string;
}

export interface SnapshotEntry {
  edgeId: string;
  cameraId: string;
  thumbnail: string;
  timestamp: number;
}

export interface QueryByEdgeId {
  edgeId?: string;
  cameraId?: string;
  timestamp?: number;
  normalizedTimestamp?: number;
  start?: string | number;
  end?: string | number;
}

export type ThumbnailsQuery = QueryByEdgeId;

const TWENTY_MINUTES = 20 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class ThumbnailsService {
  constructor(private http: HttpClient, private store$: Store) {
    setInterval(() => {
      console.log('refreshing snapshots');
      this.store$.select(CameraSelectors.selectAllCameras)
        .pipe(take(1))
        .subscribe(cameras => {
          this.store$.dispatch(CameraActions.GetLocationEdgesCamerasSnapshots({ payload: cameras }));
        });
    }, TWENTY_MINUTES);
  }

  parseObject(parse = true) {
    return source$ =>
      source$.pipe(
        map((res: Array<ThumbnailsEntry>) => {
          return parse
            ? res.map((item, index) => {
              let data = item.data;

              if (data) {
                try {
                  data = JSON.parse(data);
                } catch (error) {
                  data = item.data;
                }
              }

              if (item['timestamp']) {
                item['day'] = new Date(item['timestamp']);
              }
              item['index'] = index;
              if (item[index] !== 0) {
                // item['difference'] = item[index]['timestamp'] - item[index - 1]['timestamp']
                // item['difference'] = item[index];
              }

              const e = !!data ? { ...item, ...{ data } } : { ...item };
              return e;
            })
            : res;
        }),
      );
  }

  getAllThumbnails(page: number, size = 20, filter?: ThumbnailsQuery): Observable<ThumbnailsEntry[]> {
    let url = `${environment.apiUrl}/thumbnails?page=${page}&size=${size}`;

    for(const key in filter) {
      const element = filter[key];
      if (!!element) {
        url = url.concat(`&${key}=${element}`);
      }
    }

    return this.http.get<ThumbnailsEntry[]>(url)
      .pipe(this.parseObject(true));
  }

  getSnapshotsForLocationCameras(cameras: EdgeCamera.CameraItem[]): Observable<ThumbnailsEntry[]> {
    let url = `${environment.apiUrl}/thumbnails/snapshots`;
    return this.http.post<ThumbnailsEntry[]>(url, { cameras });
  }
}
