<ng-container *ngrxLet="selectCameraStatus$;let cameraStatus">
  <div class="wrapper h-100 w-100 flex-col center-center">
    <ui-camera-snapshot class="w-100 h-100"
                        [cameraId]="cameraId"
                        [edgeId]="edgeId"
    ></ui-camera-snapshot>
    @if (cameraStatus?.status === cameraStatusDisplay.Offline) {
      <div class="back-drop w-100 h-100">
      </div>
      <span class="ui-icon-camera-off" [style.font-size.px]="iconSizePx">
      </span>
    }
    @if (showName || showStatus) {
      <div class="status-name-block flex-row start-center gap-2">
        <ui-camera-status *ngIf="showStatus" [cameraId]="cameraId" [iconSizePx]="14"></ui-camera-status>
        <span *ngIf="showName">{{ cameraName }}</span>
      </div>
    }

  </div>
</ng-container>
