import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, shareReplay, take } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { doorComparator, speakerComparator } from '../../../helpers/comparators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccessDoorsActions } from '@states/access-doors/access-doors.action-types';
import { AccessDoorsSelectors } from '@states/access-doors/access-doors.selector-types';
import { AccessDoorsModel } from '@models/access-doors.model';
import { AccessDoorsService } from '../../../services/access-doors.service';

@UntilDestroy()
@Component({
  selector: 'ui-access-door-open',
  templateUrl: './ui-access-door-open.component.html',
  styleUrl: './ui-access-door-open.component.scss',
})
export class UiAccessDoorOpenComponent implements OnInit, OnDestroy {

  public selectCameraDoors$: Observable<AccessDoorsModel.DocumentMongo[]>;

  public doorComparator = doorComparator;

  public selectedDoor: AccessDoorsModel.DocumentMongo;

  public opened = false;

  public doors: AccessDoorsModel.DocumentMongo[] = [];

  @Input() locationId: string;
  @Input() cameraId: string;

  constructor(private store$: Store, private accessDoorsService: AccessDoorsService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.store$.dispatch(AccessDoorsActions.getOrganizationAccessDoors({}));
    this.selectCameraDoors$ = this.store$.pipe(shareReplay(), select(AccessDoorsSelectors.selectDoorsByCameraId(this.cameraId)));
    this.selectCameraDoors$.pipe(take(1))
      .subscribe((doors) => {
        if (!!doors?.length) {
          this.doors = doors;
          this.selectedDoor = doors[0];
          this.accessDoorsService.getDoorState(this.selectedDoor?.doorId, this.selectedDoor.sourceType)
            .pipe(untilDestroyed(this))
            .subscribe(({ isLocked }) => {
              this.opened = !isLocked;
              if (this.selectedDoor.sourceType === AccessDoorsModel.SourceType.Kisi) {
                setTimeout(() => {
                  this.opened = false;
                }, 3000);
              }
            });
        }
      });
  }

  async getDoorState() {
    this.accessDoorsService.getDoorState(this.selectedDoor?.doorId, this.selectedDoor.sourceType)
      .pipe(untilDestroyed(this))
      .subscribe(({ isLocked }) => {
        this.opened = !isLocked;
      });
  }

  async openDoor() {
    if (this.opened && this.selectedDoor.sourceType === AccessDoorsModel.SourceType.Genea) {
      this.accessDoorsService.closeDoor(this.selectedDoor?.doorId, this.selectedDoor.sourceType)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.opened = false;
        });
      return;
    }
    this.accessDoorsService.openDoor(this.selectedDoor?.doorId, this.selectedDoor.sourceType)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.opened = true;
        setTimeout(() => {
          this.getDoorState();
        }, 7000);
      });
  }

}
