import { createAction, props } from '@ngrx/store';
import { CameraSettingsModel } from '@models/camera-settings.model';

export const resetToInitialState = createAction('[CAMERAS-SETTINGS] Reset to Initial state');

export const getCameraSettings = createAction('[CAMERAS-SETTINGS] Get camera settings');
export const getCameraSettingsSuccess = createAction('[CAMERAS-SETTINGS] Get camera settings success', props<{ documents: CameraSettingsModel.CameraSettingUI[] }>());
export const getCameraSettingsFail = createAction('[CAMERAS-SETTINGS] Get camera settings fail');

export const updateCameraSettings = createAction('[CAMERAS-SETTINGS] Update camera settings', props<{ document: CameraSettingsModel.CameraSettingUI }>());
