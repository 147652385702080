import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActivityLogActions from '@states/activity-log/activity-log.actions';
import { withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { catchError, debounceTime, exhaustMap, of, share, switchMap } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { AppState } from '../app.state';
import { ActivityLogService } from '../../development/activity-log.service';
import { OrganizationService } from '../../development/organization.service';
import { ActivityLogFilterName } from '@enums/activity-log.enum';
import { ActivityDailyEventGroup } from '@models/activity-log';
import { UtilsV2Service } from '../../services/utils-v2.service';
import * as moment from 'moment';
import { UserSelectors } from '@states/user/user.selector-types';

@Injectable()
export class ActivityLogEffect {
  public detailsValidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLogActions.getActivityLog),
      withLatestFrom(this.store$.pipe(select(state => state.activityLogState))),
      exhaustMap(([, { limit, isLastPage, lastActivityTimestamp, filters }]) => {
        if (!isLastPage) {
          const dateRangeFilter = this.utilsV2Service.dateRangeToServerRequest(filters.dateRange);

          const endTime = lastActivityTimestamp ?? dateRangeFilter.end;
          return this.activityLogService
            .get(limit, {
              start: moment(dateRangeFilter.start)
                .toString(),
              end: moment(endTime)
                .toString(),
              events: filters.events.map(item => item.id),
              authProviderIds: filters.users.map(user => user.authProviderId),
              orgIds: filters.orgs.map(item => item.orgId),
            })
            .pipe(
              switchMap(res => {
                return [ActivityLogActions.getActivityLogSuccess({ activityLogs: res })];
              }),
              catchError(err => of(SharedActions.doNothing())),
            );
        } else {
          return of(SharedActions.doNothing());
        }
      }),
      share(),
    ),
  );

  public downloadCsv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLogActions.downloadActivityLogCsv),
      withLatestFrom(this.store$.pipe(select((state) => state.activityLogState))),
      exhaustMap(([, { filters }]) => {
        const dateRangeFilter = this.utilsV2Service.dateRangeToServerRequest(filters.dateRange);

        this.store$.dispatch(SharedActions.setIsLoading({ isLoading: true }));

        const filename = `activity-log-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;

        return this.activityLogService
          .downloadCsv({
            start: moment(dateRangeFilter.start).toString(),
            end: moment(dateRangeFilter.end).toString(),
            events: filters.events.map((item) => item.id),
            authProviderIds: filters.users.map((user) => user.authProviderId),
            orgIds: filters.orgs.map((item) => item.orgId),
          })
          .pipe(
            switchMap((blob) => {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = filename;
              link.click();
              window.URL.revokeObjectURL(url);

              this.store$.dispatch(SharedActions.setIsLoading({ isLoading: false }));

              return of(SharedActions.showMessage({ success: 'CSV download started' }));
            }),
            catchError(() => {
              this.store$.dispatch(SharedActions.setIsLoading({ isLoading: false }));

              return of(SharedActions.showMessage({ error: 'Failed to download CSV' }));
            }),
          );
      }),
      share(),
    ),
  );




  public filter = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ActivityLogActions.setDateRangeFilter,
        ActivityLogActions.setFilter,
        ActivityLogActions.removeFilter,
        ActivityLogActions.refreshDateFilter,
      ),
      debounceTime(400),
      switchMap(() => {
        return [
          ActivityLogActions.resetToInitialStateWithoutFilters(),
          ActivityLogActions.getActivityLog(),
        ];
      }),
      share(),
    ),
  );

  public ifOrgSelectorChangedByDev = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ActivityLogActions.setFilter,
        ActivityLogActions.setActivityReportFilter,
      ),
      switchMap(({ filter }) => {
        if (filter === ActivityLogFilterName.orgs) {
          return [ActivityLogActions.getOrgUsers()];
        }
        return [
          SharedActions.doNothing(),
        ];
      }),
      share(),
    ),
  );


  public filterAuditReport = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ActivityLogActions.setDateRangeFilter,
        ActivityLogActions.setActivityReportFilter,
        ActivityLogActions.removeActivityReportFilter,
        ActivityLogActions.refreshActivityReportDateFilter,
      ),
      debounceTime(400),
      switchMap(() => {
        return [
          ActivityLogActions.resetToInitialStateWithoutFilters(),
          ActivityLogActions.findAndGroupByDailyEvents(),
        ];
      }),
      share(),
    ),
  );

  public getOrgUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLogActions.getOrgUsers),
      withLatestFrom(
        this.store$.pipe(select(state => state.activityLogState)),
        this.store$.pipe(select(UserSelectors.isDeveloper)),
        this.store$.pipe(select(UserSelectors.isDevTeam)),
      ),
      switchMap(([, { filters }, isDeveloper, isDevTeam]) => {
          if (isDeveloper || isDevTeam) {
            return this.organizationService.getUsersByOrg({ orgIds: filters.orgs.map(org => org.orgId) })
              .pipe(
                switchMap(users => {
                  return of(ActivityLogActions.getOrgUsersSuccess({ users }));
                }),
              );
          } else {
            return this.organizationService.getActiveOrganizationUsers()
              .pipe(
                switchMap(users => {
                  return of(ActivityLogActions.getOrgUsersSuccess({ users }));
                }),
              );
          }
        },
      ),
    ),
  );

  public findAndGroupByDailyEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLogActions.findAndGroupByDailyEvents),
      withLatestFrom(this.store$.pipe(select(state => state.activityLogState))),
      exhaustMap(([, { limit, isLastPage, lastActivityTimestamp, filters }]) => {
        if (!isLastPage) {
          const dateRangeFilter = this.utilsV2Service.dateRangeToServerRequest(filters.dateRange);

          const endTime = lastActivityTimestamp ?? dateRangeFilter.end;
          return this.activityLogService
            .findAndGroupByDailyEvents(limit, {
              start: moment(dateRangeFilter.start)
                .toString(),
              end: moment(endTime)
                .toString(),
              events: filters.events.map(item => item.id),
              authProviderIds: filters.users.map(user => user.authProviderId),
              orgIds: filters.orgs.map(item => item.orgId),
            })
            .pipe(
              switchMap(res => {
                return [ActivityLogActions.findAndGroupByDailyEventsSuccess({ groupedLogs: this.groupBy(res, 'method') })];
              }),
              catchError(err => of(SharedActions.doNothing())),
            );
        } else {
          return of(SharedActions.doNothing());
        }
      }),
      share(),
    ),
  );

  public generatePdfReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLogActions.generateAuditReportPdfReport),
      exhaustMap(() => {
        return this.activityLogService
          .generateAuditReportPdfReport()
          .pipe(
            switchMap(res => {
              return of(SharedActions.showMessage({ success: 'Report has been sent to your email' }));
            }),
            catchError(() => {
              return of(SharedActions.showMessage({ error: 'Error' }));
            }),
          );
      }),
      share(),
    ),
  );

  public generateAuditLogPdfReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLogActions.generateAuditLogPdfReport),
      exhaustMap(() => {
        return this.activityLogService
          .generateAuditLogPdfReport()
          .pipe(
            switchMap(res => {
              return of(SharedActions.showMessage({ success: 'Report has been sent to your email' }));
            }),
            catchError(() => {
              return of(SharedActions.showMessage({ error: 'Error' }));
            }),
          );
      }),
      share(),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private activityLogService: ActivityLogService,
    private organizationService: OrganizationService,
    private utilsV2Service: UtilsV2Service,
  ) {
  }

  private groupBy(objectArray, property): { [key: string]: ActivityDailyEventGroup[] } {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }

}
