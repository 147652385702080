import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs';
import { HomeActions } from '@states/home/home.action-types';
import { HomeService } from '../../development/home.service';
import { SharedActions } from '@states/shared/shared.action-types';
import { addCamerasToSubLocation, addCamerasToSubLocationSuccess, getLocations } from '@states/home/home.actions';
import { HomeModel } from '@models/home.model';
import { withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';


@Injectable()
export class HomeEffects {

  public getFavoriteLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getFavoriteLocations),
      switchMap(() => {
        return this.homeService.getFavorites()
          .pipe(
            switchMap((res) => {
              return [HomeActions.getFavoriteLocationsSuccess({ favorites: res.locations })];
            }),
            catchError((err) => {
                return [SharedActions.showMessage({ error: `Error Failed to star location: ${JSON.stringify(err?.message)}` })];
              },
            ));
      }),
    ),
  );

  public starLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.starLocation),
      switchMap(({ id }) => {
        return this.homeService.starLocation(id)
          .pipe(
            switchMap(() => {
              return [];
            }),
            catchError((err) => {
                return [SharedActions.showMessage({ error: `Error Failed to star location: ${JSON.stringify(err?.message)}` })];
              },
            ));
      }),
    ),
  );

  public unstarLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.unstarLocation),
      switchMap(({ id }) => {
        return this.homeService.unstarLocation(id)
          .pipe(
            switchMap(() => {
              return [];
            }),
            catchError((err) => {
                return [SharedActions.showMessage({ error: `Error Failed to unstar location: ${JSON.stringify(err?.message)}` })];
              },
            ));
      }),
    ),
  );

  public getLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getLocations),
      switchMap(_ => {
        return this.homeService.getHomeLocations()
          .pipe(
            switchMap((locations) => {
              return [HomeActions.getLocationsSuccess({ locations })];
            }),
            catchError((err) => {
              return [SharedActions.showMessage({ error: `Failed to get home locations: ${JSON.stringify(err?.message)}` })];
            }),
          );
      }),
    ),
  );

  public serverRequestCreateSubLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.serverRequestCreateSubLocation),
      switchMap(({ sublocation }) => {
        return this.homeService.addSubLocation(sublocation)
          .pipe(
            switchMap((document) => {
              return [HomeActions.serverRequestCreateSubLocationSuccess({ document })];
            }),
            catchError((err) => {
                return [SharedActions.showMessage({ error: `Error Failed to create sub location: ${JSON.stringify(err?.message)}` })];
              },
            ));
      }),
    ),
  );

  public addCameraToSubLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.addCamerasToSubLocation),
      withLatestFrom(this.store$.pipe(select(state => state.homeState))),
      switchMap(([{ sublocationId }, { selectedCameras }]) => {
        if (selectedCameras.length === 0) {
          return [SharedActions.showMessage({ warning: `No cameras selected are selected, please select at least one camera` })];
        }
        const request: HomeModel.AddCamerasToSubLocationRequest = {
          subLocationId: sublocationId,
          cameras: selectedCameras,
        };
        return this.homeService.addCamerasToSubLocation(request)
          .pipe(
            switchMap((res) => {
              return [HomeActions.getLocations(), HomeActions.clearCameraSelectionMode()];
            }),
            catchError((err) => {
                return [SharedActions.showMessage({ error: `Error Failed to add camera to sub location: ${JSON.stringify(err?.message)}` })];
              },
            ));
      }),
    ),
  );

  public addCamerasToSubLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.addCameraToSubLocation),
      switchMap(({ request }) => {
        return this.homeService.addCameraToSubLocation(request)
          .pipe(
            switchMap((res) => {
              return [HomeActions.getLocations()];
            }),
            catchError((err) => {
                return [SharedActions.showMessage({ error: `Error Failed to add camera to sub location: ${JSON.stringify(err?.message)}` })];
              },
            ));
      }),
    ),
  );

  public removeCameraFromSubLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.removeCameraFromSubLocation),
      switchMap(({ request }) => {
        return this.homeService.removeCameraFromSubLocation(request)
          .pipe(
            switchMap((res) => {
              return [HomeActions.getLocations()];
            }),
            catchError((err) => {
                return [SharedActions.showMessage({ error: `Error: Failed to remove camera to sub location: ${JSON.stringify(err?.message)}` })];
              },
            ));
      }),
    ),
  );

  public deleteSubLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.deleteSubLocation),
      switchMap(({ request }) => {
        return this.homeService.deleteSubLocation(request)
          .pipe(
            switchMap((res) => {
              return [HomeActions.getLocations()];
            }),
            catchError((err) => {
                return [SharedActions.showMessage({ error: `Error: Failed to delete sub location: ${JSON.stringify(err?.message)}` })];
              },
            ));
      }),
    ),
  );

  public updateSubLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.updateSubLocation),
      switchMap(({ request }) => {
        return this.homeService.updateSubLocation(request)
          .pipe(
            switchMap((res) => {
              return [HomeActions.getLocations()];
            }),
            catchError((err) => {
                return [SharedActions.showMessage({ error: `Error: Failed to update sub location: ${JSON.stringify(err?.message)}` })];
              },
            ));
      }),
    ),
  );

  constructor(private actions$: Actions, private homeService: HomeService, private store$: Store<AppState>) {
  }

}
