import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { VideoService } from '../../../development/video.service';
import { WebrtcPlayerState } from '../../webrtc-v2/webrtc-v2.component';
import { PlaybackError, WbRTCActiveSession, WebRTCPeerControlTypes, WebRTCPeerInterface, WebRTCPeerInterfaceControl, WebRTCPeerInterfacePlaybackSeek, WebRTCPeerInterfacePlaybackSpeed, WebRTCPeerInterfacePlaybackStart, WebRtcPeerType, WebRTCPlaybackError, WebRTCPlaybackInfo, WebrtcStats } from '@models/webrtc.model';
import * as Ably from 'ably';
import { AblyTopics, AblyTopicsStr } from '@models/ably.model';
import { StorageActions } from '@states/storage/storage.action-types';
import { WebRTCActiveSessionSelectors } from '@states/webrtc-active-sessions/webrtc-active-sessions.selector-types';
import { catchError, filter, lastValueFrom, Observable, of, take } from 'rxjs';
import { WebRtcActiveSessionActions } from '@states/webrtc-active-sessions/webrtc-active-sessions.action-types';
import { LiveStreamModels } from '@models/live-stream.model';
import { LockService } from '../../../development/lock.service';
import { VisibilityChanged } from '../../directives/visibility-change.directive';
import { WebrtcDebugDialogComponent, WebrtcDebugDialogData } from '../../../framework/webrtc-player/webrtc-debug-dialog/webrtc-debug-dialog.component';
import { WebrtcService } from '../../../development/webrtc.service';
import * as uuid from 'uuid';
import { CamerasService } from '../../../cameras/cameras.service';
import { MatDialog } from '@angular/material/dialog';
import { PlayerBaseComponent } from '../player-base/player-base.component';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { ActiveOrganization } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';

const DEFAULT_EXTEND_TIME = 120000;


@UntilDestroy()
@Component({
  selector: 'video-webrtc',
  templateUrl: './webrtc.component.html',
  styleUrl: './webrtc.component.scss',
})
export class WebrtcComponent extends PlayerBaseComponent implements OnInit, OnDestroy {

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  @Input() isExternallyManaged = false;
  @Input() public disableExtend = false;
  @Input() ablyDefault = false;

  @Output() isRelay: EventEmitter<boolean> = new EventEmitter<boolean>();

  public disableInactivity = true;

  public counters = {
    start: 0,
    stop: 0,
    sessionGenerate: 0,
  };


  // WEBRTC CONFIGURATION AND HANDLING

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event) {
    if (this.sessionId) {
      this.stop();
    }
  }

  private ablyClient: Ably.Realtime;
  private ablyChannel: Ably.RealtimeChannel;
  public sessionId: string;
  private extendInterval;
  statsInterval;


  public get peerDataBase() {
    return {
      locationId: this.locationId,
      edgeId: this.edgeId,
      cameraId: this.cameraId,
      timestamp: new Date().getTime(),
      sessionId: this.sessionId,
      cloud: true,
    };
  }

  rtc_configuration: RTCConfiguration = {
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' },
    ],
  };
  state: WebrtcPlayerState = {
    hq: true,
    pc: null,
    relay: false,
    incomingIce: [],
    receivedOffer: false,
    configuration: this.rtc_configuration,
  };
  stats: WebrtcStats = {
    output: '',
    bitrate: 0,
    prevTimestamp: 0,
    prevBytesReceived: 0,
  };

  private pendingChanges: SimpleChanges[] = [];
  private _isRelay = false;
  private forceWebrtcRelay = false;

  private cloudflareTurn = false;

  constructor(
    private lockService: LockService,
    private webrtcService: WebrtcService,
    private camerasService: CamerasService,
    private dialog: MatDialog,
    store$: Store,
    cd: ChangeDetectorRef,
    videoService: VideoService) {
    super(store$, cd, videoService);
  }


  async checkHiddenDocument(visibilityChanged: VisibilityChanged) {
    this.windowHidden = visibilityChanged.hidden;
    const sessionId = this.sessionId;

    const playing = !!sessionId;
    if (!visibilityChanged.hidden) {
      // Tab is visible change
      if (!this.sessionId) {
        console.log('extend called without session id');
        await this.play();
        return;
      } else {
        await this.extend();
        this.healthCheck();
        setTimeout(() => {
          this.healthCheck();
        }, 5000);
      }
    } else {
      // Tab is hidden change

    }
  }

  public async extend() {
    if (document.hidden) {
      return;
    }
    const peerData = <WebRTCPeerInterfaceControl>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: this.playback ? WebRTCPeerControlTypes.PlaybackExtend : WebRTCPeerControlTypes.Extend,
    };
    await this.publishMsg(peerData);
  }


  ngAfterViewInit(): void {
    this.initPlayer();
  }

  override ngOnDestroy(): void {

    if (this.extendInterval) {
      clearInterval(this.extendInterval);
    }

    this.clearHealthCheck();
    if (!this.isExternallyManaged) {
      if (this.sessionId) {
        this.stop();
      }
      if (!!this.state.pc) {
        this.state?.pc?.close();
        this.resetState();
      }
    }
    if (this.statsInterval) {
      clearInterval(this.statsInterval);
    }

    this.destroyAbly();

    if (this.lockService.isLocked(`webrtc-${this.cameraId}`)) {
      this.lockService.releaseLock(`webrtc-${this.cameraId}`);
    }

    super.ngOnDestroy();
  }

  override async ngOnInit(): Promise<void> {
    this.selectActiveOrganization$.pipe(take(1))
      .subscribe(activeOrg => {
        this.cloudflareTurn = activeOrg?.cloudflareTurn ?? false;
      });
    await this.initAbly();
    this.initVideoControls();
    if (this.playback) {
      const ts = this.videoService.ts;
      this.play(ts);
    } else {
      this.play();
    }
    super.ngOnInit();
  }


  async play(ts?: number) {
    if (!this || this._destroyed) {
      return;
    }
    if (!!ts) {
      console.log(`[WEBRTC][PLAYBACK]: ${new Date(ts)}`);
    }
    if (document.hidden) {
      return;
    }

    if (this.playback && this.sessionId) {
      return this.seek(ts);
    }

    /* If this webrtc session is externally managed and already exist - play it */
    if (this.isExternallyManaged && this.state?.sessionId) {
      await this.playExisting();
      return;
    }
    /* Otherwise - create a new session */
    await this.startWebrtcSession(ts);
  }

  stop() {
    this.setPlaceholder();
    this.clearVideo();
    this.stopWebrtcSession(this.inactive, true);
  }


  pause() {
    if (this.playback && !this.sessionId) {
      this.video.pause();
    }
  }

  resetState(resetErrors = true) {
    this.state = {
      hq: this.state.hq,
      pc: null,
      incomingIce: [],
      receivedOffer: false,
      configuration: this.rtc_configuration,
    };
    if (this.video) {
      this.video.srcObject = null;
    }
    if (resetErrors) {
      this.resetErrors();
    }
    this.sessionId = null;
  }

  async initAbly() {
    let ablyOptions: Ably.ClientOptions;
    ablyOptions = {
      key: 'E1YILg.eTBPUQ:m4VkVmbMO6ftJBdS4GLcayKXXn8bp8cXj3B-jBkjIDA',
      echoMessages: false, realtimeHost: 'msg.lumix.ai', restHost: 'msg.lumix.ai', fallbackHosts: [
        'a-fallback.msg.lumix.ai', 'b-fallback.msg.lumix.ai', 'c-fallback.msg.lumix.ai', 'd-fallback.msg.lumix.ai', 'e-fallback.msg.lumix.ai'],
    };
    if (this.ablyDefault) {
      ablyOptions = {
        key: 'E1YILg.eTBPUQ:m4VkVmbMO6ftJBdS4GLcayKXXn8bp8cXj3B-jBkjIDA',
        echoMessages: false,
      };
    }
    this.ablyClient = new Ably.Realtime(ablyOptions); /* inferred type Ably.Realtime */
    this.ablyChannel = this.ablyClient.channels.get(this.edgeId); /* inferred type Ably.Types.RealtimeChannel */


    await this.ablyChannel.attach();
    // Getting presence on a channel
    await this.ablyChannel.presence.get();

    await this.ablyChannel
      .subscribe(AblyTopics[this.playback ? AblyTopics.Playback : AblyTopics.WebRTCPeer], async (message) => {

        const peerData: WebRTCPeerInterface = message.data;
        if (peerData.sessionId !== this.sessionId) {
          return;
        }
        let timestamp = 0;
        if (peerData?.timestamp !== timestamp && peerData?.type !== undefined) {
          switch (peerData.type) {
            case WebRtcPeerType.ICE:
              if (this.state.receivedOffer) {
                await this.onIncomingICE(peerData.ice);
              } else {
                this.state.incomingIce.push(peerData.ice);
              }
              break;
            case WebRtcPeerType.SDP:

              await this.onIncomingSDP(peerData.sdp);
              break;
            default:
              switch (peerData.control) {
                case WebRTCPeerControlTypes.Close:
                  this.recover = false;
                  this.stop();
                  break;
                case WebRTCPeerControlTypes.PlaybackInfo:
                  const data: WebRTCPlaybackInfo = peerData as WebRTCPlaybackInfo;
                  this.store$.dispatch(StorageActions.setOfflineStorageStats({ offlineStorage: data.noStorage }));
                  this.store$.dispatch(StorageActions.setSmartStorageStats({ smartStorage: data.smartStorage }));
                  break;
                case WebRTCPeerControlTypes.PlaybackError:
                  const err: WebRTCPlaybackError = peerData as WebRTCPlaybackError;
                  switch (err.error) {
                    case PlaybackError.InternalError:
                      this.playbackErrors.internal = true;
                      break;
                    case PlaybackError.SessionLimitReached:
                      this.playbackErrors.maxSessions = true;
                      break;
                  }
                  this.stop();
                  break;
                default:
                  break;
              }
              break;
          }
        }
      });
    // await this.ablyClient.connection.once('connected');
  }

  async publishMsg(msg: any) {
    if (!this.ablyChannel || this.ablyClient.connection.state !== 'connected') {
      return;
    }
    return this.ablyChannel?.publish(this.playback ? AblyTopicsStr[AblyTopics.Playback] : AblyTopicsStr[AblyTopics.WebRTCPeer], JSON.stringify({ data: msg, time: new Date().getTime() }));
  }

  public async executeChanges(changes: SimpleChanges) {
    if (this.isExternallyManaged) {
      this.isExternallyManaged = false;
    }
    if (changes['cameraId'] && this.autostart) {
      if (!this.isExternallyManaged) {
        let randomNumber = Math.random() * 20;
        let roundedNumber = Math.round(randomNumber) * 50;
        await this.sleep(roundedNumber);
      }
      if (this.ablyClient) {
        this.destroyAbly();
      }

      if (this.isExternallyManaged) {
        this.store$.select(WebRTCActiveSessionSelectors.selectActiveSessionByEdgeIdCameraId({
            edgeId: this.edgeId,
            cameraId: this.cameraId,
          }))
          .pipe(untilDestroyed(this), take(1))
          .subscribe(
            (session) => {
              if (!!session) {
                if ((new Date().getTime() - session.timestamp > DEFAULT_EXTEND_TIME)) {
                  this.store$.dispatch(WebRtcActiveSessionActions.deleteActiveSession({
                    edgeId: this.edgeId,
                    cameraId: this.cameraId,
                  }));
                } else {
                  this.setExistingSession(session);
                  if (this.autostart) {
                    this.play();
                  }
                }
              } else {
                if (this.lockService.isLocked(`webrtc-${this.cameraId}`)) {
                  this.store$.select(WebRTCActiveSessionSelectors.selectActiveSessionByEdgeIdCameraId({
                      edgeId: this.edgeId,
                      cameraId: this.cameraId,
                    }))
                    .pipe(untilDestroyed(this), filter(session => !!session), take(1))
                    .subscribe(
                      (session) => {
                        this.setExistingSession(session);
                        if (this.autostart) {
                          this.play();
                        }
                      });
                } else {
                  this.lockService.acquireLock(`webrtc-${this.cameraId}`);
                  if (this.autostart) {
                    this.play();
                  }
                }
              }

            },
          );
      } else if (this.autostart) {
        this.play();
      }
    }
  }

  public setExistingSession(session: WbRTCActiveSession) {
    this.state = this.webrtcService.getActiveSession(this.edgeId, this.cameraId);
    this.rtc_configuration = this.state.configuration;
    // if (this.forceWebrtcRelay) {
    //   this.rtc_configuration.iceTransportPolicy = 'relay';
    // }
    this.sessionId = session.id;
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (this.isViewInitCompleted) {
      this.executeChanges(changes);
    } else {
      this.pendingChanges.push(changes);
    }
  }

  sleep(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  public async stopWebrtcSession(inactive?: boolean, resetErrors = true) {
    if (this.state.sessionSubscription) {
      this.state.sessionSubscription.unsubscribe();
    }
    if (this.statsInterval) {
      clearInterval(this.statsInterval);
    }

    this.state?.pc?.close();
    this.resetState(resetErrors);
    if (inactive) {
      this.setInactive.emit(true);
    }

    await this.publishStop();

    if (this.isExternallyManaged && this.lockService.isLocked(`webrtc-${this.cameraId}`)) {
      this.lockService.releaseLock(`webrtc-${this.cameraId}`);
    }
  }

  public async publishStop() {
    if (this.sessionId) {
      const peerData = <WebRTCPeerInterfaceControl>{
        ...this.peerDataBase,
        type: WebRtcPeerType.CONTROL,
        control: this.playback ? WebRTCPeerControlTypes.PlaybackStop : WebRTCPeerControlTypes.Stop,
        hq: this.state.hq,
        errorMsg: this.videoService.errorMsg,
      };

      this.sessionId = null;

      await this.publishMsg(peerData);
    }
  }

  public async speed(speed: number) {
    const peerData = <WebRTCPeerInterfacePlaybackSpeed>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.PlaybackSpeed,
      playbackSpeed: speed,
    };
    await this.publishMsg(peerData);
  }

  public async seek(ts: number) {
    const peerData = <WebRTCPeerInterfacePlaybackSeek>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.PlaybackSeek,
      playbackTS: ts,
    };
    await this.publishMsg(peerData);
    this.video.pause();
    this.video.currentTime = 0;
    setTimeout(() => {
      this.video.play();
      this.setDragging(false);
    }, 500);
  }

  public async startWebrtcSession(ts?: number) {
    if (this.sessionId) {
      this.setPlaceholder();
      this.stop();
    }
    this.setLoader.emit(true);
    this.setInactive.emit(false);
    if (!this.disableExtend) {
      if (this.extendInterval) {
        clearInterval(this.extendInterval);
      }
      this.extendInterval = setInterval(() => {
        this.extend();
      }, DEFAULT_EXTEND_TIME);
    }
    // Call get credentials
    if (!this.rtc_configuration['password']) {
      try {
        const res = await lastValueFrom(this.webrtcService.getCredentials(this.accessToken, this.cloudflareTurn));
        this.state.configuration = res;
        // if (this.forceWebrtcRelay) {
        //   this.state.configuration.iceTransportPolicy = 'relay';
        // }
        this.rtc_configuration = this.state.configuration;
      } catch (e) {
        this.state.configuration = this.rtc_configuration;
      }
    }
    this.sessionId = uuid.v4();
    this.state.sessionId = this.sessionId;
    this.counters.sessionGenerate++;

    this.start(ts);
    if (this.enableHealthCheck && !this.health?.interval) {
      this.startHealthCheck();
    }
    await this.createCall();
  }

  initStats() {
    if (this.statsInterval) {
      clearInterval(this.statsInterval);
    }
    this.statsInterval = setInterval(() => {
      this.state?.pc?.getStats(null)
        .then((stats) => {
          this.stats.output = '';

          if (this.state.stream) {
            this.stats.output += `<h2>Stream settings</h2>\n`;
            if (this.state.stream.getVideoTracks()[0]) {
              const settings = this.state.stream.getVideoTracks()[0]?.getSettings();
              if (settings) {
                for(let [key, value] of Object.entries(settings)) {
                  this.stats.output += `${key}: ${value}<br>\n`;
                }
              }
            }
          }
          let activeCandidatePair;
          stats.forEach(report => {
            if (report.type === 'transport') {
              activeCandidatePair = stats.get(report.selectedCandidatePairId);
            }
          });

          // Firefox workaround
          if (!activeCandidatePair) {
            stats.forEach(report => {
              if (report.type === 'candidate-pair' && report.selected) {
                activeCandidatePair = report;
              }
            });
          }
          if (!!activeCandidatePair) {
            const localCandidate = stats.get(activeCandidatePair.localCandidateId);
            if (localCandidate.candidateType === 'relay') {
              // Stream is not local - enable inactivity feature
              this.disableInactivity = false;
              if (this._isRelay !== true) {
                this._isRelay = true;
                if (this.isExternallyManaged) {
                  this.store$.dispatch(WebRtcActiveSessionActions.updateSessionLocal({
                    edgeId: this.edgeId, cameraId: this.cameraId, isLocal: false,
                  }));
                } else {
                  this.store$.dispatch(WebRtcActiveSessionActions.setActiveSession({
                    edgeId: this.edgeId,
                    cameraId: this.cameraId,
                    sessionState: this.state,
                    isLocal: false,
                    unmanaged: true,
                  }));
                }
                this.isRelay.emit(true);
              }
            } else {
              // Stream is local - disable inactivity feature
              this.disableInactivity = true;
              if (this._isRelay !== false) {
                this._isRelay = false;
                if (this.isExternallyManaged) {
                  this.store$.dispatch(WebRtcActiveSessionActions.updateSessionLocal({
                    edgeId: this.edgeId, cameraId: this.cameraId, isLocal: true,
                  }));
                } else {
                  this.store$.dispatch(WebRtcActiveSessionActions.setActiveSession({
                    edgeId: this.edgeId,
                    cameraId: this.cameraId,
                    sessionState: this.state,
                    isLocal: true,
                    unmanaged: true,
                  }));
                }
                this.isRelay.emit(false);
              }
            }
          }

          stats.forEach((report) => {

            if (report.type === 'inbound-rtp' && report.kind === 'video') {
              this.calculateBitrate(report.timestamp, report.bytesReceived);
            }

            this.stats.output +=
              `<h2>Report: ${report.type}</h2>\n<strong>ID:</strong> ${report.id}<br>\n` +
              `<strong>Timestamp:</strong> ${report.timestamp}<br>\n`;

            // Now the statistics for this report; we intentionally drop the ones we
            // sorted to the top above

            Object.keys(report)
              .forEach((statName) => {
                if (
                  statName !== 'id' &&
                  statName !== 'timestamp' &&
                  statName !== 'type'
                ) {
                  this.stats.output += `<strong>${statName}:</strong> ${report[statName]}<br>\n`;
                }
              });
          });
        });
    }, 1000);
  }

  calculateBitrate(timestamp: number, bytesReceived: number) {

    if (!!this.stats.prevTimestamp && !!this.stats.prevBytesReceived) {
      const bitrate = 8 * (bytesReceived - this.stats.prevBytesReceived) / (timestamp - this.stats.prevTimestamp);
      this.stats.bitrate = Math.max(0, bitrate); // ((bytesReceived - this.prevBytesReceived) / 128) / ((timestamp - this.prevTimestamp) / 1000);
    }

    this.stats.prevTimestamp = timestamp;
    this.stats.prevBytesReceived = bytesReceived;
  }

  private onVideoLoad = () => {
    this.playVideo();
  };

  async createCall() {
    const constraints = { audio: true, video: true };
    if (this.state.pc) {
      this.state?.pc?.close();
      this.state.pc = null;
    }
    this.state.pc = new RTCPeerConnection({ ...this.rtc_configuration });

    this.state.pc.ontrack = ({ track, streams }) => {
      // console.log(`[${this.cameraId}] ontrack`);
      track.onunmute = () => {
        if (!this.placeholder) {
          this.setPlaceholder();
        }
        if (!this.isPlaying) {
          this.state.stream = streams[0];
          this.video.srcObject = streams[0];
          this.video.load();
          this.video.removeEventListener('loadeddata', this.onVideoLoad);
          this.video.addEventListener('loadeddata', this.onVideoLoad);
          if (this.enableHealthCheck && !this.health?.interval) {
            this.startHealthCheck();
          }

          if (this.isExternallyManaged) {
            this.store$.dispatch(WebRtcActiveSessionActions.setActiveSession({
              edgeId: this.edgeId,
              cameraId: this.cameraId,
              sessionState: this.state,
            }));
          }
        }
        if (this.isPlaying) {
          this._removePlaceholder();
        } else {
          if (this.placeholderInterval) {
            clearInterval(this.placeholderInterval);
          }
          this.placeholderInterval = setInterval(() => {
            // need to review
            // if (this.isPlaying || this.offline) {
            //   if (!this.offline) {
            //     this._removePlaceholder();
            //   }
            //   clearInterval(this.placeholderInterval);
            // }
            if (this.isPlaying) {
              this._removePlaceholder();
              clearInterval(this.placeholderInterval);
            }
          }, 1000);
        }
      };

    };

    this.state.pc.onconnectionstatechange = (ev) => {
      const prefix = `[WebRTC][${this.sessionId}] `;
      switch (this?.state?.pc?.connectionState) {
        case 'checking':
        case 'connecting':
        case 'new':
          break;
        case 'connected':
          this.playing.emit(null);
          this.qualityChange.emit(false);
          this.setLoader.emit(false);
          break;
        case 'disconnected':
          this.onError('disconnected');
          break;
        case 'closed':
          this.onError('closed');
          break;
        case 'failed':
          this.onError('failed');
          break;
        default:
          break;
      }
    };

    this.state.pc.oniceconnectionstatechange = () => {
      if (this?.state?.pc?.iceConnectionState === 'failed') {
        // this.state.pc.restartIce();
        this.onError('iceConnectionState failed');
      }
    };

    this.state.pc.onicecandidate = async (event) => {
      if (event.candidate == null) {
        return;
      }
      const peerData = {
        ...this.peerDataBase,
        type: WebRtcPeerType.ICE,
        ice: event.candidate.toJSON(),
      };
      await this.publishMsg(peerData);
    };


    this.initStats();
  }

  destroyAbly() {
    try {
      if (this.ablyChannel) {
        this.ablyChannel.unsubscribe();
        this.ablyChannel.detach();
        this.ablyChannel.off();
      }

      if (this.ablyClient) {
        const connectionState = this.ablyClient.connection.state;
        if (connectionState === 'connected' || connectionState === 'connecting') {
          // Wait for a short delay to ensure messages are sent
          setTimeout(() => {
            this.ablyClient.close();
          }, 500);  // Adjust the delay as necessary
        } else if (connectionState !== 'closed') {
          this.ablyClient.close();
        }
      }
    } catch (e: any) {
      console.error('Error during Ably destruction:', e);
    }
  }

  async onIncomingSDP(sdp) {
    if (sdp.type === 'offer') {
      await this.state.pc.setRemoteDescription(sdp);
      this.state.receivedOffer = true;
      await this.state.pc.setLocalDescription();
      for(let ice of this.state.incomingIce) {
        await this.onIncomingICE(ice);
      }
      this.state.incomingIce = [];
      const peerData = {
        locationId: this.locationId,
        edgeId: this.edgeId,
        cameraId: this.cameraId,
        timestamp: new Date().getTime(),
        sessionId: this.sessionId,
        type: WebRtcPeerType.SDP,
        sdp: this.state.pc.localDescription.toJSON(),
      };
      await this.publishMsg(peerData);
    }
  };

  async onIncomingICE(ice) {
    // let candidate = new RTCIceCandidate(ice);
    try {
      if (this.forceWebrtcRelay && ice.candidate.includes('relay')) {
        const split = ice.candidate.split(' ');
        const score = +split[3] + 800000000;
        split[3] = score.toString();
        ice.candidate = split.join(' ');
      }

      await this.state.pc.addIceCandidate(ice);
    } catch (err) {
      this.onError('addIceCandidate failed');
    }
  };

  public async start(ts?: number) {
    // console.log('start() not hidden');
    // console.log(`[WEBRTC-START] cameraId: ${this.cameraId}, sessionId: ${this.sessionId}`);
    if (!this.isLive) {
      return this.playbackStart(this.ts);
    }
    this.counters.start++;
    const rtcConfiguration = this.state.configuration;
    // console.log(rtcConfiguration);
    const peerData = <WebRTCPeerInterfaceControl>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.Start,
      rtcConfiguration,
      hq: this.state.hq,
    };
    await this.publishMsg(peerData);
    if (!this.playback) {
      this.camerasService.startLiveView(this.edgeId, this.locationId, this.cameraId, false, this.state.hq)
        .subscribe();
    }
  }

  public async playExisting() {
    this.setLoader.emit(true);
    this.setInactive.emit(false);
    if (!this.disableExtend) {
      if (this.extendInterval) {
        clearInterval(this.extendInterval);
      }
      this.extendInterval = setInterval(async () => {
        await this.extend();
      }, DEFAULT_EXTEND_TIME);
      await this.extend();
    }
    this.video.srcObject = this.state.stream;
    this.video.load();
    this.video.removeEventListener('loadeddata', this.onVideoLoad);
    this.video.addEventListener('loadeddata', this.onVideoLoad);
    setTimeout(() => {
      this.playVideo();
    }, 3000);
    if (this.enableHealthCheck) {
      this.startHealthCheck();
    }
    this.initStats();
    this.setLoader.emit(false);
    this.started = true;
  }

  public async playbackStart(ts: number) {
    this.counters.start++;

    const rtcConfiguration = this.state.configuration;
    const peerData = <WebRTCPeerInterfacePlaybackStart>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.PlaybackStart,
      rtcConfiguration,
      hq: this.state.hq,
      playbackTS: ts,
    };
    await this.publishMsg(peerData);
  }

  public openStats() {
    const data: WebrtcDebugDialogData = {
      stats: this.stats,
    };
    this.dialog.open(WebrtcDebugDialogComponent, {
      panelClass: 'modal-no-padding',
      width: '90vw',
      height: '90vh',
      data,
    });
  }

  changeResolution(resolution: LiveStreamModels.StreamResolution) {
    this.setLoader.emit(true);
    this.state.hq = (resolution === LiveStreamModels.StreamResolution.HQ);
    this.stop();
    this.play();
  }

  override async onError(reason?: string) {
    if (this.sessionId) {
      delete this.sessionId;
    }

    await super.onError(reason);
  }

  public timeUpdate() {
    if (this.isWall) {
      return;
    }
    const timestamp = Date.now();
    this.store$.dispatch(MultiPlaybackActions.setMove({ move: { percentage: 0.5, timestamp } }));
  }
}
