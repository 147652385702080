export namespace IntegrationsModel {

  export interface NoonLightInstallRequest {
    token: string;
    quota: string;
  }

  export interface LumanaInstallRequest {
    quota: string;
  }

  export interface SlackInstallCallback {
    code: string;
    state: string;
  }

  export interface IntegrationCategory {
    name: string;
    options: Inegration[];
  }

  export interface Inegration {
    id: Providers;
    name: string;
    description: string;
    icon: string;
    installed?: boolean;
    beta?: boolean;
    defaultParams?: any;
  }

  export enum Providers {
    Slack = 'slack',
    RapidSOS = 'rapidSos',
    Webhook = 'webhook',
    Team3 = 'team3',
    NoonLight= 'noonLight',
    Lumana= 'lumana',
    Dispatch = 'Dispatch',
    Kisi = 'kisi',
    Genea = 'genea',
  }

  export type ProviderActions = RapidSOS.Actions | Slack.Actions;
  export type ProviderActionsParams = RapidSOS.ActionParams | Slack.ActionParams;

  export namespace RapidSOS {
    export enum Actions {
      callFlow = 'callFlow',
    }

    export type ActionParams = {
      [RapidSOS.Actions.callFlow]: {
        orgId: string;
      };
    };
  }

  export namespace Slack {
    export enum Actions {
      listChannels = 'listChannels',
      sendMessage = 'sendMessage',
    }

    export type ActionParams = {
      [Slack.Actions.listChannels]: {
        orgId: string;
      };
      [Slack.Actions.sendMessage]: {
        orgId: string;
        channel: string;
        text: string;
      };
    };
  }

  export interface SlackChannel {
    id: string;
    name: string;
  }

  export interface IntegrationListSlackChannelsResponse {
    channels: SlackChannel[];
  }

  export interface IntegrationsBaseDocument {
    createdAt?: number;
    orgId: string;
    slack?: {
      active: boolean;
      accessToken: string;
    };
    rapidSos?: {
      active: boolean;
      clientId?: string;
      clientSecret?: string;
    };
    noonLight?: {
      active: boolean;
      token: string;
      quota: string;
    };
    lumana?: {
      active: boolean;
      token: string;
      quota: string;
    };

    vcc?: {
      quota: string;
    }
  }

}
