import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounce, debounceTime, exhaustMap, filter, map, mergeMap, Observable, of, share, switchMap, withLatestFrom } from 'rxjs';
import { DeviceStatusActions } from '@states/device-status/device-status.actions-types';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Dictionary } from '@ngrx/entity/src/models';
import { PulsationModels } from '@models/pulsation.model';
import { LocationSelectors } from '@states/location/location.selector-types';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { selectHeartbeatCameraEntities } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation.selectors';
import { selectAnalyticsEntities } from '@states/analytic-heartbeat-pulsation/analytic-heartbeat-pulsation.selectors';
import { selectStorageEntities } from '@states/storage-heartbeat-pulsation/storage-heartbeat-pulsation.selectors';
import { selectAllCameras } from '@states/camera/camera.selectors';
import { selectSmartStorageEntities } from '@states/smart-storage-heartbeat-pulsation/smart-storage-heartbeat-pulsation.selectors';
import { selectSubstreamEntities } from '@states/substream-heartbeat-pulsation/substream-heartbeat-pulsation.selectors';
import ComponentStatus = PulsationModels.ComponentStatus;
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;
import { CameraActions } from '@states/camera/camera.action-types';
import { GetCameraPulsationStatusSuccess } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation.actions';
import { GetAnalyticPulsationStatusSuccess } from '@states/analytic-heartbeat-pulsation/analytic-heartbeat-pulsation.actions';
import { GetStoragePulsationStatus } from '@states/storage-heartbeat-pulsation/storage-heartbeat-pulsation.actions';
import { GetSubstreamPulsationStatusSuccess } from '@states/substream-heartbeat-pulsation/substream-heartbeat-pulsation.actions';
import { EdgeCamera, ProperFitting } from '../../cameras/camera.model';
import { CameraSelectors } from '@states/camera/camera.selector-types';

@Injectable()
export class DeviceStatusEffects {

  public calculationTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeviceStatusActions.calculateTrigger,
        CameraActions.DeleteCameraSuccess,
        GetCameraPulsationStatusSuccess,
        GetAnalyticPulsationStatusSuccess,
        GetStoragePulsationStatus,
        GetSubstreamPulsationStatusSuccess,
        GetSubstreamPulsationStatusSuccess,
      ),
      switchMap(() => {
        return [
          DeviceStatusActions.calculateEdgeStatus(),
        ];
      }),
    ),
  );


  public calculateCameraStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceStatusActions.setEdgesStatus),
      withLatestFrom(
        this.store$.pipe(select(selectHeartbeatCameraEntities)),
        this.store$.pipe(select(selectAnalyticsEntities)),
        this.store$.pipe(select(selectStorageEntities)),
        this.store$.pipe(select(selectAllCameras)),
        this.store$.pipe(select(selectSmartStorageEntities)),
        this.store$.pipe(select(selectSubstreamEntities)),
        this.store$.pipe(select(state => state.deviceStatusState)),
      ),
      mergeMap(([,
                  cameraStatusMap,
                  analyticsStatusMap,
                  storageStatusMap,
                  selectAllCameras,
                  smartStorageStatusMap,
                  subStreamStatusMap,
                  { edgeStatusMap },
                ]) => {
          const statusLookup: Dictionary<{ edgeId: string, cameraId: string, status: ComponentStatusDisplay }> = {};
          selectAllCameras.forEach(camera => {
            const cameraId = camera.edgeOnly.cameraId;
            let statusOnlineMainCondition;
            if (cameraStatusMap[cameraId]) {
              const isEdgeOnline = edgeStatusMap[camera.edgeId] === ComponentStatusDisplay.Online;
              const cameraStatusOnline = PulsationModels.getFilteredPulsationStatus(cameraStatusMap[cameraId]) === ComponentStatus.Online;
              const analyticStatusOnline = PulsationModels.getFilteredPulsationStatus(analyticsStatusMap[cameraId]) === ComponentStatus.Online;
              const storageStatusOnline = (PulsationModels.getFilteredPulsationStatus(storageStatusMap[cameraId]) === ComponentStatus.Online ||
                PulsationModels.getFilteredPulsationStatus(storageStatusMap[cameraId]) === ComponentStatus.NotRecording);
              statusOnlineMainCondition = cameraStatusOnline
                && (analyticStatusOnline || (typeof camera?.edgeOnly?.analyticMode !== 'undefined' && camera?.edgeOnly?.analyticMode !== EdgeCamera.AnalyticModes.Enabled))
                && storageStatusOnline
                && isEdgeOnline;


              /**
               * 18/12/2023 offline -> if stream is offline.
               * 25/03/2024 offline -> if edge is offline
               * all the other combination that today makes you offline should be changed to Unhealthy.
               */
              let statusOfflineMainCondition = PulsationModels.getFilteredPulsationStatus(cameraStatusMap[cameraId]) === ComponentStatus.Offline || edgeStatusMap[camera.edgeId] === ComponentStatusDisplay.Offline;


              if (camera.edgeOnly.smartStorage?.enabled) {
                const smartStorageStatus = PulsationModels.getFilteredPulsationStatus(smartStorageStatusMap[cameraId]);
                statusOnlineMainCondition = statusOnlineMainCondition && smartStorageStatus === PulsationModels.ComponentStatus.Online;
              }

              if (camera.edgeOnly.storageStream?.storageFromSubstream) {
                const subStreamStatus = PulsationModels.getFilteredPulsationStatus(subStreamStatusMap[cameraId]);
                statusOnlineMainCondition = statusOnlineMainCondition && subStreamStatus === PulsationModels.ComponentStatus.Online;
              }

              const finalStatus: PulsationModels.ComponentStatusDisplay = statusOnlineMainCondition ? ComponentStatusDisplay.Online : (statusOfflineMainCondition ? ComponentStatusDisplay.Offline : ComponentStatusDisplay.Unhealthy);

              statusLookup[cameraId] = {
                cameraId: cameraId,
                edgeId: camera.edgeId,
                status: finalStatus,
              };
            } else {
              statusLookup[cameraId] = {
                cameraId: cameraId,
                edgeId: camera.edgeId,
                status: ComponentStatusDisplay.Unhealthy,
              };
            }
          });

          return of(DeviceStatusActions.setCamerasStatus({ cameraStatusMap: statusLookup }));
        },
      ),
      share(),
    ),
  );


  public calculateEdgeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceStatusActions.calculateEdgeStatus),
      withLatestFrom(
        this.store$.pipe(select(state => state.edgePulsationState)),
        this.store$.pipe(select(EdgeSelectors.selectAllEdges)),
      ),
      mergeMap(([, { entities }, edgesMap]) => {
          const edgeStatusLookup: Dictionary<ComponentStatusDisplay> = {};
          edgesMap
            .forEach(edge => {
              edgeStatusLookup[edge.edgeId] = entities[edge.edgeId] ? PulsationModels.convertComponentStatusToComponentStatusDisplay(entities[edge.edgeId].status) : ComponentStatusDisplay.Unhealthy;
            });
          return of(DeviceStatusActions.setEdgesStatus({ edgeStatusMap: edgeStatusLookup }));
        },
      ),
      share(),
    ),
  );

  public calculateLocationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeviceStatusActions.setEdgesStatus,
        DeviceStatusActions.setEdgeStatusById,
        DeviceStatusActions.setCamerasStatus,
        DeviceStatusActions.setCameraStatusById,
      ),
      withLatestFrom(
        this.store$.pipe(select(LocationSelectors.selectLocationEntities)),
        this.store$.pipe(select(state => state.deviceStatusState)),
        this.store$.pipe(select(CameraSelectors.selectProperFitting))
          .pipe(
            map(res => {
              const obj = {};
              res?.map(item => {
                obj[item.cameraId] = item;
              });
              return obj;
            })),
      ),
      mergeMap(([, locations, { edgeStatusMap, cameraStatusMap }, properFitting]) => {
          const locationIds = Object.keys(locations);
          const locationStatusLookup: Dictionary<ComponentStatusDisplay> = {};

          locationIds.forEach(locationId => {
            const location = locations[locationId];
            const edges = Object.values(location.edges ?? {});

            const isEdgesAndCamerasAllOnline = edges.every(edge => {
              const cameras = Object.values(edge.cameras ?? {});

              return edgeStatusMap[edge.edgeId] === ComponentStatusDisplay.Online && cameras
                .every(camera => {
                  return cameraStatusMap[camera.edgeOnly.cameraId]?.status === ComponentStatusDisplay.Online;
                });
            });


            if (isEdgesAndCamerasAllOnline) {
              locationStatusLookup[locationId] = ComponentStatusDisplay.Online;
            } else {
              const isEdgesAndCamerasAllOffline = edges.every(edge => {
                const cameras = Object.values(edge.cameras ?? {});
                return edgeStatusMap[edge.edgeId] === ComponentStatusDisplay.Offline && cameras.every(camera => {
                  return cameraStatusMap[camera.edgeOnly.cameraId]?.status === ComponentStatusDisplay.Offline;
                });
              });
              if (isEdgesAndCamerasAllOffline) {
                locationStatusLookup[locationId] = ComponentStatusDisplay.Offline;
              } else {
                locationStatusLookup[locationId] = ComponentStatusDisplay.Unhealthy;
              }
            }


          });

          return of(DeviceStatusActions.setLocationStatus({ locationStatusMap: locationStatusLookup }));
        },
      ),
      share(),
    ),
  );

  constructor(private actions$: Actions, private store$: Store<AppState>) {
  }
}
