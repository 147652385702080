import { Injectable } from '@angular/core';
import { map, Observable, tap, throwError } from 'rxjs';
import { KeyValuePairs, SQSMsgInfo } from '../core/interfaces';
import { api } from '@consts/url.const';
import { HttpClient, HttpContext } from '@angular/common/http';
import { WebRTCPeerControlTypes, WebRTCPeerInterface, WebRTCPeerInterfaceControl, WebRTCPeerInterfaceICE, WebRTCPeerInterfaceSDP, WebRTCPeerStartResponse, WebRTCPeerTurnCredentials, WebRtcPeerType, WebRtcStartRequest } from '@models/webrtc.model';
import { TokenDataStatus } from '../core/messaging.interfaces';
import { doc, docSnapshots, DocumentData, DocumentReference, DocumentSnapshot, Firestore } from '@angular/fire/firestore';
import { BYPASS_AUTHENTICAION_TOKEN } from '../core/authentication-interceptor.service';
import { WebrtcPlayerState } from '../shared/webrtc-v2/webrtc-v2.component';
import * as Ably from 'ably';
import { AblyTopics, AblyTopicsStr } from '@models/ably.model';
import { Store } from '@ngrx/store';
import { WebRtcActiveSessionActions } from '@states/webrtc-active-sessions/webrtc-active-sessions.action-types';

@Injectable({
  providedIn: 'root',
})
export class WebrtcService {

  private activeSessions: KeyValuePairs<WebrtcPlayerState> = {};

  constructor(private http: HttpClient, private firestore: Firestore, private store$: Store) {
  }

  public setActiveSession(edgeId: string, cameraId: string, sessionState: WebrtcPlayerState) {
    this.activeSessions[`${edgeId}:${cameraId}`] = sessionState;
  }

  public getActiveSession(edgeId: string, cameraId: string): WebrtcPlayerState {
    return this.activeSessions[`${edgeId}:${cameraId}`];
  }

  public deleteActiveSession(edgeId: string, cameraId: string) {
    this.cleanActiveSession(edgeId, cameraId, this.activeSessions[`${edgeId}:${cameraId}`]);
    delete this.activeSessions[`${edgeId}:${cameraId}`];
  }

  public start(data: WebRtcStartRequest, authToken?: string): Observable<WebRTCPeerStartResponse> {
    return this.http.post<WebRTCPeerStartResponse>(api.webRtc.peer, data,
      authToken ? {
        context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
        headers: { Authorization: `Bearer ${authToken}` },
      } : undefined,
    );
  }

  public sdp(data: WebRTCPeerInterfaceSDP, authToken?: string): Observable<SQSMsgInfo> {
    return this.http.post<SQSMsgInfo>(api.webRtc.peer, data, authToken ? {
      context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
      headers: { Authorization: `Bearer ${authToken}` },
    } : undefined);
  }


  public ice(data: WebRTCPeerInterfaceICE, authToken?: string): Observable<SQSMsgInfo> {
    return this.http.post<SQSMsgInfo>(api.webRtc.peer, data, authToken ? {
      context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
      headers: { Authorization: `Bearer ${authToken}` },
    } : undefined);
  }

  public control(data: WebRTCPeerInterfaceControl, authToken?: string): Observable<SQSMsgInfo> {
    return this.http.post<SQSMsgInfo>(api.webRtc.peer, data, authToken ? {
      context: new HttpContext().set(BYPASS_AUTHENTICAION_TOKEN, true),
      headers: { Authorization: `Bearer ${authToken}` },
    } : undefined);
  }

  public getCredentials(authToken?: string, cloudflareTurn?: boolean): Observable<RTCConfiguration> {
    let url = api.webRtc.credentials;
    if (cloudflareTurn) {
      url += '?cloudflare=true';
    }
    return this.http.get<RTCConfiguration>(url, {
      params: {
        sharedToken: true,
      },
    });
  }

  public stopSession(edgeId: string, cameraId: string, sessionId: string) {
    const ablyOptions = {
      key: 'E1YILg.eTBPUQ:m4VkVmbMO6ftJBdS4GLcayKXXn8bp8cXj3B-jBkjIDA',
      echoMessages: false,
    };
    const ablyClient = new Ably.Realtime(ablyOptions);
    const ablyChannel = ablyClient.channels.get(edgeId);

    ablyChannel.attach();
    ablyChannel.presence.get();

    const peerData = <WebRTCPeerInterfaceControl>{
      edgeId: edgeId,
      cameraId: cameraId,
      sessionId: sessionId,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.Stop,
    };
    ablyChannel.publish(AblyTopicsStr[AblyTopics.WebRTCPeer], JSON.stringify({ data: peerData, time: new Date().getTime() }));
  }

  public cleanActiveSession(edgeId: string, cameraId: string, activeSessionState: WebrtcPlayerState) {
    this.stopSession(edgeId, cameraId, activeSessionState.sessionId);
    activeSessionState.pc?.close();
  }

  public subscribeToSession(
    token: string,
    throwOnError = true,
    snsMsgError = false,
  ): Observable<{ data: WebRTCPeerInterface; status: TokenDataStatus } | undefined> {
    try {
      const document: DocumentReference = doc(this.firestore, `/tokens/${token}`);

      const snapshot$ = docSnapshots(document)
        .pipe(
          map((doc: DocumentSnapshot<DocumentData>) => {
            const data = doc.data();
            if (!data) {
              return null;
            }
            return data as { data: WebRTCPeerInterface; status: TokenDataStatus };
          }),
          tap(data => {
            if (!snsMsgError) {
              if (throwOnError && data?.status === TokenDataStatus.ERROR) {
                throw new Error('operation result is set to error');
              }
            }
          }),
        );

      return snapshot$;
    } catch (error) {
      return throwError(() => error);
    }
  }

  public cleanAllSessions() {
    const activeSessions = Object.keys(this.activeSessions);
    activeSessions.forEach(key => {
      const edgeId = key.split(':')[0];
      const cameraId = key.split(':')[1];
      this.store$.dispatch(WebRtcActiveSessionActions.deleteActiveSession({ edgeId, cameraId }));
    });
    // this.activeSessions = {};
  }

  public cleanExcept(edgeCameraIds: { edgeId: string; cameraId: string }[]) {
    const keys = edgeCameraIds.map(item => {
      return `${item.edgeId}:${item.cameraId}`;
    });
    const existingSessions = Object.keys(this.activeSessions);
    existingSessions.forEach(sessionKey => {
      if (!keys.includes(sessionKey)) {
        console.info('CLEAN SESSION', this.activeSessions[sessionKey]);
        const edgeId = sessionKey.split(':')[0];
        const cameraId = sessionKey.split(':')[1];
        this.store$.dispatch(WebRtcActiveSessionActions.deleteActiveSession({ edgeId, cameraId }));
      }
    });
  }

  public testWSPlayback() {
    const request = {
      edgeId: '664a470acec44a1247646db2',
      data: {
        sqsMessageType: 24,
        data: '{"edgeId":"664a470acec44a1247646db2","url":"https://play-dev.lumix.ai","apiKey":"123","cameraId":"665c724fc7dcdf474dc8ebd7","sessionId":"32609763-f705-4572-80f2-ed642107b5e1","action":"stream_ws_connect","storage":1,"duration":60,"timestamp":1717342380,"filename":"1111.mp4","exitAfterDone":true}',
        token: {
          session: '77090aab-b1b6-404a-8fe9-de0c416cfea2',
          mqtt: true,
          topic: 24,
          watcher: false,
        },
      },
    };

    return this.http.post(api.edgePlayback.test, request);
  }

}
