import { StoreModule } from '@ngrx/store';
import { reducers } from './app.state';
import { metaReducers } from './app.meta-reducers';
import { extModules } from './build-specifics';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { AnalyticsEffects } from '@effects/analytic.effect';
import { OrganizationEffect } from '@effects/organization.effect';
import { SharedEffects } from '@effects/shared.effects';
import { AlertEventsEffect } from '@effects/alert-events.effect';
import { SearchConfigurationEffect } from '@effects/search-configuration.effect';
import { AlertMonitoringEffect } from '@effects/alert-monitoring.effect';
import { EdgeEditEffects } from '@effects/edge-edit.effects';
import { ArchiveEffect } from '@effects/archive.effect';
import { GrantedAccessEffect } from '@effects/granted-access.effect';
import { StatsEffects } from '@effects/stats.effects';
import { UserSettingsEffect } from '@effects/user-settings.effect';
import { TerminalEffect } from '@effects/terminal.effect';
import { GeneralEffect } from '@effects/general.effects';
import { ActivityLogEffect } from '@effects/activity-log.effect';
import { DevTeamEffect } from '@effects/dev-team.effect';
import { VariableEffect } from '@effects/variable.effect';
import { WorkspaceEffects } from '@effects/workspace.effects';
import { CameraEffect } from '@effects/camera.effect';
import { MultiSearchEffect } from '@effects/multi-search.effect';
import { WebRtcEffect } from '@effects/webrtc.effect';
import { NotificationEffects } from '@effects/notification.effects';
import { MultiPlaybackEffect } from '@effects/multi-playback.effect';
import { PricingEffect } from '@effects/pricing.effect';
import { AlertsV2Effect } from '@effects/alerts-v2.effect';
import { PeopleEffect } from '@effects/people.effect';
import { LocationEditEffects } from '@effects/location-edit.effects';
import { OperatorEffects } from '@effects/operator.effects';
import { StorageEffects } from '@effects/storage.effects';
import { AlertLogEffect } from '@effects/alert-log.effect';
import { DoorsEffect } from '@effects/doors.effect';
import { CustomEventsEffect } from '@effects/custom-events.effect';
import { ApiKeyEffect } from '@effects/api-key.effect';
import { UserGroupsEffect } from '@effects/user-groups.effect';
import { VehiclesEffect } from '@effects/vehicles.effect';
import { PermissionGroupsEffect } from '@effects/permission-groups.effect';
import { OrgGroupsEffect } from '@effects/org-groups.effect';
import { DashboardEffects } from '@effects/dashboard.effects';
import { DeviceStatusEffects } from '@effects/device-status.effects';
import { AuthenticationEffects } from '@effects/authentication.effects';
import { UserEffects } from '@effects/user.effects';
import { LocationEffects } from '@effects/location.effects';
import { EdgeEffects } from '@effects/edge.effects';
import { CameraEffects } from '@effects/camera.effects';
import { IdentityEffect } from '@effects/identity.effect';
import { AppleTvEffects } from '@effects/apple-tv.effects';
import { UserDeviceEffects } from '@effects/user-device.effects';
import { IntegrationsEffect } from '@effects/integrations.effect';
import { MfaEffects } from '@effects/mfa.effects';
import { WebhookEffects } from '@effects/webhook.effects';
import { FloorEffects } from '@effects/floor.effects';
import { DhcpEffects } from '@effects/dhcp.effects';
import { ExternalStorageEffects } from '@effects/external-storage.effects';
import { WallV2Effects } from '@effects/wall-v2.effects';
import { YoutubeEffects } from '@effects/youtube.effects';
import { AccessDoorsEffects } from '@effects/access-doors.effects';
import { CameraSettingsEffect } from '@effects/camera-settings.effect';
import { HomeEffects } from '@effects/home.effects';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: false,
        strictActionSerializability: false,
        strictStateSerializability: true,
      },
    }),
    extModules,
    EffectsModule.forRoot([
      AuthenticationEffects,
      UserEffects,
      LocationEffects,
      EdgeEffects,
      CameraEffects,
      AnalyticsEffects,
      OrganizationEffect,
      SharedEffects,
      AlertEventsEffect,
      AlertsV2Effect,
      VariableEffect,
      WebRtcEffect,
      PricingEffect,
      MultiSearchEffect,
      MultiPlaybackEffect,
      CameraEffect,
      SearchConfigurationEffect,
      AlertMonitoringEffect,
      EdgeEditEffects,
      ArchiveEffect,
      GeneralEffect,
      GrantedAccessEffect,
      StatsEffects,
      UserSettingsEffect,
      TerminalEffect,
      ActivityLogEffect,
      DevTeamEffect,
      WorkspaceEffects,
      NotificationEffects,
      PeopleEffect,
      LocationEditEffects,
      OperatorEffects,
      StorageEffects,
      AlertLogEffect,
      DoorsEffect,
      VehiclesEffect,
      CustomEventsEffect,
      ApiKeyEffect,
      UserGroupsEffect,
      OrgGroupsEffect,
      PermissionGroupsEffect,
      DashboardEffects,
      DeviceStatusEffects,
      IdentityEffect,
      AppleTvEffects,
      UserDeviceEffects,
      IntegrationsEffect,
      MfaEffects,
      WebhookEffects,
      FloorEffects,
      DhcpEffects,
      WallV2Effects,
      ExternalStorageEffects,
      YoutubeEffects,
      AccessDoorsEffects,
      CameraSettingsEffect,
      HomeEffects,
    ]),
  ],
})
export class AppStoreModule {
}
