import { createAction, props } from '@ngrx/store';
import { ActivityDailyEventGroup, ActivityLog } from '@models/activity-log';
import { UserOrganizationDropDown } from '@models/organization.model';
import { ActivityLogFilterName } from '@enums/activity-log.enum';
import { OnRangeSelectedResult } from '../../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';

export const resetToInitialState = createAction('[ACTIVITY-LOG] Reset to Initial state');

export const resetToInitialStateWithoutFilters = createAction('[ACTIVITY-LOG] Reset to Initial state without filters');

export const downloadActivityLogCsv = createAction('[ACTIVITY-LOG] Download CSV');

export const getActivityLog = createAction('[ACTIVITY-LOG] Get Activity Log');

export const getActivityLogSuccess = createAction('[ACTIVITY-LOG] Get Activity Log Success', props<{ activityLogs: ActivityLog[] }>());

export const nextPage = createAction('[ACTIVITY-LOG] Next Page');

export const setDateRangeFilter = createAction('[ACTIVITY-LOG] Set Date Range Filter', props<{ dateRange: OnRangeSelectedResult }>());

export const setFilter = createAction('[ACTIVITY-LOG] Set Filter', props<{ filter: string; value: any }>());

export const setActivityReportFilter = createAction('[ACTIVITY-REPORT] Set Filter', props<{ filter: string; value: any }>());

export const getOrgUsers = createAction('[ACTIVITY-LOG] Get Org Users');

export const getOrgUsersSuccess = createAction('[ACTIVITY-LOG] Get Org Users Success', props<{ users: UserOrganizationDropDown[] }>());

export const removeFilter = createAction('[ACTIVITY-LOG] Remove Filter', props<{ filter: ActivityLogFilterName; id: any }>());
export const removeActivityReportFilter = createAction('[ACTIVITY-REPORT] Remove Filter', props<{ filter: ActivityLogFilterName; id: any }>());

export const refreshDateFilter = createAction('[ACTIVITY-LOG] Refresh Date Filter');

export const refreshActivityReportDateFilter = createAction('[ACTIVITY-REPORT] Refresh Date Filter');

export const findAndGroupByDailyEvents = createAction('[ACTIVITY-LOG] Find And Group By Daily Events');
export const findAndGroupByDailyEventsSuccess = createAction('[ACTIVITY-LOG] Find And Group By Daily Events Success', props<{ groupedLogs: { [key: string]: ActivityDailyEventGroup[] } }>());
export const findAndGroupByDailyEventsFail = createAction('[ACTIVITY-LOG] Find And Group By Daily Events Fail');
export const generateAuditReportPdfReport = createAction('[ACTIVITY-LOG] Generate Audit Report Pdf report');
export const generateAuditLogPdfReport = createAction('[ACTIVITY-LOG] Generate Audit Log Pdf report');
