import { CameraLookup } from '@models/camera.model';
import { AlertEventTrafficControl } from '@models/alert-events.model';

export namespace AccessDoorsModel {

  export interface CreateDocumentDto {
    locationId: string;
    locationName?: string;
    doorId?: string;
    doorName?: string;
    cameras: CameraLookup[];
    sourceType: SourceType;
  }

  export interface DocumentBase extends CreateDocumentDto {
    updatedAt: number;
    createdAt: number;
    orgId: string;
  }

  export interface DocumentMongo extends DocumentBase {
    _id: string;
  }

  export interface DocumentCore extends DocumentMongo {
    lineCrossing?: AlertEventTrafficControl;
  }

  export interface Location {
    locationId: string;
    locationName?: string;
    doors?: DocumentCore[];
  }

  export interface DoorEventListItem {
    cameraId: string;
    edgeId: string;
    eventId: string;
    name: string;
  }

  export interface DoorQuery {
    sourceType: SourceType;
  }
  export enum State {
    OPEN = 'open',
    CLOSED = 'closed',
  }

  export enum SourceType {
    Genea,
    Kisi
  }
}
