<ng-container *ngIf="doors?.length">
  <div class="ptt-wrapper flex-row start-center">
    <div [matTooltip]="!opened ? 'Open door' : 'Close door'"
         class="microphone-wrapper p-r flex-row center-center"
         (click)="openDoor()"
    >
      <img *ngIf="!opened" src="assets/icons/door-lock.svg"/>
      <img *ngIf="opened" src="assets/icons/door-unlock.svg"/>
    </div>
    <div *ngIf="doors?.length > 1">
      <mat-select class="speaker-selector"
                  [(ngModel)]="selectedDoor"
                  [compareWith]="doorComparator"
                  (ngModelChange)="getDoorState()"
                  panelWidth="250" panelClass="resolution-selector-panel"
      >
        <mat-option *ngFor="let door of doors" [value]="door">{{ door.doorName }}</mat-option>
      </mat-select>
    </div>
  </div>
</ng-container>
