import { createAction, props } from '@ngrx/store';
import { ActiveOrganization, IpRules, Organization, OrganizationUser, UserOrganizationDropDown } from '@models/organization.model';
import { InviteType } from '@enums/organization.enum';
import { SortDirection } from '@angular/material/sort';
import { AppUser } from '../../../user/user.model';
import User = AppUser.User;

export const resetToInitialState = createAction('[Organization] Reset to Initial state');

export const getActiveOrganization = createAction('[Organization] Get Active Organization');

export const setActiveOrganization = createAction(
  '[Organization] Set Active Organization',
  props<{ activeOrganization: ActiveOrganization }>(),
);

export const updateActiveOrganizationSuccess = createAction(
  '[Organization] Update Active Organization Success',
  props<{ activeOrganization: Partial<ActiveOrganization> }>(),
);

export const setIsDefaultOrganization = createAction(
  '[Organization] Set Is Default Organization',
  props<{ isDefaultOrganization: boolean }>(),
);

/**
 * @deprecated use organization users state instead
 */
export const setOrganizationUsers = createAction('[Organization] Get Organization User', props<{ users: OrganizationUser[] }>());

export const saveOrganization = createAction('[Organization] Save Organization', props<{ orgName: string; orgEmail: string }>());

export const sendOrganization = createAction('[Organization] Send Organization');

export const saveOrganizationSuccess = createAction(
  '[Organization] Save Organization Success',
  props<{ orgName: string; orgId: string }>(),
);

export const getOrganizationUsers = createAction('[Organization] Get Organization Users');
export const getOrganizationUsersFail = createAction('[Organization] Get Organization Users Fail');

export const setPaginationParams = createAction('[Organization] Set Pagination Params', props<{ perPage: number; page: number }>());
/**
 * @deprecated use organization users state instead
 */
export const setTotalItemsCount = createAction('[Organization] Set Total Items Count', props<{ totalItemsCount: number }>());

export const updateActiveOrganization = createAction('[Organization] Update Active Organization', props<{ organization: Partial<ActiveOrganization> }>());

export const updateOrganizationServer = createAction('[Organization] Update Active Organization Server', props<{ toUpdate: Partial<ActiveOrganization> }>());

export const switchTenant = createAction('[Organization] Switch Tenant', props<{ tenantId: string }>());

export const getUserOrganizations = createAction('[Organization] Get User Organization');

export const getUserOrganizationsSuccess = createAction(
  '[Organization] Get User Organization Success',
  props<{ organizations: UserOrganizationDropDown[] }>(),
);

export const blockUser = createAction('[Organization] Block User', props<{ userId: string }>());

export const blockUserSuccess = createAction('[Organization] Block User Success');

export const removeUser = createAction('[Organization] Remove User', props<{ userId: string }>());

export const removeUserSuccess = createAction('[Organization] Remove User Success', props<{ userId: string }>());

export const setOrderParams = createAction(
  '[Organization] Set Order Params',
  props<{
    orderBy: string;
    orderDirection: SortDirection;
  }>(),
);

export const setQuery = createAction(
  '[Organization] Set Query',
  props<{
    query: string;
  }>(),
);


export const updateOrganizationLogo = createAction(
  '[Organization] Update Organization Logo',
  props<{
    file: Blob;
    orgId: string;
  }>(),
);

export const checkIfOrgNameExist = createAction(
  '[Organization] Check if Org Name Exist',
  props<{
    name: string;
  }>(),
);

export const checkIfOrgEmailExist = createAction(
  '[Organization] Check if Org Email Exist',
  props<{
    email: string;
  }>(),
);

export const checkIfOrgEmailExistOnServer = createAction(
  '[Organization] Check if Org Email Exist On Server',
  props<{
    email: string;
  }>(),
);

export const checkIfOrgNameExistOnServer = createAction(
  '[Organization] Check if Org Name Exist On Server',
  props<{
    name: string;
  }>(),
);

export const setOrganizationNameExist = createAction(
  '[Organization] Set Organization Name Exist',
  props<{
    organizationNameExist: boolean;
  }>(),
);

export const setOrganizationEmailExist = createAction(
  '[Organization] Check if Org Email Exist On Server',
  props<{
    organizationEmailExist: boolean;
  }>(),
);

export const resetCreatedOrganizationLocally = createAction('[Organization] Reset Created Organization Locally');

export const saveCreatedOrganizationLocally = createAction(
  '[Organization] Save Created Organization Locally',
  props<{
    createdOrganization: { orgName: string; orgEmail: string };
  }>(),
);

export const saveCreatedOrganizationInvitedUsers = createAction(
  '[Organization] Save Created Organization Invited Users',
  props<{
    invites: { [key: string]: string }[];
  }>(),
);

export const createOrganization = createAction('[Organization] Create Organization on Server');

export const deleteOrg = createAction(
  '[Organization] Delete',
  props<{
    orgId: string;
  }>(),
);

export const leaveOrg = createAction(
  '[Organization] Leave org',
  props<{
    orgId: string;
  }>(),
);

export const checkIfUserSigned = createAction('[Organization] Check If User Signed', props<{ inviteId: string }>());

export const checkIfUserSignedSuccess = createAction(
  '[Organization] Check If User Signed Success',
  props<{ user: User; email: string; orgId: string }>(),
);

export const emitJoinActiveOrganizationEvent = createAction(
  '[Organization] Emit Join Active Organization Event',
  props<{ activeOrganization: Partial<ActiveOrganization> }>(),
);

export const emitLeaveActiveOrganizationEvent = createAction(
  '[Organization] Emit Leave Active Organization Event',
  props<{ activeOrganization: { orgId: string } }>(),
);

export const checkIfUserSignedError = createAction(
  '[Organization] Check If User Signed Error',
);

export const getOrganizationMaxRetentionDays = createAction('[Organization] Get Organization Max Retention Days');
export const getOrganizationMaxRetentionDaysSuccess = createAction('[Organization] Get Organization Max Retention Days Success', props<{
  maxRetentionDays: number
}>());

export const getOrganizationMaxRetentionDaysFail = createAction('[Organization] Get Organization Max Retention Days Fail');
export const pressSave = createAction('[Organization setting] press save');
export const pressDelete = createAction('[Organization setting] press delete');

export const getLoginFirewall = createAction('[Organization setting] get login firewall');

export const loginFirewallSaveBeforeSave = createAction('[Organization setting] login firewall before save', props<{ data: IpRules }>());
export const loginFirewallSave = createAction('[Organization setting] login firewall save', props<{ data: IpRules }>());
export const loginFirewallSaveSuccess = createAction('[Organization setting] login firewall save success', props<{ data: IpRules }>());
export const loginFirewallSaveFail = createAction('[Organization setting] login firewall save fail');


export const setOrganizationNameCheckLoader = createAction(
  '[Organization] Set Organization Name Loader',
  props<{
    loading: boolean;
  }>(),
);


export const setOrganizationGlobalLoader = createAction(
  '[Organization] Set Organization Global Loader',
  props<{
    loading: boolean;
  }>(),
);
