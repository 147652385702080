import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { ActivityLogFilterRequest } from '@models/activity-log';

@Injectable()
export class ActivityLogService {
  constructor(private http: HttpClient) {
  }

  public get(limit: number, filters?: ActivityLogFilterRequest): Observable<any[]> {
    return this.http.post<any>(`${api.activityLog.findByFilter}?limit=${limit}`, filters);
  }

  public downloadCsv(filters?: ActivityLogFilterRequest): Observable<Blob> {
    return this.http.post(`${api.activityLog.exportCsv}`, filters, {
      responseType: 'blob'
    });
  }


  public findAndGroupByDailyEvents(limit: number, filters?: ActivityLogFilterRequest): Observable<any[]> {
    return this.http.post<any>(`${api.activityLog.findAndGroupByDailyEvents}?limit=${limit}`, filters);
  }

  public generateAuditReportPdfReport(): Observable<any[]> {
    return this.http.post<any>(`${api.activityLog.auditReportPdf}`, {});
  }

  public generateAuditLogPdfReport(): Observable<any[]> {
    return this.http.post<any>(`${api.activityLog.auditLogPdf}`, {});
  }

}
