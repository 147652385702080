import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';
import { HomeModel } from '@models/home.model';
import { FavoritesModel } from '@models/favorites.model';

@Injectable({
  providedIn: 'root',
})
export class HomeService {

  constructor(private http: HttpClient) {
  }

  getHomeLocations(): Observable<HomeModel.Location[]> {
    return this.http.get<HomeModel.Location[]>(api.locations.home);
  }

  getFavorites(): Observable<FavoritesModel.Favorites> {
    return this.http.get<FavoritesModel.Favorites>(api.favorites.crud);
  }

  starLocation(locationId: string): Observable<boolean> {
    const request: FavoritesModel.FavoriteLocationRequest = {
      locationId,
    };
    return this.http.post<boolean>(api.favorites.location, request);
  }

  unstarLocation(locationId: string): Observable<ArrayBuffer> {
    return this.http.delete<ArrayBuffer>(`${api.favorites.location}/${locationId}`);
  }

  addSubLocation(subLocation: HomeModel.SubLocationCreateRequest): Observable<HomeModel.Location> {
    return this.http.post<HomeModel.Location>(api.locations.sublocation, subLocation);
  }

  addCameraToSubLocation(request: HomeModel.AddCameraToSubLocationRequest): Observable<any> {
    return this.http.post<any>(api.locations.sublocationCamera, request);
  }

  addCamerasToSubLocation(request: HomeModel.AddCamerasToSubLocationRequest): Observable<any> {
    return this.http.post<any>(api.locations.sublocationCameras, request);
  }

  removeCameraFromSubLocation(request: HomeModel.RemoveCameraFromSubLocationRequest): Observable<any> {
    const url = `${api.locations.sublocationCamera}/${request.subLocationId}/${request.cameraId}`;
    return this.http.delete<any>(url);
  }

  deleteSubLocation(request: HomeModel.RemoveSubLocationRequest): Observable<any> {
    const url = `${api.locations.sublocation}/${request.subLocationId}`;
    return this.http.delete<any>(url);
  }

  updateSubLocation(request: HomeModel.UpdateSubLocationRequest): Observable<any> {
    const url = `${api.locations.sublocation}`;
    return this.http.patch<any>(url, request);
  }
}
