import { AlertsV2DisplaySettings, AlertsV2PicInPicPosition, AlertV2Document } from '@models/alerts-v2.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { AudioList } from '@models/shared.model';
import { LiveStreamModels } from '@models/live-stream.model';
import { HttpStatusCode } from '@angular/common/http';
import { LocationModel } from '../../locations/location.model';
import { AlertEntry } from '../../development/alerts.service';
import { YoutubeModel } from '@models/youtube.model';

export namespace WallV2Model {

  export const PRELOAD_SET_TIME_SECONDS = 7;

  export interface WallDocumentBase {
    name: string;
    settings: WallSettings;
    sets: WallSet[];
    orgId: string;
    createdAt: number;
    updatedAt: number;
    appleTvId?: string;
    userId: string;
  }

  export interface WallCreateDto {
    name: string;
    settings: WallSettings;
    sets: WallSet[];
  }

  export interface WallMongoDocument extends WallDocumentBase {
    _id: string;
  }

  export interface WallSettings {
    isPrivate: boolean;
    tileSettings: TilePreviewSettings;
    alertSettings: AlertSettings;
  }

  export interface TilePreviewSettings {
    showCameraName: boolean;
    showCameraStatus: boolean;
    showTimestamp: boolean;
    showTileNumber: boolean;
    resolution?: LiveStreamModels.StreamResolution;
  }

  export interface WallSet {
    name: string;
    layout: WallLayout;
    duration: number; //in seconds
    tiles: SetTile[];
  }

  export interface SetTile {
    camera: SelectedCamera;
    events: SelectedEvent[];
    allEvents: boolean;
    youtubeId: string;
  }

  export interface SelectedCamera {
    cameraId: string;
    edgeId: string;
    locationId: string;
  }

  export interface SelectedEvent {
    cameraId: string;
    edgeId: string;
    locationId: string;
    eventId: string;
  }

  export interface AlertSettings {
    display: AlertsV2DisplaySettings;
    picInPic: AlertsV2DisplaySettings;
    picInPicPos: AlertsV2PicInPicPosition;
    duration: number;
    audio: AudioList;
    volume: number;
  }

  export interface WallPlaybackSession extends LiveStreamModels.StartHlsPlaybackResponse {
    edgeId: string;
    cameraId: string;
    videoCurrentTimestamp: number;
  }

  export interface WallPlaybackStartDto {
    locationId: string,
    edgeId: string,
    cameraId: string,
    timestamp: number,
    alertId: string,
    sessionId: string;
  }

  export interface WallPlaybackError {
    error: WallPlaybackErrorResponse,
    playbackDto: WallV2Model.WallPlaybackStartDto
  }

  export interface WallPlaybackErrorResponse {
    status: HttpStatusCode,
    message: string
  }

  export enum WallLayout {
    AUTOMATIC,
    BY_LOCATION,
    DYNAMIC,
    GRID_9,
    GRID_6,
    GRID_4,
    GRID_3,
    GRID_2,
    GRID_1,
    GRID_6_VERTICAL,
    GRID_8_VERTICAL,
    GRID_4_1_3,
    GRID_5_1_4,
    GRID_6_1_5,
    GRID_6_2_4,
    GRID_7_3_4,
    GRID_7_1_6,
    GRID_8,
    GRID_9_1_8,
    GRID_10_2_8,
    GRID_10_2_8_v,
    GRID_12_1_11,
    GRID_12_12,
    GRID_13_1_center,
    GRID_13_1_12,
    GRID_16,
    GRID_16_1_15,
    GRID_20,
    GRID_20_2_18,
    GRID_25,
    GRID_30,
    GRID_36,
    GRID_48,
    GRID_14_2_12,
    GRID_15_1_14,
    GRID_16_2_14
  }

  export interface QueryFilter {
    query?: string,
    isPrivate?: string,
    cameras?: string
  }


  export const wallLayoutCameraCountV2: { [Property in WallLayout]: number } = {
    [WallLayout.GRID_1]: 1,
    [WallLayout.GRID_2]: 2,
    [WallLayout.GRID_3]: 3,
    [WallLayout.GRID_4]: 4,
    [WallLayout.GRID_4_1_3]: 4,
    [WallLayout.GRID_5_1_4]: 5,
    [WallLayout.GRID_6_1_5]: 6,
    [WallLayout.GRID_6_2_4]: 6,
    [WallLayout.GRID_6_VERTICAL]: 6,
    [WallLayout.GRID_6]: 6,
    [WallLayout.GRID_7_1_6]: 7,
    [WallLayout.GRID_7_3_4]: 7,
    [WallLayout.GRID_8]: 8,
    [WallLayout.GRID_8_VERTICAL]: 8,
    [WallLayout.GRID_9_1_8]: 9,
    [WallLayout.GRID_9]: 9,
    [WallLayout.GRID_10_2_8]: 10,
    [WallLayout.GRID_10_2_8_v]: 10,
    [WallLayout.GRID_12_1_11]: 12,
    [WallLayout.GRID_12_12]: 12,
    [WallLayout.GRID_13_1_center]: 13,
    [WallLayout.GRID_13_1_12]: 13,
    [WallLayout.GRID_16]: 16,
    [WallLayout.GRID_16_1_15]: 16,
    [WallLayout.GRID_20]: 20,
    [WallLayout.GRID_20_2_18]: 20,
    [WallLayout.GRID_25]: 25,
    [WallLayout.GRID_30]: 30,
    [WallLayout.GRID_36]: 36,
    [WallLayout.GRID_48]: 48,
    [WallLayout.GRID_14_2_12]: 14,
    [WallLayout.GRID_15_1_14]: 15,
    [WallLayout.GRID_16_2_14]: 16,
    0: 0,
    1: 0,
    2: 0,
  };

  export const defaultLayout = WallLayout.GRID_9;

  export const defaultSetTile = {
    camera: null,
    events: null,
    allEvents: false,
    youtubeId: null,
  };

  export const defaultSet: WallSet = {
    name: 'Set 1',
    layout: defaultLayout,
    duration: 0,
    tiles: new Array(wallLayoutCameraCountV2[defaultLayout]).fill(defaultSetTile),
  };

  export const defaultAlertSettings: AlertSettings = {
    display: AlertsV2DisplaySettings.LIVE,
    duration: 30000,
    picInPic: AlertsV2DisplaySettings.TIMELAPSE,
    picInPicPos: AlertsV2PicInPicPosition.BOTTOM_RIGHT,
    audio: AudioList.Announcement,
    volume: 100,
  };

  const defaultTilePreviewSettings: TilePreviewSettings = {
    showCameraName: true,
    showCameraStatus: true,
    showTileNumber: true,
    showTimestamp: true,
    resolution: LiveStreamModels.StreamResolution.AUTO,
  };


  const defaultWallSettings: WallSettings = {
    isPrivate: false,
    tileSettings: defaultTilePreviewSettings,
    alertSettings: defaultAlertSettings,
  };

  export const defaultWallCreate: WallV2Model.WallCreateDto = {
    name: '',
    settings: defaultWallSettings,
    sets: [defaultSet],
  };

  export const setRotationDurationOptions: number[] = [
    0, 10000, 30000, 60000, 120000, 300000,
  ];

  export const setRotationDurationStr: { [key: number]: string } = {
    [0]: 'None',
    [10000]: '10 seconds',
    [30000]: '30 seconds',
    [60000]: '1 Minute',
    [120000]: '2 Minutes',
    [300000]: '5 Minutes',
  };

  export enum WallCdkDropDataType {
    Camera,
    Alert,
    Youtube,
    DragTile
  }

  export interface WallTileCdkDropData {
    type: WallCdkDropDataType;
    events?: AlertV2Document[],
    singleEvent?: AlertV2Document,
    camera?: LocationModel.LocationCameraItem,
    isAllSelected?: boolean
    youtube?: YoutubeModel.YoutubeMongoDocument;
    index: number
  }

  export interface OnCameraSelectedData {
    camera: LocationModel.LocationCameraItem,
    tile: number
  }

  export interface OnEventSelectedData {
    tile: number,
    events: Dictionary<AlertV2Document>,
    singleEvent: AlertV2Document,
    isAllSelected: boolean
  }

  export interface OnYoutubeSelectedData {
    tile: number,
    youtube: YoutubeModel.YoutubeMongoDocument
  }

  export interface OnTileDrag {
    tile: number,
    fromTile: number
  }

  export const checkIfSetsEmpty = (sets: WallV2Model.WallSet[]): boolean => {
    const isSetsNotEmpty = sets.some(set => {
      return set.tiles.some(tile => tile.camera || tile.events?.length || tile.allEvents);
    });
    return !isSetsNotEmpty;
  };

  export enum WallConfirmModalType {
    DeleteWall,
    ChangeLayout,
    DeleteSet,
    SaveQuickLiveViewAsWall,
    DiscardEditWall
  }

  export const cameraQuantityLayout = {
    1: WallLayout.GRID_1,
    2: WallLayout.GRID_2,
    3: WallLayout.GRID_3,
    4: WallLayout.GRID_4,
    5: WallLayout.GRID_5_1_4,
    6: WallLayout.GRID_6,
    7: WallLayout.GRID_7_3_4,
    8: WallLayout.GRID_8,
    9: WallLayout.GRID_9,
    10: WallLayout.GRID_10_2_8,
    12: WallLayout.GRID_12_12,
    13: WallLayout.GRID_13_1_center,
    14: WallLayout.GRID_14_2_12,
    15: WallLayout.GRID_15_1_14,
    16: WallLayout.GRID_16,
    20: WallLayout.GRID_20,
    30: WallLayout.GRID_30,
  };

  export enum TileAlertColors {
    sky500 = '#0EA5E9',
    Amber500 = '#F59E0B',
    Green500 = '#22C55E',
    Purple500 = '#A855F7',
    Red500 = '#EF4444',
    Primary500 = '#4367F6',
    Pink500 = '#EC4899',
    Indigo500 = '#6366F1',
    Teal500 = '#14B8A6',
  }

  export const colorValues = Object.values(TileAlertColors);

  export interface WallAlert extends AlertEntry {
    tile: number;
    tileColor: TileAlertColors;
    selectedAt?: number;
  }

  export interface WallAlertMonitoringFilters {
    frequency: number;
    eventCameraIds: { eventId: string, cameraId: string }[];
  }

  export interface WallCamera extends SelectedCamera {
    ready?: boolean;
    err?: boolean;
  }

  export const initialAlertsMonitoringFilters: WallAlertMonitoringFilters = {
    frequency: 5,//mins
    eventCameraIds: [],
  };
}
