import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, concat, map, mergeMap, of, share, switchMap, withLatestFrom } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { IntegrationsService } from '../../development/integrations.service';
import { IntegrationsActions } from '@states/integrations/integrations.action-types';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';

@Injectable()
export class IntegrationsEffect {
  public getIntegrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.getIntegrations),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { activeOrganization }]) => {

        return this.integrationsService
          .getIntegrations(activeOrganization.orgId)
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrationsSuccess({ integrations: res }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: JSON.stringify(err) })]),
          );
      }),
      share(),
    ),
  );

  public getIntegrationDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.getIntegrationDetails),
      withLatestFrom(this.store$.pipe(select(OrganizationSelectors.selectOrgId))),
      switchMap(([, orgId]) => {
        return this.integrationsService
          .getIntegrationsDetails(orgId)
          .pipe(
            map(res => IntegrationsActions.getIntegrationDetailsSuccess({ details: res })),
            catchError(err => of(SharedActions.showMessage({ error: JSON.stringify(err) }))),
          );
      }),
    ),
  );

  public updateIntegrationsQuota$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.updateIntegrationsQuota),
      mergeMap(({ noonLightQuota, lumanaQuota,vccQuota }) => {
        return concat(
          of(SharedActions.setIsSaving({ isSaving: true })),
          this.integrationsService
            .updateIntegrationsQuota(noonLightQuota, lumanaQuota,vccQuota)
            .pipe(
              mergeMap(res => [
                IntegrationsActions.updateIntegrationsQuotaSuccess({ details: res }),
                SharedActions.showMessage({
                  success: 'Organization quotas has been updated',
                }),
                SharedActions.setIsSaving({ isSaving: false }),
              ]),
              catchError(err => {
                console.error('API error:', err);
                return of(
                  SharedActions.showMessage({ error: JSON.stringify(err) }),
                  SharedActions.setIsSaving({ isSaving: false }),
                );
              }),
            ),
        );
      }),
    ),
  );


  public deactivateSlack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.deactivateSlack),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { activeOrganization }]) => {

        return this.integrationsService
          .deactivateSlack(activeOrganization.orgId)
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'Slack uninstalled successfully' }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: err })]),
          );
      }),
      share(),
    ),
  );

  public uninstallTeam3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.uninstallTeam3),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { activeOrganization }]) => {

        return this.integrationsService
          .uninstallTeam3(activeOrganization.orgId)
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'Team3 uninstalled successfully' }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: err })]),
          );
      }),
      share(),
    ),
  );

  public installTeam3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.installTeam3),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { activeOrganization }]) => {

        return this.integrationsService
          .installTeam3(activeOrganization.orgId)
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'Team3 installed successfully' }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: err })]),
          );
      }),
      share(),
    ),
  );

  public installNoonLight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.installNoonLight),
      switchMap((action) => {
        return this.integrationsService
          .installNoonLight({ token: action.token, quota: action.quota })
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'NoonLight installed successfully' }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: err })]),
          );
      }),
      share(),
    ),
  );

  public installLumana$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.installLumana),
      switchMap((action) => {
        return this.integrationsService
          .installLumana({ quota: action.quota })
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'Lumana installed successfully' }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: err })]),
          );
      }),
      share(),
    ),
  );

  public uninstallNoonLight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.uninstallNoonLight),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { activeOrganization }]) => {
        return this.integrationsService
          .uninstallNoonLight()
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'NoonLight uninstalled successfully' }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: err })]),
          );
      }),
      share(),
    ),
  );


  public uninstallLumana$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.uninstallLumana),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { activeOrganization }]) => {
        return this.integrationsService
          .uninstallLumana()
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'Lumana uninstalled successfully' }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: JSON.parse(err) })]),
          );
      }),
      share(),
    ),
  );

  public uninstallGenea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.uninstallGenea),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { activeOrganization }]) => {

        return this.integrationsService
          .uninstallGenea(activeOrganization.orgId)
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'Genea uninstalled successfully' }),
                IntegrationsActions.uninstallGeneaSuccess(),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: err })]),
          );
      }),
      share(),
    ),
  );

  public installGenea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.installGenea),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([{ apiKey }, { activeOrganization }]) => {

        return this.integrationsService
          .installGenea(activeOrganization.orgId, apiKey)
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'Genea installed successfully' }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: 'Error: Invalid API Key' })]),
          );
      }),
      share(),
    ),
  );

  public uninstallKisi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.uninstallKisi),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([, { activeOrganization }]) => {

        return this.integrationsService
          .uninstallKisi(activeOrganization.orgId)
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'Kisi uninstalled successfully' }),
                IntegrationsActions.uninstallKisiSuccess(),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: err })]),
          );
      }),
      share(),
    ),
  );

  public installKisi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationsActions.installKisi),
      withLatestFrom(this.store$.pipe(select(state => state.organizationState))),
      switchMap(([{ apiKey }, { activeOrganization }]) => {

        return this.integrationsService
          .installKisi(activeOrganization.orgId, apiKey)
          .pipe(
            switchMap(res => {
              return [
                IntegrationsActions.getIntegrations(),
                SharedActions.showMessage({ success: 'Kisi installed successfully' }),
              ];
            }),
            catchError(err => [SharedActions.showMessage({ error: 'Error: Invalid API key' })]),
          );
      }),
      share(),
    ),
  );


  constructor(private actions$: Actions, private store$: Store<AppState>, private integrationsService: IntegrationsService,
  ) {
  }
}
