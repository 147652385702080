import { createReducer, on } from '@ngrx/store';
import * as ArchiveActions from '@states/archive/archive.actions';
import { AddArchiveModalConfig, ArchiveModel, ArchiveResponseFilter } from '@models/archive.model';
import { initialArchive, initialArchiveFilter } from '@consts/archive.conts';
import { SortDirection } from '@angular/material/sort';
import { Archive } from '@models/archive.model';
import { UserOrganizationDropDown } from '@models/organization.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Dictionary } from '@ngrx/entity/src/models';

export interface ArchiveState extends EntityState<Archive.ArchiveDocument> {
  selectedArchive: ArchiveModel;
  isArchiveValid: boolean;
  page: number;
  perPage: number;
  orderBy: string;
  orderDirection: SortDirection;
  filters: ArchiveResponseFilter;
  userAutocomplete: UserOrganizationDropDown[];
  addArchiveModalConfig: AddArchiveModalConfig;
  isLastPage: boolean;
  isInitialLoaded: boolean;
  isLoading: boolean;
  downloadProgress: Dictionary<boolean>;
  notEmpty: boolean; //show no Data if result empty
}

export const archiveAdapter: EntityAdapter<Archive.ArchiveDocument> = createEntityAdapter<Archive.ArchiveDocument>({
  selectId: (archiveDocument: Archive.ArchiveDocument) => archiveDocument.sessionId,
});

const { selectAll } = archiveAdapter.getSelectors();

const initialState = archiveAdapter.getInitialState({
  selectedArchive: initialArchive,
  isArchiveValid: false,
  page: 0,
  perPage: 100,
  orderBy: 'name',
  orderDirection: 'asc',
  filters: initialArchiveFilter,
  userAutocomplete: [],
  addArchiveModalConfig: {
    disableSelectedCamera: false,
    disableSelectDateRange: false,
    hasSmartStorage: false,
    smartStorageInterval: null,
  },
  isLastPage: false,
  isInitialLoaded: false,
  downloadProgress: {},
  notEmpty: false,
});

export const archiveStateReducer = createReducer(
  initialState,
  on(ArchiveActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(ArchiveActions.resetSelectedArchive, state => {
    return {
      ...state,
      selectedArchive: initialState.selectedArchive,
      isArchiveValid: initialState.isArchiveValid,
    };
  }),
  on(ArchiveActions.changeArchiveProperty, (state, { property, value }) => {
    return {
      ...state,
      selectedArchive: {
        ...state.selectedArchive,
        [property]: value,
      },
    };
  }),
  on(ArchiveActions.changeGrantedAccessParams, (state, { property, value }) => {
    return {
      ...state,
      selectedArchive: {
        ...state.selectedArchive,
        grantedAccessParams: {
          [property]: value,
        },
      },
    };
  }),
  on(ArchiveActions.setValid, (state, { isArchiveValid }) => {
    return {
      ...state,
      isArchiveValid,
    };
  }),
  on(ArchiveActions.removeTags, (state, { value }) => {
    const index = state.selectedArchive.tags.findIndex(item => item === value);
    const tags = [...state.selectedArchive.tags];
    if (index > -1) {
      tags.splice(index, 1);
    }
    return {
      ...state,
      selectedArchive: {
        ...state.selectedArchive,
        tags,
      },
    };
  }),
  on(ArchiveActions.getArchiveListSuccess, (state, { archives }) => {
    if (state.isInitialLoaded) {
      return archiveAdapter.addMany(archives, {
        ...state,
        isLastPage: archives.length < state.perPage,
        page: state.page + 1,
      });
    } else {
      return archiveAdapter.addMany(archives, {
        ...state,
        isLastPage: archives.length < state.perPage,
        isInitialLoaded: true,
        page: state.page + 1,
        notEmpty: !!archives.length,
      });
    }

  }),
  on(ArchiveActions.setPaginationParams, (state, { page, perPage }) => {
    return {
      ...state,
      page,
      perPage,
    };
  }),
  on(ArchiveActions.setFilter, (state, { filter, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [filter]: value,
      },
    };
  }),
  on(ArchiveActions.rmFilter, (state, { filter, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [filter]: removeFilter(state.filters, value, filter),
      },
    };
  }),
  on(ArchiveActions.updateArchiveProgress, (state, { archive }) => {
    return archiveAdapter.updateOne({
        id: archive.sessionId,
        changes: {
          progress: archive.progress,
          status: archive.status,
        },
      },
      state);
  }),
  on(ArchiveActions.setUserAutocomplete, (state, { users }) => {
    return {
      ...state,
      userAutocomplete: Object.values(users),
    };
  }),
  on(ArchiveActions.updateAddArchiveModalConfig, (state, { property, disable }) => {
    return {
      ...state,
      addArchiveModalConfig: {
        ...state.addArchiveModalConfig,
        [property]: disable,
      },
    };
  }),
  on(ArchiveActions.resetArchives, (state) => {
    return archiveAdapter.removeAll({
      ...state,
      isLastPage: initialState.isLastPage,
      page: initialState.page,
      perPage: initialState.perPage,
    });
  }),
  on(ArchiveActions.deleteArchiveSuccess, (state, { sessionId }) => {
    return archiveAdapter.removeOne(sessionId, {
      ...state,
      notEmpty: Object.keys(state.entities ?? {}).length > 1,
    });
  }),
  on(ArchiveActions.setIsLoading, (state, { isLoading }) => {
    return {
      ...state,
      isLoading,
    };
  }),
  on(ArchiveActions.retrySuccess, (state, { archiveUpdate }) => {
    return archiveAdapter.updateOne({
      id: archiveUpdate.sessionId, changes: {
        progress: archiveUpdate.progress,
        status: archiveUpdate.status,
      },
    }, state);
  }),
  on(ArchiveActions.archiveSockedReceived, (state, { archive }) => {
    if (state.entities[archive.sessionId]) {
      return archiveAdapter.updateOne({
          id: archive.sessionId,
          changes: {
            progress: archive.progress,
            status: archive.status,
            _id: archive?._id,
          },
        },
        state);
    } else {
      return archiveAdapter.setAll([archive, ...selectAll(state)], { ...state, notEmpty: true });
    }
  }),
  on(ArchiveActions.downloadArchive, (state, { archive }) => {
    return {
      ...state,
      downloadProgress: {
        ...state.downloadProgress,
        [archive.sessionId]: true,
      },
    };
  }),
  on(ArchiveActions.downloadArchiveFinished, (state, { archive }) => {
    const downloadProgress = { ...state.downloadProgress };
    delete downloadProgress[archive.sessionId];
    return {
      ...state,
      downloadProgress,
    };
  }),
);

const removeFilter = (filters: ArchiveResponseFilter, value: any, field: string): any => {
  switch (field) {
    case 'tags':
      const tags = [...filters[field]];
      const indexDetectionTypesFilters = tags.findIndex(item => item === value);
      if (indexDetectionTypesFilters !== -1) {
        tags.splice(indexDetectionTypesFilters, 1);
      }
      return tags;
    case 'selectedCameras':
      const cameras = { ...filters[field] };
      delete cameras[value];
      return cameras;
    case 'status':
      const status = [...filters[field]];
      const indexStatusFilters = status.findIndex(item => item === value);
      if (indexStatusFilters !== -1) {
        status.splice(indexStatusFilters, 1);
      }
      return status;
  }
};
