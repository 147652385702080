import { edgeEditMenuLevel2Validation, locationEditMenuLevel2Validation } from '@consts/menu.const';
import { createReducer, on } from '@ngrx/store';
import { LocationModel } from '../../../locations/location.model';
import { LocationEditActions } from '@states/location-edit/location-edit.action-types';
import { DeviceModel } from '@models/device.model';

export declare interface LocationEditState {
  level2MenuValid: { [key: string]: boolean };

  selectedLocationId: string;
  selectedLocation: LocationModel.LocationItem;

  selectedSpeaker: DeviceModel.LocationDeviceDocument;
  deviceState: DeviceModel.DeviceState;
}

export const initialState: LocationEditState = {
  level2MenuValid: locationEditMenuLevel2Validation,
  selectedLocationId: null,
  selectedLocation: null,
  selectedSpeaker: null,
  deviceState: {
    testOngoing: false,
  },
};

export const locationEditStateReducer = createReducer(
  initialState,
  on(LocationEditActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(LocationEditActions.resetDeviceState, state => {
    return {
      ...state,
      selectedSpeaker: null,
      deviceState: {
        testOngoing: false,
      },
    };
  }),
  on(LocationEditActions.setSelectedLocationId, (state, { selectedLocationId }) => {
    return {
      ...state,
      selectedLocationId,
    };
  }),
  on(LocationEditActions.setSelectedLocation, (state, { selectedLocation }) => {
    return {
      ...state,
      selectedLocation,
    };
  }),
  on(LocationEditActions.setSelectedSpeaker, (state, { selectedSpeaker }) => {
    return {
      ...state,
      selectedSpeaker,
    };
  }),
  on(LocationEditActions.setSelectedSpeakerById, (state, { speakerId }) => {
    const speaker = state.selectedLocation?.devices[speakerId];
    return {
      ...state,
      selectedSpeaker: speaker,
    };
  }),
  on(LocationEditActions.setDeviceState, (state, { deviceStateChange }) => {
    return {
      ...state,
      deviceState: {
        ...deviceStateChange,
      },
    };
  }),
  on(LocationEditActions.testSpeakerSuccess, (state) => {
    const inProgressNum = state.deviceState.inProgressNum - 1;
    return {
      ...state,
      deviceState: {
        inProgressNum,
        testOngoing: false,
        testSuccess: true,
      },
    };
  }),
  on(LocationEditActions.testSpeakerError, (state, { errorMsg }) => {
    if(state?.deviceState?.testSuccess === true) {
      return {
        ...state
      }
    }
    const inProgressNum = state.deviceState.inProgressNum - 1;
    if (state?.deviceState?.testErrorMsg === 'Unauthorized: Wrong username or password') {
      errorMsg = state?.deviceState?.testErrorMsg;
    } else if (!!state?.deviceState?.testErrorMsg && errorMsg === 'The speaker is not connected. Try again'
      && state?.deviceState?.testErrorMsg !== 'The speaker is not connected. Try again') {
      errorMsg = state?.deviceState?.testErrorMsg;
    }
    return {
      ...state,
      deviceState: {
        ...state.deviceState,
        inProgressNum,
        testOngoing: inProgressNum === 0 ? false : true,
        testErrorMsg: errorMsg,
      },
    };
  }),
);
