import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';
import { AppUser } from '../user/user.model';
import * as Twilio from '@twilio/voice-sdk';
import { select, Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserSelectors } from '@states/user/user.selector-types';
import { Call } from '@twilio/voice-sdk';
import Codec = Call.Codec;
import { RegisterSpeakerRequest } from '../operator/speaker/create/create-speaker.component';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TwilioService {

  public selectTwilioPttDetails$: Observable<AppUser.TwilioPttDetails> = this.store$.pipe(untilDestroyed(this), select(UserSelectors.selectTwilioPttDetails));
  private device: Twilio.Device;
  private call: Twilio.Call;
  private twilioPttDetails: AppUser.TwilioPttDetails;

  constructor(private http: HttpClient, private store$: Store) {
    this.selectTwilioPttDetails$.subscribe((twilioPttDetails) => {
      if (twilioPttDetails) {
        this.twilioPttDetails = twilioPttDetails;
        this.initializeDevice();
      }
    });
  }

  renewTwilioPttToken(): Observable<AppUser.TwilioPttDetails> {
    return this.http.get<AppUser.TwilioPttDetails>(api.ptt.authToken);
  }

  listCredentialLists(): Observable<{ sid: string, name: string }[]> {
    return this.http.get<{ sid: string, name: string }[]>(api.ptt.listCredentialList);
  }

  register(request: RegisterSpeakerRequest): Observable<{ username: string, password: string }> {
    return this.http.post<{ username: string, password: string }>(api.ptt.register, request);
  }

  private initializeDevice() {
    this.device = new Twilio.Device(this.twilioPttDetails?.token, {
      // debug: true,
      // answerOnBridge: true,
      /*
       * Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
       * providing better audio quality in restrained network conditions. Opus will be default in 2.0.
       */
      sounds: {},
      codecPreferences: [Codec.Opus, Codec.PCMU],

    });

    //  SETUP STEP 4: Listen for Twilio.Device states
    this.addDeviceListeners(this.device);

    // Device must be registered in order to receive incoming calls
    // this.device.register();
  }

  private addDeviceListeners(device: Twilio.Device) {
    device.on('registered', function() {
      console.log('Twilio.Device Ready to make and receive calls!');
    });

    device.on('error', function(error) {
      console.log(`Twilio.Device Error: ${error.message}`);
    });
  }

  public async talk(to: string) {
    const params = {
      To: to,
      // customParam: 'exampleValue',
      // From: '<JWT TOKEN>',
    };

    if (this.device) {
      console.log(`Attempting to call ${params.To} ...`);
      setTimeout(() => {
        if (this.callStatus !== 'open') {
          console.log('Call timed out.');
          this.hangUp();
        }
      }, 5000);

      // Twilio.Device.connect() returns a Call object
      this.call = await this.device.connect({ params });

      /*
       * add listeners to the Call
       * "accepted" means the call has finished connecting and the state is now "open"
       */
      this.call.on('accept', this.updateUIAcceptedOutgoingCall);
      this.call.on('disconnect', this.updateUIDisconnectedOutgoingCall);
      this.call.on('cancel', this.updateUIDisconnectedOutgoingCall);
      this.call.on('reject', this.updateUIDisconnectedOutgoingCall);

    } else {
      console.log('Unable to make call.');
    }
  }

  public get callStatus() {
    return this.call?.status() ?? 'closed';
  }

  private updateUIAcceptedOutgoingCall(call) {
    console.log('Call in progress ...');
  }

  private updateUIDisconnectedOutgoingCall() {
    console.log('Call disconnected.');
  }

  public hangUp() {
    if (!this.call) {
      return;
    }
    this.call.disconnect();
    delete this.call;
  }

}
