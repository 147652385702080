import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { AlertMonitoringService } from '../../development/alert-monitoring.service';
import * as AlertMonitoringActions from '@states/alert-monitoring/alert-monitoring.actions';
import { catchError, debounceTime, exhaustMap, of, share, switchMap, takeUntil, tap } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { AlertMonitoringFilter, AlertMonitoringViewModel } from '@models/alert-monitoring.model';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { AlertEntry, AlertsService } from '../../development/alerts.service';
import { SelectedCamera } from '@models/alert-events.model';
import { defaultDateRange } from '@states/alert-monitoring/alert-monitoring.reducer';
import { UtilsV2Service } from '../../services/utils-v2.service';
import { UserSelectors } from '@states/user/user.selector-types';
import { MediaCacheService } from '../../shared/media-cache/media-cache.service';
import * as _ from 'lodash';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { SearchCamera } from '@models/search.model';
import { AlertEventsService } from '../../development/alert-events.service';

@Injectable()
export class AlertMonitoringEffect {
  public saveView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.saveView),
      exhaustMap(({ name, isPublic, asNew }) => [
        SharedActions.setIsSaving({ isSaving: true }),
        AlertMonitoringActions.sendView({ name, isPublic, asNew }),
      ]),
    ),
  );

  public sendView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.sendView),
      withLatestFrom(this.store$.pipe(select(state => state.alertMonitoringState))),
      switchMap(([{ name, isPublic, asNew }, { eventIds, selectedCameras, trackedObjects, dateRange, selectedView, flowTypes, searchFilters, acknowledges }]) => {
        const view: AlertMonitoringViewModel = {
          name: name,
          isPublic,
          selectedCameras: Object.values(selectedCameras ?? {}),
          trackedObjects,
          dateRange: dateRange,
          flowTypes,
          filters: searchFilters,
          acknowledges,
          eventIds,
        };
        if (!asNew && selectedView) {
          view._id = selectedView._id;
        }
        return this.saveAlertMonitoring(view)
          .pipe(
            switchMap(item => {
              const actions: Action[] = [
                SharedActions.setIsSaving({ isSaving: false }),
                AlertMonitoringActions.saveViewSuccess(),
                AlertMonitoringActions.getAlertMonitoringViews({ query: null }),
                AlertMonitoringActions.getView({ id: !asNew && selectedView ? view._id : item._id }),
              ];
              // if (!selectedView) {
              //   actions.push(
              //     AlertMonitoringActions.selectAlertMonitoringView({
              //       selectedView: item,
              //     }),
              //   );
              // }
              return actions;
            }),
            catchError(response => {
              return [
                SharedActions.setIsSaving({ isSaving: false }), //loader off
                this.catchError(response),
              ];
            }),
          );
      }),
    ),
  );

  public getAlertMonitoringViews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.getAlertMonitoringViews),
      debounceTime(400),
      withLatestFrom(this.store$.pipe(select(state => state.alertMonitoringState))),
      switchMap(([{ query }, { isLastViewListPage, lastViewListTimestamp, limit }]) => {
        if (!isLastViewListPage) {
          return this.alertMonitoringService.get({ query, timestamp: lastViewListTimestamp, limit })
            .pipe(
              takeUntil(this.actions$.pipe(ofType(AlertMonitoringActions.cancelNetworkRequests))),
              switchMap(views => {
                return [AlertMonitoringActions.getAlertMonitoringViewsSuccess({ views })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        } else {
          return of(SharedActions.doNothing());
        }
      }),
    ),
  );

  public setSelectedAlertMonitoringView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.selectAlertMonitoringView),
      withLatestFrom(this.store$.pipe(select(CameraSelectors.selectCameraState))),
      switchMap(([{ selectedView }, allCameras]) => {
        const selectedCameras = [];
        selectedView.selectedCameras.forEach(camera => {
          if (allCameras.entities[camera.cameraId]) {
            selectedCameras.push(allCameras.entities[camera.cameraId]);
          }
        });
        const filters = Object.keys(selectedView?.filters ?? {});
        const filterActions: Action[] = [];
        filters.forEach(filter => {
          filterActions.push(
            AlertMonitoringActions.setSearchFilter({
              prop: filter,
              value: selectedView.filters[filter],
            }),
          );
        });
        return [
          // AlertMonitoringActions.setSelectedCamera({
          //   selectedCameras: selectedCameras,
          // }),
          AlertMonitoringActions.setTrackedObjects({
            trackedObjects: selectedView.trackedObjects,
          }),
          AlertMonitoringActions.setDateRange({
            dateRange: selectedView.dateRange ?? defaultDateRange,
          }),
          AlertMonitoringActions.setSelectedAlertMonitoringView({
            selectedView: selectedView,
          }),
          AlertMonitoringActions.setFlowTypes({
            flowTypes: selectedView.flowTypes ?? [],
          }),
          AlertMonitoringActions.setAcknowledges({
            acknowledges: selectedView.acknowledges,
          }),
          AlertMonitoringActions.setEventIdsFilter({
            eventIds: selectedView.eventIds,
          }),
          ...filterActions,
          AlertMonitoringActions.getAlertsByFilters(),
        ];
      }),
    ),
  );

  public deleteView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.deleteView),
      switchMap(({ id }) => {
        return this.alertMonitoringService.delete(id)
          .pipe(
            switchMap(views => {
              return [
                AlertMonitoringActions.resetToInitialState(),
                AlertMonitoringActions.getAlertMonitoringViews({ query: null }),
                AlertMonitoringActions.deleteViewSuccess(),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public getAlertsByFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.getAlertsByFilters),
      exhaustMap(() => {
        return [
          AlertMonitoringActions.setLoader({ isLoading: true }),
          AlertMonitoringActions.sendGetAlertsByFilters(),
        ];
      }),
    ),
  );

  public sendGetAlertsByFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.sendGetAlertsByFilters),
      withLatestFrom(
        this.store$.pipe(select(state => state.alertMonitoringState)),
        this.store$.pipe(select(UserSelectors.selectUserTimezone)),
      ),
      switchMap(
        ([, {
          selectedCameras,
          trackedObjects,
          dateRange,
          alerts,
          query,
          flowTypes,
          paginationTimeStamps,
          eventIds,
          alertTypes,
          searchFilters,
          lastAlertMonitoringTimestamp,
          isLastAlertMonitoringPage,
          limitAlertMonitoringPage,
          acknowledges,
          widgetDataInfo,
        },
           timezone,
         ]) => {
          const selectedCamerasFilter: SelectedCamera[] = Object.values(selectedCameras ?? {});
          const dateRangeFilter = this.utilsV2Service.dateRangeToServerRequest(dateRange);
          const timeRange = this.utilsV2Service.dateRangeWithAdvancedOptionsToServerRequestV2(dateRange);
          if (!isLastAlertMonitoringPage) {
            let filter: AlertMonitoringFilter;
            if (widgetDataInfo) {
              filter = {
                selectedCameras: widgetDataInfo.selectedCameras,
                trackerClass: widgetDataInfo?.trackerClass,
                timeRange: widgetDataInfo?.timeRange,
                query,
                flowTypes: widgetDataInfo?.flowTypes,
                eventIds,
                alertTypes,
                filters: widgetDataInfo?.filters,
                latestTs: lastAlertMonitoringTimestamp,
                acknowledges: [false, true],
                eventIdsFilters: widgetDataInfo?.eventIdsFilters,
                source: widgetDataInfo?.source,
              };
            } else {
              filter = {
                selectedCameras: selectedCamerasFilter.map(camera => camera?.cameraId),
                trackedObjects,
                dateRangeV2: dateRangeFilter,
                timeRange: timeRange,
                query,
                flowTypes,
                eventIds,
                alertTypes,
                filters: searchFilters,
                latestTs: lastAlertMonitoringTimestamp,
                acknowledges: acknowledges,
                eventIdsFilters: eventIds,
              };
            }
            return this.alertsService
              .getByFilters(filter)
              .pipe(
                takeUntil(this.actions$.pipe(ofType(AlertMonitoringActions.cancelNetworkRequests))),
                switchMap(result => {
                  const actions: Action[] = [];
                  const startTimes = result.map(arr => arr.timestamp - 32000);
                  const endTimes = result.map(arr => arr.timestamp + 32000);
                  const minStart = _.min(startTimes);
                  const maxEnd = _.max(endTimes);
                  const requestCameras: SearchCamera[] = result.map(arr => arr.selectedCamera)
                    .filter(camera => !!camera?.cameraId);
                  if (!minStart || !maxEnd) {
                    return [AlertMonitoringActions.setLoader({ isLoading: false })];
                  }
                  if (requestCameras.length > 0) {
                    this.cameraThumbnailsService
                      .getThumbnailsByRange(
                        new Date(minStart).getTime(),
                        new Date(maxEnd).getTime(),
                        requestCameras,
                      )
                      .pipe(
                        tap(res => {
                          this.mediaCacheService.getThumbnailBits(requestCameras, minStart, maxEnd);
                        }),
                      )
                      .subscribe();
                  }
                  actions.push(
                    AlertMonitoringActions.getAlertsByFiltersSuccess({
                      alerts: result,
                    }),
                  );
                  actions.push(AlertMonitoringActions.setLoader({ isLoading: false }));
                  // actions.push(AlertMonitoringActions.setLoader({ isLoading: false }));
                  // actions.push(
                  //   AlertMonitoringActions.exportAlertsByFiltersToCSVSuccess({
                  //     alerts: result,
                  //   }),
                  // );


                  return actions;
                }),
                catchError(response => {
                  return [
                    AlertMonitoringActions.setLoader({ isLoading: false }),
                    this.catchError(response),
                  ];
                }),
              );
          } else {
            return of(AlertMonitoringActions.setLoader({ isLoading: false }));
          }


        },
      ),
      share(),
    ),
  );

  // public getAlertsAutocomplete$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AlertMonitoringActions.getAlertsAutocomplete),
  //     switchMap(({ query }) => {
  //       return this.alertsService.autocomplete(query)
  //         .pipe(
  //           takeUntil(this.actions$.pipe(ofType(AlertMonitoringActions.cancelNetworkRequests))),
  //           switchMap(res => {
  //             return of(AlertMonitoringActions.getAlertsAutocompleteSuccess({ autocompleteOptions: res }));
  //           }),
  //           catchError(response => {
  //             return [this.catchError(response)];
  //           }),
  //         );
  //     }),
  //     share(),
  //   ),
  // );

  public setSearchQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.setQuery),
      debounceTime(400),
      switchMap(() => of(AlertMonitoringActions.getAlertsByFilters())),
    ),
  );

  public changeFilter = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AlertMonitoringActions.setQuery,
        AlertMonitoringActions.setSelectedCamera,
        AlertMonitoringActions.setTrackedObjects,
        AlertMonitoringActions.setFlowTypes,
        AlertMonitoringActions.setAlertTypes,
        AlertMonitoringActions.setDateRange,
        AlertMonitoringActions.removeFilter,
        AlertMonitoringActions.resetFilters,
        AlertMonitoringActions.setEventIdsFilter,
        AlertMonitoringActions.setSearchFilter,
        AlertMonitoringActions.setAcknowledges,
        AlertMonitoringActions.removeSelectedCameraFilter,
      ),
      debounceTime(400),
      switchMap(() => [AlertMonitoringActions.resetEntities(), AlertMonitoringActions.getAlertsByFilters(), AlertMonitoringActions.countAlertsByFilters()]),
    ),
  );

  public getView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.getView),
      switchMap(({ id }) => {
        return this.alertMonitoringService.getView(id)
          .pipe(
            takeUntil(this.actions$.pipe(ofType(AlertMonitoringActions.cancelNetworkRequests))),
            switchMap(view => {
              return [
                AlertMonitoringActions.selectAlertMonitoringView({ selectedView: view }),
              ];
            }),
            catchError(response => {
              return [
                AlertMonitoringActions.getViewFail(),
                this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  public archiveAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.archiveAlert),
      mergeMap(({ id, isArchive }) => {
        return this.alertMonitoringService.archiveAlert(id, isArchive)
          .pipe(
            mergeMap(view => {
              return [
                AlertMonitoringActions.archiveAlertSuccess({ id, isArchive }),
                AlertMonitoringActions.refreshAlertsByAutoAcknowledgesAndFrequency(),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
    ),
  );


  public deleteAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.deleteAlert),
      switchMap(({ id }) => {
        return this.alertMonitoringService.deleteAlert(id)
          .pipe(
            switchMap(view => {
              return [
                SharedActions.showMessage({ success: 'Alert has been removed' }),
                AlertMonitoringActions.deleteAlertSuccess({ id }),
              ];
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
    ),
  );


  public setNewAlerts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.setNewAlerts),
      withLatestFrom(
        this.store$.pipe(select(state => state.userSettingsState)),
      ),
      switchMap(([{ alerts }, { userSettings }]) => {
        const isMuted = userSettings.alertMonitoringMuted;
        if (!isMuted) {
          let audio = new Audio();
          audio.src = '../assets/audio/new-alert.mp3';
          audio.load();
          audio.play();
        }
        return of(SharedActions.doNothing());
      }),
    ),
  );

  public newAlertsReceivedValidation = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.newAlertsValidation),
      withLatestFrom(this.store$.pipe(select(state => state.alertMonitoringState))),
      mergeMap(([{ alerts }, {
        selectedCameras,
        trackedObjects,
        flowTypes,
        eventIds,
        alertTypes,
        acknowledges,
      }]) => {
        let result: AlertEntry[] = [];
        let filterResult = false;
        if (Object.values(selectedCameras)?.length) {
          filterResult = true;
          result = alerts.filter(alert => {
            return selectedCameras[alert.cameraId];
          });
        }
        if (trackedObjects?.length) {
          let filerArray = filterResult ? result : alerts;
          filterResult = true;
          result = filerArray.filter(alert => {
            const findInTrackedObjects = trackedObjects.findIndex(trackedObject => trackedObject === alert.type);
            return findInTrackedObjects > -1;
          });
        }
        if (flowTypes?.length) {
          let filerArray = filterResult ? result : alerts;
          filterResult = true;
          result = filerArray.filter(alert => {
            const findInFlowType = flowTypes.findIndex(flowType => flowType.category === alert.event.selectedFlow.category && flowType.flowType === alert.event.selectedFlow.flowType);
            return findInFlowType > -1;
          });
        }
        if (eventIds?.length) {
          // let filerArray = filterResult ? result : alerts;
          // filterResult = true;
          // result = filerArray.filter(alert => {
          //   const findInAlertName = eventIds.findIndex(name => name === alert.eventName);
          //   return findInAlertName > -1;
          // });
        }

        if (alertTypes?.length) {
          let filerArray = filterResult ? result : alerts;
          filterResult = true;
          result = filerArray.filter(alert => {
            const findInFlowType = alertTypes.findIndex(alertType => alertType === alert.alertType);
            return findInFlowType > -1;
          });
        }

        if (!filterResult) {
          return of(AlertMonitoringActions.setNewAlerts({ alerts }));
        }

        if (result?.length) {
          return of(AlertMonitoringActions.setNewAlerts({ alerts: result }));
        } else {
          return of(SharedActions.doNothing());
        }
      }),
    ),
  );

  public countAlertsByFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.countAlertsByFilters),
      exhaustMap(() => {
        return [
          AlertMonitoringActions.setLoader({ isLoading: true }),
          AlertMonitoringActions.resetAlertsCount(),
          AlertMonitoringActions.sendCountAlertsByFilters(),
        ];
      }),
    ),
  );

  public sendCountAlertsByFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.sendCountAlertsByFilters),
      withLatestFrom(
        this.store$.pipe(select(state => state.alertMonitoringState)),
        this.store$.pipe(select(UserSelectors.selectUserTimezone)),
      ),
      switchMap(
        ([, {
          selectedCameras,
          trackedObjects,
          dateRange,
          alerts,
          query,
          flowTypes,
          paginationTimeStamps,
          eventIds,
          alertTypes,
          searchFilters,
          lastAlertMonitoringTimestamp,
          isLastAlertMonitoringPage,
          limitAlertMonitoringPage,
          acknowledges,
        },
           timezone,
         ]) => {
          const selectedCamerasFilter: SelectedCamera[] = Object.values(selectedCameras ?? {});
          const dateRangeFilter = this.utilsV2Service.dateRangeToServerRequest(dateRange);
          const timeRange = this.utilsV2Service.dateRangeWithAdvancedOptionsToServerRequestV2(dateRange);
          if (!isLastAlertMonitoringPage) {
            return this.alertsService
              .countByFilters({
                selectedCameras: selectedCamerasFilter.map(camera => camera?.cameraId),
                trackedObjects,
                dateRangeV2: dateRangeFilter,
                timeRange,
                query,
                flowTypes,
                eventIds,
                alertTypes,
                filters: searchFilters,
                latestTs: lastAlertMonitoringTimestamp,
                acknowledges: acknowledges,
              })
              .pipe(
                takeUntil(this.actions$.pipe(ofType(AlertMonitoringActions.cancelNetworkRequests))),
                switchMap(result => {
                  const actions: Action[] = [];
                  actions.push(
                    AlertMonitoringActions.countAlertsByFiltersSuccess({
                      count: result,
                    }),
                  );
                  // actions.push(AlertMonitoringActions.setLoader({isLoading: false}));
                  return actions;
                }),
                catchError(response => {
                  return [
                    AlertMonitoringActions.setLoader({ isLoading: false }),
                    this.catchError(response),
                  ];
                }),
              );
          } else {
            return of(AlertMonitoringActions.setLoader({ isLoading: false }));
          }


        },
      ),
      share(),
    ),
  );


  public getAlertsAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertMonitoringActions.getEventsAutocomplete),
      switchMap(({ query }) => {
        return this.alertEventService.getAlertEventsV2(0, 50, query)
          .pipe(
            takeUntil(this.actions$.pipe(ofType(AlertMonitoringActions.cancelNetworkRequests))),
            switchMap(res => {
              return of(AlertMonitoringActions.getEventsAutocompleteSuccess({ events: res.items }));
            }),
            catchError(response => {
              return [this.catchError(response)];
            }),
          );
      }),
      share(),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private alertMonitoringService: AlertMonitoringService,
    private alertsService: AlertsService,
    private utilsV2Service: UtilsV2Service,
    private mediaCacheService: MediaCacheService,
    private cameraThumbnailsService: CamerasThumbnailsService,
    private alertEventService: AlertEventsService,
  ) {
  }

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

  private saveAlertMonitoring(view: AlertMonitoringViewModel) {
    if (view._id) {
      return this.alertMonitoringService.updateView(view);
    } else {
      return this.alertMonitoringService.saveView(view);
    }
  }
}

