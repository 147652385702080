import { Component, HostBinding } from '@angular/core';
import _default from 'chart.js/dist/plugins/plugin.tooltip';
import animations = _default.defaults.animations;
import { FadeInAnimation, FadeOutAnimation } from '../../../framework/animations';

@Component({
  selector: 'ui-submenu',
  templateUrl: './ui-submenu.component.html',
  styleUrl: './ui-submenu.component.scss',
  animations: [FadeOutAnimation, FadeInAnimation],

})
export class UiSubmenuComponent {

  @HostBinding('class.expanded')
  get expandedClass(): boolean {
    return this.isExpanded;
  }

  public isExpanded = true;

  public toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

}
