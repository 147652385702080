import { createAction, props } from '@ngrx/store';
import { HomeModel } from '@models/home.model';
import { LocationModel } from '../../../locations/location.model';
import { selectSelectedLocationId } from '@states/home/home.selectors';

export const resetToInitialState = createAction('[HOME] Reset to Initial state');

export const startCreateLocation = createAction('[HOME] Start Create Home', props<{ isSave: boolean, form: HomeModel.LocationCreateRequest }>());

export const serverRequestCreateSubLocation = createAction('[HOME] Server Request Create SubLocation', props<{ sublocation: HomeModel.SubLocationCreateRequest }>());
export const serverRequestCreateSubLocationSuccess = createAction('[HOME] Server Request Create SubLocation Success', props<{ document: HomeModel.Location }>());
export const serverRequestCreateSubLocationFail = createAction('[HOME] Server Request Create SubLocation Fail');

export const addCameraToSubLocation = createAction('[HOME] Add Camera To SubLocation', props<{ request: HomeModel.AddCameraToSubLocationRequest }>());
export const addCameraToSubLocationSuccess = createAction('[HOME] Add Camera To SubLocation Success', props<{ request: HomeModel.AddCameraToSubLocationRequest }>());
export const addCameraToSubLocationFail = createAction('[HOME] Add Camera To SubLocation Fail');

export const addCamerasToSubLocation = createAction('[HOME] Add Cameras To SubLocation', props<{ sublocationId: string }>());
export const addCamerasToSubLocationSuccess = createAction('[HOME] Add Cameras To SubLocation Success');

export const removeCameraFromSubLocation = createAction('[HOME] Remove Camera From SubLocation', props<{ request: HomeModel.RemoveCameraFromSubLocationRequest }>());
export const removeCameraFromSubLocationSuccess = createAction('[HOME] Remove Camera From SubLocation Success', props<{ request: HomeModel.RemoveCameraFromSubLocationRequest }>());
export const removeCameraFromSubLocationFail = createAction('[HOME] Remove Camera From SubLocation Fail');

export const deleteSubLocation = createAction('[HOME] Delete SubLocation', props<{ request: HomeModel.RemoveSubLocationRequest }>());
export const updateSubLocation = createAction('[HOME] Update SubLocation', props<{ request: HomeModel.UpdateSubLocationRequest }>());

export const setIsSaving = createAction('[HOME] Set Is Saving', props<{ isSaving: boolean }>());

export const getLocations = createAction('[HOME] Get Locations');
export const getLocationsSuccess = createAction('[HOME] Get Locations Success', props<{ locations: HomeModel.Location[] }>());
export const getLocationsFail = createAction('[HOME] Get Locations Fail');

export const deleteLocationById = createAction('[HOME] Delete Location By Id', props<{ id: string }>());
export const deleteLocationByIdSuccess = createAction('[HOME] Delete Location By Id Success', props<{ id: string }>());
export const deleteLocationByIdFail = createAction('[HOME] Delete Location By Id Fail', props<{ id: string }>());

export const starLocation = createAction('[HOME] Star Location', props<{ id: string }>());
export const unstarLocation = createAction('[HOME] Unstar Location', props<{ id: string }>());
export const getFavoriteLocations = createAction('[HOME] Get Favorite locations');
export const getFavoriteLocationsSuccess = createAction('[HOME] Get Favorite locations success', props<{ favorites: string[] }>());
export const getFavoriteLocationsFail = createAction('[HOME] Get Favorite locations fail');

export const setFilteredLocations = createAction('[HOME] Set Filtered Locations', props<{ locations: HomeModel.Location[] }>());

export const DeleteCameraSuccess = createAction('[HOME] Delete Camera Success', props<{ response: LocationModel.DeleteCameraFromLocationResponse }>());

export const setQuery = createAction('[HOME] Set Query', props<{ query: string }>());

export const setSelectedLocationId = createAction('[HOME] Set Selected Location Id', props<{ selectedLocationId: string }>());
export const unsetSelectedLocationId = createAction('[HOME] Unset Selected Location Id');
export const addSelectedCamera = createAction('[HOME] Add Selected Camera', props<{ camera: HomeModel.Camera }>());
export const removeSelectedCamera = createAction('[HOME] Remove Selected Camera', props<{ cameraId: string }>());
export const clearSelectedCameras = createAction('[HOME] Unset Selected Cameras');
export const setCameraSelectionModeForLocation = createAction('[HOME] Set Camera Selection Mode For Location', props<{ locationId: string }>());
export const clearCameraSelectionMode = createAction('[HOME] Clear Camera Selection Mode');

export const setGotoCameraId = createAction('[HOME] Set Goto Camera Id', props<{ cameraId: string }>());
export const clearGotoCameraId = createAction('[HOME] Clear Goto Camera Id');

