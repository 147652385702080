import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MultiSearchState } from '@states/multi-search/multi-search.reducer';

export const selectMultiSearchState = createFeatureSelector<MultiSearchState>('multiSearchState');

export const selectSelectedCameras = createSelector(selectMultiSearchState, ({ selectedCameras }: MultiSearchState) => selectedCameras);

export const selectSearchSelections = createSelector(selectMultiSearchState, ({ searchSelections }: MultiSearchState) => searchSelections);
export const selectSavedSearches = createSelector(selectMultiSearchState, ({ savedSearches }: MultiSearchState) => savedSearches);
export const selectSelectedSavedSearch = createSelector(
  selectMultiSearchState,
  ({ selectedSavedSearch }: MultiSearchState) => selectedSavedSearch,
);

export const selectSearchHighConfidence = createSelector(selectMultiSearchState, ({ highConfidence }: MultiSearchState) => highConfidence);
export const selectSearchPrecision = createSelector(selectMultiSearchState, ({ precision }: MultiSearchState) => precision);
export const selectDwellRange = createSelector(selectMultiSearchState, ({ dwellRange }: MultiSearchState) => dwellRange);
export const selectSearchV2 = createSelector(selectMultiSearchState, ({ searchV2 }: MultiSearchState) => searchV2);
export const selectFaces = createSelector(selectMultiSearchState, ({ faces }: MultiSearchState) => faces);
export const hasFaces = createSelector(selectMultiSearchState, ({ faces }: MultiSearchState) => !!faces?.objects?.length);

export const selectCameraSearchSettingsFilters = createSelector(
  selectMultiSearchState,
  ({ cameraSearchSettingsFilters }: MultiSearchState) => cameraSearchSettingsFilters,
);

export const selectObjectSelections = createSelector(selectMultiSearchState, ({ objectSelections }: MultiSearchState) => objectSelections);
export const selectUseSingleStore = createSelector(selectMultiSearchState, ({ useSingleStore }: MultiSearchState) => useSingleStore);

export const selectDateRange = createSelector(selectMultiSearchState, ({ dateRange }: MultiSearchState) => dateRange);
export const selectDateTimeRange = createSelector(selectMultiSearchState, ({ dateTimeRange }: MultiSearchState) => dateTimeRange);
export const selectAppearancePeriod = createSelector(selectMultiSearchState, ({ appearancePeriod }: MultiSearchState) => appearancePeriod);
export const selectObjectSelectionsFormatted = createSelector(selectMultiSearchState, ({ objectSelectionsFormatted }: MultiSearchState) => objectSelectionsFormatted);

export const selectObjectSelectionsFormattedAndOuterOperator = createSelector(
  selectMultiSearchState,
  ({ objectSelectionsFormatted, outerOperator }: MultiSearchState) => {
    if (objectSelectionsFormatted) {
      return {
        objectSelectionsFormatted,
        outerOperator,
      };
    } else {
      return null;
    }
  },
);

export const selectOuterOperator = createSelector(selectMultiSearchState, ({ outerOperator }: MultiSearchState) => outerOperator);

export const selectQuery = createSelector(selectMultiSearchState, ({ query }: MultiSearchState) => query);

export const selectTotalItemsCount = createSelector(selectMultiSearchState, ({ totalItemsCount }: MultiSearchState) => totalItemsCount);

export const selectPage = createSelector(selectMultiSearchState, ({ page }: MultiSearchState) => {
  return page;
});

export const selectPerPage = createSelector(selectMultiSearchState, ({ perPage }: MultiSearchState) => perPage);

export const selectCustomEvent = createSelector(selectMultiSearchState, ({ customEvent }: MultiSearchState) => customEvent);
export const selectMotion = createSelector(selectMultiSearchState, ({ motion }: MultiSearchState) => motion);
export const selectUnusualEvent = createSelector(selectMultiSearchState, ({ unusualEvent }: MultiSearchState) => unusualEvent);
export const selectSearchType = createSelector(selectMultiSearchState, ({ searchType }: MultiSearchState) => searchType);

export const selectAccessDoors = createSelector(selectMultiSearchState, ({ accessDoors }: MultiSearchState) => accessDoors);

