import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocationState, selectAll } from './location.reducer';
import { DeviceModel } from '@models/device.model';
import DeviceType = DeviceModel.DeviceType;
import { KeyValuePairs } from '../../../core/interfaces';
import { Dictionary } from '@ngrx/entity/src/models';
import { LocationModel } from '../../../locations/location.model';
import { EdgeCamera } from '../../../cameras/camera.model';
import CameraItem = EdgeCamera.CameraItem;
import { CameraSelectorModalModels } from '../../../modals/camera-selector-modal/models/models';

export const selectLocationState = createFeatureSelector<LocationState>('locationState');

export const isFirstLocationLoaded = createSelector(selectLocationState, (locations: LocationState) => locations.isFirstLocationLoaded);

export const selectAllLocations = createSelector(selectLocationState, selectAll);

export const selectAllLocationIds = createSelector(selectLocationState, ({ ids }: LocationState) => ids);

export const selectLocationNames = createSelector(selectAllLocations, locations => {
  const names: KeyValuePairs<string> = {};
  locations.forEach(location => {
    names[location._id] = location.name;
  });
  return names;
});

export const selectLocationById = (locationId: string) =>
  createSelector(selectLocationState, (locations: LocationState) => locations.entities[locationId]);

export const selectLocationEntities = createSelector(selectLocationState, (locations: LocationState) => locations.entities);

export const selectLocationsEdges = (locationId: string) => createSelector(selectLocationById(locationId), location => location?.edges);

export const selectLocationsDevices = (locationId: string) => createSelector(selectLocationById(locationId), location => location?.devices);

export const selectLocationTimezone = (locationId: string) =>
  createSelector(selectLocationById(locationId), location => location?.timezone);

export const selectLocationName = (locationId: string) => createSelector(selectLocationById(locationId), location => location?.name);

export const selectLocationNameByEdgeId = (edgeId: string) =>
  createSelector(selectLocationState, (locations: LocationState) => Object.values(locations.entities)
    .filter(location => {
      return !!location.edges[edgeId];
    })[0]?.name ?? '');


export const selectLocationLookup = createSelector(selectLocationState, ({ locationLookup }: LocationState) => locationLookup);

export const selectLocationComplianceById = (locationId: string) =>
  createSelector(selectLocationState, (locations: LocationState) => {
    return {
      faceRecognition: locations.entities[locationId].faceRecognition,
      genderClassification: locations.entities[locationId].genderClassification,
    };
  });

export const selectLocationsEdgeById = (locationId: string, edgeId: string) =>
  createSelector(selectLocationsEdges(locationId), edges => (!!edges ? edges[edgeId] : null));

export const selectEdgeCameras = (locationId: string, edgeId: string) =>
  createSelector(selectLocationsEdgeById(locationId, edgeId), edges => edges?.cameras);

export const selectCameraByIpAddress = (locationId: string, edgeId: string, ipAddress: string) =>
  createSelector(selectEdgeCameras(locationId, edgeId), cameras => {
    if (!cameras) {
      return false;
    } else {
      return Object.values(cameras)
        .find(e => e.edgeOnly.networkDetails?.ipAddress.toString()
          .trim() === ipAddress.toString()
          .trim());
    }
  });

export const isIpAddressExistInEdgeCameras = (locationId: string, edgeId: string, ipAddress: string) =>
  createSelector(selectCameraByIpAddress(locationId, edgeId, ipAddress), camera => !!camera);

export const isExpanded = (locationId: string) =>
  createSelector(selectLocationById(locationId), location => location.expanded);

export const isEdgeExpanded = (locationId: string, edgeId: string) =>
  createSelector(selectLocationsEdgeById(locationId, edgeId), edge => edge.expanded);

export const selectAllLocationsEdges = createSelector(selectAllLocations, locations => {
  const allEdges = locations
    .map(location => location.edges)
    .map(e => Object.keys(e!))
    .flat();

  return allEdges;
});

export const selectAllExpanded = createSelector(selectAllLocations, locations => {
  let allExpanded = true;
  for(let location of locations) {
    if (!location.expanded) {
      allExpanded = false;
      break;
    }
    for(let edge of Object.values(location.edges)) {
      if (!edge.expanded) {
        allExpanded = false;
        break;
      }
    }
  }

  return allExpanded;
});

export const selectLocationSpeakers = (locationId: string) => createSelector(selectLocationsDevices(locationId), devices => {
  return Object.values(devices)
    .filter(device => device.type === DeviceModel.DeviceType.Speaker);
});

export const selectAllLocationsSpeakers = createSelector(selectAllLocations, locations => {
  const allEdges = locations
    .map(location => location.devices ? Object.values(location.devices)
      .filter(device => device.type === DeviceModel.DeviceType.Speaker)
      .map(device => {
        return {
          ...device,
          locationName: location.name,
        };
      }) : [])
    .flat();

  return allEdges;
});


/**
 * Select Config Json for Edge
 */
export const selectEdgeConfigJson = createSelector(selectLocationState, ({ edgeConfigJson }: LocationState) => edgeConfigJson);

export const selectEdgeConfigUploaded = createSelector(selectLocationState, ({ edgeConfigUploaded }: LocationState) => edgeConfigUploaded);

/**
 * Select edge Document Json for Edge
 */
export const selectEdgeDocumentJson = createSelector(selectLocationState, ({ edgeDocument }: LocationState) => edgeDocument);

/**
 * Select edge Info Json for Edge
 */
export const selectEdgeInfoJson = createSelector(selectLocationState, ({ edgeInfo }: LocationState) => edgeInfo);

export const selectFilteredLocations = createSelector(selectLocationState, ({ filteredLocations }: LocationState) => filteredLocations);

export const selectHealthFilter = createSelector(selectLocationState, ({ healthFilter }: LocationState) => healthFilter);

export const selectHasSpeakers = createSelector(selectAllLocations, locations => {
  for(const location of locations) {
    const devices = location?.devices ? Object.values(location?.devices) : [];
    if (devices?.length && devices.filter(device => device.type === DeviceType.Speaker)?.length) {
      return true;
    }
  }
  return false;
});

export const selectViewType = createSelector(selectLocationState, ({ viewType }: LocationState) => viewType);

export const selectSipSpeakers = (locationId: string, cameraId: string) =>
  createSelector(selectLocationState, (locations: LocationState) => {
    const location = locations.entities[locationId];
    const devices = location?.devices ? Object.values(location?.devices) : [];
    if (devices?.length) {
      return devices.filter(device =>
        device?.type === DeviceType.Speaker &&
        device?.model === DeviceModel.SpeakerBrands.SIP &&
        !!device?.cameras?.[cameraId],
      );
    }
    return [];
  });


export const selectLocationCamerasSelectorModal = createSelector(selectLocationState, ({ locationLookup }: LocationState) => {
  const result: Dictionary<CameraSelectorModalModels.Location> = {};
  const locationIds = Object.keys(locationLookup);
  let allCameras: Dictionary<CameraSelectorModalModels.Camera> = {};
  locationIds.forEach(locationId => {
    const edges = locationLookup[locationId].edges ?? {};
    let locationCameras: Dictionary<CameraSelectorModalModels.Camera> = {};
    Object.values(edges)
      .forEach(edge => {
        Object.values(edge.cameras ?? {})
          .forEach(camera => {
            locationCameras[camera.edgeOnly.cameraId] = {
              ...camera,
              name: camera.edgeOnly.name,
            };
          });
      });
    const locationResult = {
      ...locationLookup[locationId],
      locationCameras,
    };
    allCameras = {
      ...allCameras,
      ...locationCameras,
    };
    result[locationResult._id] = locationResult;
  });
  return { allCameras, locations: result };
});

export const selectQuery = createSelector(selectLocationState, ({ query }: LocationState) => query);
export const selectStatusFilter = createSelector(selectLocationState, ({ statusFilter }: LocationState) => statusFilter);


